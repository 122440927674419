
import { mainStore } from "../store/index";
import http from "./request/http";

export default {
    getToken(uuid) {
        console.log(uuid);
        const store = mainStore();
        let hotelToken = store.hotelToken;

        if (hotelToken.token && hotelToken.expireTime && hotelToken.uuid) {
            // 判断是否已经过期
            let curTime = new Date().getTime();
            if (hotelToken.expireTime < curTime) {
                return http.get_hotel("/partner/getPTCUserByUuid/" + uuid, {}, 2, hotelToken, false);
            }
            let result = { status: 9999, payload: null, msg: "ok" };
            return Promise.resolve(result);
        }
        return http.get_hotel("/partner/getPTCUserByUuid/" + uuid, {}, 2, hotelToken, false);
    }
};