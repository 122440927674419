//缓存,默认有效期1天
export function cache_sync(key, value, seconds) {
	var _keytime = "time";
	var timestamp = Date.parse(new Date()) / 1000;
	if (key && !value) {
		
		//获取缓存
		var val = sessionStorage.getItem(key + _keytime);
		if (!val || timestamp >= val) {
			//删除缓存
			//console.log("key已失效");
			sessionStorage.removeItem(key + _keytime);
			sessionStorage.removeItem(key);
			return "";
		}
		else {
			//console.log("key未失效");
			return JSON.parse(sessionStorage.getItem(key));
		}
	}
	else if (key && (value || value=="")) {
		//console.log("设置key");
		//设置缓存
		var expire = 0;
		if (!seconds) {
			expire = timestamp + (3600 * 24 * 1);
		}else{
			expire = timestamp + seconds;
		}
		
		sessionStorage.setItem(key + _keytime, expire);
		sessionStorage.setItem(key, JSON.stringify(value));
	}
	else {
		//console.log("key不能空");
	}
}

export function remove_cache_sync(key) {
	var _keytime = "time";
	if (key) {
		sessionStorage.removeItem(key + _keytime);
		sessionStorage.removeItem(key);
	}
}