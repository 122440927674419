//src>request>http.js
//main.js引用：import http from "./request/http.js";app.config.globalProperties.$http = http;
import axios from "axios";
import qs from "qs";
import { ElMessageBox, ElMessage } from 'element-plus'
import { showLoading, hideLoading } from '@/utils/loading'


import 'element-plus/es/components/message-box/style/index'
import 'element-plus/es/components/message/style/index'
import 'element-plus/es/components/loading/style/index'


const hotelServer = process.env.VUE_APP_HOTEL_URL;
console.log("process.env.VUE_APP_HOTEL_URL", process.env.VUE_APP_HOTEL_URL);
const hotelToken = '?cid=SCTT-AYH5&token=747f4444-a3e3-4a81-8982-72de3896a384';


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
//axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? window.location.origin : "/api"; 
//axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? window.location.origin : "/prod_api"; 
// //设置超时
axios.defaults.timeout = 50000;
let myMap = new Map();
const currentExecutingRequests = {};

//发起拦截器 
axios.interceptors.request.use(
    (req) => {
        let originalRequest = req;
        if(req.url.includes("/ayhtml/search/0/500") || req.url.includes("/ayhtml/getProviderHotelRooms/")) {
            // console.log("axios.interceptors.request", req);
            if (currentExecutingRequests[req.url]) {
                const source = currentExecutingRequests[req.url];
                delete currentExecutingRequests[req.url];
                source.cancel();
            }
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            originalRequest.cancelToken = source.token;
            currentExecutingRequests[req.url] = source;
        }
        if (req.showLoading) {
            showLoading()
        }
        return originalRequest;
    },
    (err) => {
        return Promise.reject(err);
    }
);
// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.config.showLoading) {
            setTimeout(() => {
                hideLoading()
            }, 200);
        }
        
        if(response.config.url.includes("/ayhtml/search/0/500") || response.config.url.includes("/ayhtml/getProviderHotelRooms/")){
            // console.log("axios.interceptors.response", response);
            if (currentExecutingRequests[response.request.responseURL]) {
                // here you clean the request
                delete currentExecutingRequests[response.request.responseURL];
            }
        }
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    (error) => {
        console.log("error", error);
        // 将loading移除
        setTimeout(() => {
            hideLoading()
        }, 200);

        if (error && error.response && error.response.status) {
            const { config, response } = error;
            if(config.url.includes("/ayhtml/search/0/500") || config.url.includes("/ayhtml/getProviderHotelRooms/")) {
                const originalRequest = config;
                if (axios.isCancel(error)) {
                    // here you check if this is a cancelled request to drop it silently (without error)
                    return new Promise(() => {});
                }
                if (currentExecutingRequests[originalRequest.url]) {
                    // here you clean the request
                    delete currentExecutingRequests[originalRequest.url];
                }
                // here you could check expired token and refresh it if necessary
                //return Promise.reject(error);
            }

            switch (error.response.status) {
                case 401:
                    ElMessageBox.alert('您的登录信息已失效，点击确定按钮后将跳转到登录页面', '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        callback: (action) => {
                            sessionStorage.clear()
                            window.location.href = window.origin + "/error"
                        },
                    })
                    break;

                default:
                    let responseURL = error.request.responseURL;
                    // 是酒店request重试3次
                    if (responseURL && responseURL.startsWith(hotelServer)) {
                        console.log(error)
                        let config = error.config;
                        if (config && !config.retry) {
                            config.retry = 3;
                        }
                        config.__retryCount = config.__retryCount || 0;
                        if (config.__retryCount < config.retry) {
                            console.log("请求错误,正在重试：" + config.__retryCount);
                            config.__retryCount += 1;
                            setTimeout(function () {
                                axios(config);
                            }, 500);
                        } else {
                            console.log("重试完毕即将跳转到首页。。。");
                            sessionStorage.clear()
                            window.location.href = window.origin + "/index"
                        }
                    } else {
                        sessionStorage.clear()
                        window.location.href = window.origin + "/index"
                        // ElMessageBox.alert(`其他错误--${error.response.status}`, '提示', {
                        //     confirmButtonText: '确定',
                        //     showClose: false,
                        //     callback: (action) => {
                        //         sessionStorage.clear()
                        //         window.location.href = window.origin + "/error"
                        //     },
                        // })
                    }
            }
            return Promise.reject(error.response);
        }
        else{
            return Promise.reject(error);
        }
    }
);

export default {
    post(url, data, headerData, showLoading = true) {
        return new Promise((resolve, reject) => {
            let headers = {
                "token": "Bearer " + headerData.token,
                "bid": headerData.bid,
                "language": headerData.language,
                "locations": headerData.locations,
                "timezone": headerData.timezone,
                "domainname": headerData.domainname,
                "guest": headerData.guest,
                "Content-Type": "application/json"
            };
            axios({
                method: "post",
                url,
                //data: qs.stringify(data),
                data: data,
                headers: headers,
                showLoading: showLoading
            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    get_hotel(url, data, apiType, hotelToken, showLoading = true) {
        return new Promise((resolve, reject) => {
            let headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            };
            axios({
                method: "get",
                url: this.geturl(url, apiType, hotelToken),
                //data: qs.stringify(data),
                data: data,
                headers: headers,
                showLoading: showLoading
            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    get_hotel_ayhtml(url, apiType, ptcrequestuuid, showLoading = true) {
        return new Promise((resolve, reject) => {
            let headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            };
            axios({
                method: "get",
                url: this.geturl_ayhtml(url, apiType, ptcrequestuuid),
                //data: qs.stringify(data),
                // data: data,
                headers: headers,
                showLoading: showLoading
            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    post_hotel(url, data, apiType, hotelToken, showLoading = true) {
        return new Promise((resolve, reject) => {
            let headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            };
            axios({
                method: "post",
                url: this.geturl(url, apiType, hotelToken),
                //data: qs.stringify(data),
                data: data,
                headers: headers,
                showLoading: showLoading
            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    post_hotel_ayhtml(url, data, apiType, ptcrequestuuid, showLoading = true) {
        return new Promise((resolve, reject) => {
            let headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            };
            axios({
                method: "post",
                url: this.geturl_ayhtml(url, apiType, ptcrequestuuid, data),
                //data: qs.stringify(data),
                data: data,
                headers: headers,
                showLoading: showLoading
            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    geturl: function (url, apiType, hotelToken) {
        if (apiType == 1) {
            let endUrl = hotelServer + url + hotelToken;
            return endUrl;
        }
        else if (apiType == 2) {
            let endUrl;

            if (hotelToken) {
                endUrl = hotelServer + url + "?cid=" + hotelToken.uuid + "&token=" + hotelToken.token;
            } else {
                endUrl = hotelServer + url;
            }
            //endUrl = hotelServer + url + "?cid=3ec1e85d-6c38-4554-8d89-f5e8e18149bc&token=3db17d13-0c60-4f1b-86a9-7bc42fccb25f";

            return endUrl;
        }
        else {
            return hotelServer + url;
        }
    },
    geturl_ayhtml: function (url, apiType, ptcrequestuuid, data) {
        let endUrl = ""
        if (apiType == 1) {
            endUrl = hotelServer + url + "?cid=SCTT-AYH5&token=747f4444-a3e3-4a81-8982-72de3896a384";
        }
        else {
            endUrl = hotelServer + url + "?cid=SCTT-AYH5&token=747f4444-a3e3-4a81-8982-72de3896a384&ptcrequestuuid=" + ptcrequestuuid;
        }
        //判断当前是查询酒店列表，将agent加入url中作为中止请求的唯一标记，如果前端用户重复搜寻将中止前一个相同请求
        if((url.includes("/ayhtml/search/0/500") || url.includes("/ayhtml/getProviderHotelRooms/")) && data){
            endUrl += "&sign_agent=" + data.agent;
        }
        return endUrl;
    }
};
