import i18n from "./i18n";
import { createApp } from "vue";
import App from "./App.vue";
//import store from "./store";
import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist";

import preventReClick from './static/js/preventReClick'
import router from "./router";
import 'element-plus/dist/index.css'
// import '@/icons/index.js'
// import SvgIcon from '@/icons/SvgIcon'
// import {
//   formatDate,
//   formatDateTime,
//   CompareDate,
//   formatNum,
//   formatPrice,
//   MathCeil
// } from "./common.js";

const pinia = createPinia();
pinia.use(piniaPluginPersist);

const app = createApp(App);
app.use(pinia);
app.use(i18n);
app.use(router);

app.use(preventReClick)
// app.component('svg-icon', SvgIcon)

//app.config.globalProperties.$http = http;
//app.config.globalProperties.$qs = qs;


app.mount("#app");
