module.exports = {
  system_err: "시스템 오류",
  loading_api: "데이터 요청 중...",
  location: "시드니",
  menu_home: "홈 페이지",
  menu_ExperienceTicket: "어트랙션 티켓",
  menu_daytour: "당일치기 여행",
  menu_hotel: "호텔",
  menu_mdaytour: "여러 날 둘러보기",
  menu_fooddining: "미식가",
  menu_activity: "체험 활동",
  menu_giftcard: "상품권",
  menu_user_myorder: "예약(거래 가격)",
  menu_user_shoucang: "즐겨찾기",
  menu_user_account: "내 계정",
  menu_user_logout: "로그아웃",
  tourtype: "분류",
  reset: "리셋",
  search_pla: "검색...",
  search_product_pla: "키워드 또는 제품 코드 입력",
  search_discription_pla: "대상 검색 및 선택",
  search_text: "서치",
  label: "레이블",
  page_home: "첫번째",
  language_title: "언어를 선택하십시오.",
  findoutmore: "자세히 알아보기",
  joinus: "가입하세요",
  home_selected: "선택된",
  home_clear: "선택 항목 지우기",
  home_discription: "목적지",
  home_product: "제품 검색",
  home_data: "만든 날짜",
  home_sort: "정렬:",
  home_liked: "위시리스트",
  home_general: "일반",
  home_price: "가격.",
  home_price_hign_low: "높은 가격에서 낮은 가격",
  home_price_low_hign: "낮은 가격부터 높은 가격까지",
  home_world: "월드와이드",
  home_xinpin: "신제품",
  home_hotelbook: "숙박 예약",
  home_hotelbook_tips: "숙박을 예약하려면 클릭하십시오.",
  login_account_pla: "이메일/휴대폰",
  login_password_pla: "암호",
  login_phone_pla: "전화번호",
  login_send_code: "확인코드 전송",
  login_enter_code_pla: "확인 코드 입력",
  login_resend_code: "코드 재발송",
  login_resend_code_in: "코드 재전송 위치",
  login_resend_code_in_s: "초",
  login_phonenumber_error_required: "휴대폰 번호를 입력하십시오.",
  login_phonenumber_error_msg: "올바른 휴대폰 번호를 입력하십시오.",
  login_valid_code_error_msg: "한도가 초과되었습니다.",
  login_title: "로그 인.",
  login_title2: "로그 인/등록하다",
  login_titlemsg: "등록되지 않은 전화 번호로 로그인하면 새 계정이 생성됩니다.",
  login_titlemsg1: "휴대폰 또는 이메일 계정으로 로그인하십시오.",
  login_account_error_msg: "모바일 또는 이메일을 입력하십시오.",
  login_password_error_msg: "암호를 입력하십시오.",
  login_username_password_error: "입력한 사용자 이름 또는 암호가 잘못되었습니다.",
  login_remember_me: "기억해줘",
  login_phone_login: "보안 코드 로그인/가입",
  login_account_login: "암호로 로그인",
  login_lastname: '성',
  login_firstname: '이름',
  login_birthday: '생년월일',
  setuser_email: '이메일주소를 입력해주세요',
  setuser_okemail: '이메일주소를 정확히 입력해주세요',
  response_password: '비밀번호는 8~20자리 숫자와 문자로 구성됩니다',
  placeholder_repeat_password: '새비밀번호를 다시 입력하세요',
  response_pwd_double: '입력한 두 개의 비밀번호가 일치하지 않습니다',
  coupon_plase_login_lastname: "입력하십시오.성",
  coupon_plase_login_firstname: "입력하십시오.이름",
  orderform_text_choose_login_birthday: '선택하십시오.생년월일',
  setuser_okemail: '이메일주소를 정확히 입력해주세요',
  cancel_noone: "취소없음",
  cancel_pe: "수동취소 및 환불",
  cancel_5: "환불불가",
  cancel_6: "예약 전 무료 취소, 예약 후 환불 불가",
  pdetail_pid: "제품ID",
  pdetail_time: "유용성",
  pdetail_jike: "즉시 확인",
  pdetail_t1: "취소 정책",
  pdetail_soldss: "팔았다",
  pdetail_tab1: "제품 설명",
  pdetail_tab2: "중요 정보",
  pdetail_tab3: "추가 정보",
  pdetail_tab4: "댓글",
  pdetail_location_title: "주소.",
  pdetail_resort_title: "만남의 장소",
  pdetail_exchange_title: "바우처 상환 포인트",
  pdetail_event_starttime: "영업시간",
  pdetail_projectcontain: "포함",
  pdetail_projectexclude: "제외 사항",
  pdetail_da: "대상 주소",
  pdetail_mt1: "연령 제한",
  pdetail_mt2: "메모들",
  pdetail_mt3: "상기시키는 것",
  pdetail_mt4: "계약 조건",
  pdetail_mt5: "비상 연락처",
  pdetail_bn: "지금 예약",
  pdetail_Wishlist: "즐겨찾기",
  pdetail_pricefrom: "부터",
  pdetail_Packageoptions: "패키지 옵션",
  pdetail_selectoptions: "옵션 선택",
  pdetail_selectedpackagedetails: "패키지 세부 정보",
  pdetail_valid: "유효 날짜",
  pdetail_valid_until: "까지 유효",
  pdetail_valid_booking_s: "유효 기간",
  pdetail_valid_booking_e: "주문이 성공적으로 이루어진 후",
  pdetail_valid_booking_months: "몇달.",
  pdetail_valid_booking_years: "몇 해",
  pdetail_likeit: "당신은 또한 ...을 좋아할 것이다.",
  selectdate_title: "날짜/게스트 수 선택",
  selectdate_month1: "1월",
  selectdate_month2: "2월",
  selectdate_month3: "3월",
  selectdate_month4: "4월",
  selectdate_month5: "그럴지도 모른다",
  selectdate_month6: "6월.",
  selectdate_month7: "7월.",
  selectdate_month8: "8월",
  selectdate_month9: "9월.",
  selectdate_month10: "10월",
  selectdate_month11: "11월",
  selectdate_month12: "12월",
  selectdate_week1: "몬",
  selectdate_week2: "화요일",
  selectdate_week3: "수",
  selectdate_week4: "투르",
  selectdate_week5: "프라이",
  selectdate_week6: "앉았다",
  selectdate_week7: "태양.",
  selectdate_addpro: "더 구입하기", //더 구입하기     Buy more    加購
  selectdate_title_session: "세션",
  selectdate_text_travel_times: "이동시간",
  selectdate_text_left: "사용 가능:",
  selectdate_text_well: "재고품이 풍부한",
  selectdate_text_no_session: "선택한 날짜에 사용 가능한 세션이 없으며 예약 보류 상태가 없습니다.",
  selectdate_text_loading_session: "추가 세션 로드...",
  selectdate_text_loading_price: "추가 가격 로드...",
  selectdate_response_session_select: "세션을 선택하십시오.",
  selectdate_response_quantity_min: "예약된 수는 다음보다 작을 수 없습니다.",
  selectdate_response_quantity_zero: "수량을선택하십시오.",
  selectdate_response_inventory_Insufficient: "저주",
  selectdate_skutitle: "수량 선택",
  selectdate_booking_details: "예약내역",
  selectdate_package_type: "패키지 유형",
  selectdate_date_time: "날짜 및 시간:",
  selectdate_price_details: "세부 사항",
  selectdate_please_choose: "계속하려면 모든 필수 필드를 입력하십시오.",
  selectdate_see_all: "모두 보기",
  selectdate_tips1: "*가격은 여행일정 및 특별조건에 따라 변동될 수 있으며, 체크아웃 페이지에 표시된 가격을 참조하시기 바랍니다.",
  selectdate_tips2: "*호주 플랫폼을 사용 중이므로 위챗, 알리페이, 유니온페이 또는 신용카드를 통해 주문 시 은행 거래 수수료가 부과됩니다.",
  orderform_choose_booking: "예약 선택",
  orderform_pay: "지불하다",
  orderform_enter_info: "정보 입력",
  orderform_enter_info_tip: "*신중하게 입력해주세요_ 한번 제출하면 변경이 불가능합니다.",
  orderform_boolean_true: "진실의",
  orderform_boolean_false: "거짓의",
  orderform_valid_date: "유효 날짜",
  orderform_valid_until: "까지 유효",
  orderform_travelinfo: "여행 정보",
  orderform_chuxingren: "참가자",
  orderform_contact_info: "연락처 정보",
  orderform_other_info: "기타 정보",
  orderform_contact_tip: "예약 업데이트에 대해서만 연락드리겠습니다.",
  orderform_title_pickuplocation: "픽업/하차 지점",
  orderform_title_pickuplocation2:'픽업 포인트',
  orderform_title_dropofflocation: "하차점",
  orderform_location_title_no: "것은 아니다.",
  orderform_agentnumber: "참조 예약 번호",
  orderform_remarks: "메모들",
  orderform_booking_tip: "계속'을 클릭하면 예약이 제출됩니다. 다음 단계에서 결제 방법을 선택할 수 있습니다.",
  orderform_continue: "계속하다.",
  orderform_date: "날짜.",
  orderform_time: "시간을",
  orderform_quantity: "양",
  orderform_subtotal: "소계",
  orderform_total: "총",
  orderform_subtotal2: "가격.",
  orderform_payment_amount: "지급금액",
  orderform_text_please_enter: "입력하십시오.",
  orderform_text_correct: "맞아요.",
  orderform_text_choose: "선택하십시오.",
  orderform_required: "입력해야 합니다.",
  orderform_coupon_shop: "샵쿠폰",
  coupon_title: "할인 쿠폰",
  coupon_checking: "확인",
  coupon_checkok: "확인 및 통과",
  coupon_plase_print: "쿠폰 코드를 입력하십시오.",
  coupon_submit: "사용하다",
  coupon_valid_list: "현재 쿠폰 사용",
  coupon_cancel: "취소",
  coupon_shengyu_num: "현재사용가능한번호",
  coupon_btn_confirm: "확인.",
  coupon_btn_cancel_logout: "잘못 클릭",
  coupon_tip_cancel: "취소하시겠습니까? 쿠폰은 유효하지 않으며 예약을 취소할 때 할인 혜택이 없습니다.",
  coupon_Voucher_Invalid_1001: "바우처를 찾을 수 없습니다.",
  coupon_Voucher_Invalid_1007: "바우처가사용되었습니다.",
  coupon_Voucher_Invalid_1008: "바우처가 만료되었습니다.",
  coupon_Voucher_Invalid_1013: "오늘 바우처를 환불했습니다.",
  coupon_notes_title: "주의:",
  coupon_notes_1: "1. 바우처 코드가 맞는지 확인 부탁드리며, 디스커버리 바우처만 환불 가능합니다.",
  coupon_notes_2: "2. 바우처 코드가 사용되었는지 확인하십시오.",
  coupon_notes_3: "3. 바우처는 1일 1매만 환불이 가능합니다.",
  coupon_notes_4: '4. 쿠폰은 오늘 사용하셨고, 내일 사용하시기 바랍니다: "쿠폰은 하루에 한 장만 사용하실 수 있으며", 다음날 사용하시기 바랍니다.',
  coupon_order_amount: "주문금액",
  coupon_p_notes_1: "1. 바우처 코드가 맞는지 확인 부탁드리며, 디스커버리 바우처만 환불 가능합니다.",
  coupon_p_notes_2: "2. 바우처 코드가 사용되었는지 확인하십시오.",
  coupon_p_notes_3: "3. 바우처는 1일 1매만 환불이 가능합니다.",
  myorder_shop_coupon: "샵쿠폰",
  myorder_myorder: "예약",
  myorder_manage_personal_info: "개인 정보 관리",
  myorder_mybookings: "내 예약",
  myorder_wait_for_confirm: "확인 대기",
  myorder_cancelled: "이미 취소됨",
  myorder_bookingdate: "예약 날짜",
  myorder_reviews: "리뷰",
  myorder_credit_limit: "신용잔고",
  myorder_manage_booking_info: "예약 정보 관리",
  myorder_start_date: "여행 날짜",
  myorder_start_or_booking_date: "여행 날짜/예약 날짜",
  myorder_please_choose_date: "날짜 유형을 선택하십시오.",
  myorder_valid_until: "유효 기간",
  myorder_label_duration_of_validity: "유효 시간",
  myorder_sum_payable: "미지급금",
  myorder_total_paid: "총",
  myorder_details: "세부 정보 표시",
  myorder_gotopay: "지불하러 가다",
  myorder_paystatus: "지급여부",
  myorder_bookingstatus: "예약여부",
  myorder_all: "모든.",
  myorder_unpaid: "미납",
  myorder_paid: "유급의",
  myorder_order_status_0: "지불 대기",
  myorder_order_status_1: "확인 대기",
  myorder_order_status_2: "공정.",
  myorder_order_status_3: "완료된",
  myorder_order_status_4: "취소 대기",
  myorder_order_status_5: "취소된",
  myorder_order_status_6: "유보하다",   //待预约    To be reserved       유보하다
  myorder_order_status_7: "예약됨",    //已预约     Reserved      예약됨
  myorder_order_status_8: "여행대기",
  myorder_order_status_9: "약속을 기다리다",
  myorder_search_order: "예약 참조, 제품(이름, 코드)",
  myorder_search_agentcode: "에이전트 코드",
  myorder_search_confirmation_no: "확인 번호",
  myorder_search_bookingcontact: "예약 연락처(이름, 전화, 이메일)",
  myorder_reset: "분명한",
  my_jiesuan: "합의",
  my_accountinfo: "계정 프로필",
  setuser_top: '계속 하려면 다음 정보를 작성해주세요',
  my_name: "이름.",
  my_phone: "전화",
  my_email: "이메일",
  my_pwdop: "암호 설정",
  my_pwdop_tips: "암호를 정기적으로 변경하여 보안 향상",
  my_oldpwd: "현재 암호",
  my_oldpwd_tips: "암호 변경",
  my_newpwd_tips: "새 암호(8-20자, 숫자, 문자 및 특수 기호 1개 이상을 포함해야 함)",
  my_newpwd_pla1: "암호를 입력하십시오",
  my_newpwd_pla2: "암호 확인",
  my_save: "절약하다",
  pay_complete_payment: "완납",
  pay_pay_within1: "내결제",
  pay_pay_within2: "",
  pay_credit_pay: "크레디트 페이",
  pay_credit_amount: "사용 가능한 크레딧",
  pay_alipay: "알리페이",
  pay_wechat: "위챗페이",
  pay_union: "유니온페이",
  pay_hc: "거래 수수료",
  pay_booking_tip: '"지불 지금 지불"을 클릭하여"당신이 우리의 것을 읽고 이해했다는 것에 동의하고',
  pay_booking_tip_a: "계약 조건",
  payreturn_ok: "결제가완료되었습니다!",
  payreturn_email_tip: "예약 정보 및 사용 바우처는 영업일 1~2일 이내에 귀하의 이메일로 발송됩니다.",
  payreturn_orderno: "주문번호",
  payreturn_vieworder: "순서를 보다.",
  location_hot_title: "인기 여행지",
  location_country_city_title: "탐색할 국가 또는 도시 선택",
  location_state_city_title: "대상 선택",
  location_bottom_tips: "아래로 스크롤하여 자세히 보기",
  orderdetail_payment_type_1: "신용 카드",
  orderdetail_payment_type_2: "알리페이",
  orderdetail_payment_type_3: "위챗페이",
  orderdetail_payment_type_4: "유니온 페이",
  orderdetail_payment_type_5: "오프라인결제",
  orderdetail_payment_type_6: "신용잔고",
  orderdetail_payment_type_7: "재충전 한도",
  orderdetail_orderno: "예약 참조",
  orderdetail_agentcode: "에이전트 코드",
  orderdetail_viewvoucher: "바우처 보기",
  orderdetail_pay: "지불된 총계",
  orderdetail_orderdetails: "주문 상세내역",
  orderdetail_contactinfo: "담당자",
  orderdetail_travelinfo: "여행자",
  orderdetail_cancelinfo: "취소 정책",
  orderdetail_bookinginfo: "예약 세부 정보",
  orderdetail_ordertime: "주문 시간",
  orderdetail_paytype: "결제 방법",
  orderdetail_paytime: "결제 시간",
  orderdetail_paydetails: "지불 세부 정보",
  orderdetail_total: "총",
  orderdetail_cancel_order: "주문 취소",
  orderdetail_cancel_modal_1: "현재 주문을 취소하시겠습니까? 현재 무료 취소 가능",
  orderdetail_cancel_modal_2: "현재 주문을 취소하시겠습니까? 현재 취소는 규칙에 따라 청구됩니다.",
  settlement_export: "내보내기",
  settlement_billno: "청구서 번호",
  settlement_startdate: "출발, 스타트",
  settlement_enddate: "끝.",
  settlement_bookings_num: "예약 번호",
  settlement_total: "총",
  settlement_writeoff: "탕감 상태",
  wechat_qrcodetime: "QR 코드 유효성:",
  wechat_qrcodetips1: "위챗 스캔을 사용하여 결제를 완료하십시오.",
  wechat_qrcodetips2: "결제 페이지로 리디렉션되지 않습니까?",
  wechat_qrcodetips3: "위챗 결제를 완료한 후에도 리디렉션되지 않는 경우 예약 페이지로 이동하여 주문 상태를 확인하십시오.",
  wechat_changepay: "지급수단변경",
  b_companyDetails: "회사 세부 정보",
  b_BrandName: "브랜드 이름",
  b_Country: "나라",
  b_City: "도시",
  b_address: "전체 주소",
  b_SettlementPeriod: "결산기간",
  b_CompanyNumber: "회사 사업자 번호",
  b_SettlementCompany: "결산회사명",
  b_Personcharge: "담당자",
  b_Personemail: "담당자 이메일",
  b_Personphone: "담당자 전화번호",
  b_Remarks: "언급",


  home_join: "합류해요",
  home_explore_local: "목적지 탐색",
  home_search: "글로벌 대상 또는 활동 검색",
  home_bg1: "어딜 가든 놀아라.",
  home_bg2: "여행을 즐기고 여러분의 이상적인 나라를 탐험하세요.",
  home_banner_title: "새 이벤트",
  home_recommend: "추천",
  home_view_more: "자세히 보기",
  home_hot_local: "인기 여행지",
  home_view_local: "더 많은 목적지",
  home_newproduct_title: "신제품",
  home_user_info_title: "회원 가입",
  home_user_info1: "가입 및 포인트 적립",
  home_user_info2: "구매 및 적립 포인트",
  home_user_info3: "리뷰 작성 및 포인트 적립",

  footer_about: "우리에 대해",
  footer_join_us: "우리에 가입하세요",
  footer_payment: "결제 채널",
  footer_sctt_note: "SCTT란?",
  footer_user_reg: "사용자 등록",
  footer_user_login: "로그 인.",
  footer_agent: "요원 Marketplace",
  footer_supplier: "유통 및 마케팅 문의",
  footer_right_title: "가입단추를 클릭하면 이용 및 개인 정보 보호 정책의 우리의 조건에 동의를 얻고 있다.",
  footer_input_pla: "제발 구독 정보를 받는 이메일 주소를 입력하다.",
  footer_dingyue: "가입",
  footer_service_hotline: "고객 서비스 핫라인",
  footer_service_hours: "우르 식기",

  copy_tips: "클립보드에 복사된",
  /*
  已复制到剪切板
  英语：Copied to clipboard
  韩语：클립보드에 복사된
  印尼语：Disalin ke papan klip
  马来语：Disalin ke papan keratan
  */

  search_try_key: "다른 목적지를 검색하거나 다른 카테고리를 선택하십시오.",
  search_try_more: "더 많은 목적지 탐색",
  search_result_text: "검색 결과",
  /*
  中文	英文	韩文	马来语	印尼语
  试试搜索不同目的地或选择其他类别	Try searching for different destinations or choose another category	다른 목적지를 검색하거나 다른 카테고리를 선택하십시오.	Cuba cari destinasi lain atau pilih kategori lain	Coba cari tujuan lain atau pilih kategori lain
  探索更多目的地	Explore more destinations	더 많은 목적지 탐색	Teroka lebih banyak destinasi	Jelajahi lebih banyak tujuan
  搜索结果	Search results	검색 결과	Keputusan Carian	Hasil Pencarian
  */
  my_points: "포인트",   //"Points"
  use_points: "포인트 사용",
  date_of_earn: "수익일",   //获得日期：Date of earn 수익일
  date_of_use: "사용일자",    //使用日期：Date of use 사용일자
  valid_until: "유효기간",    //有效期至：Valid until  유효기간


  share_text: "공유하다",

  orderform_agentpay: "지급대리인가격",

  nodata: "데이터 없음",
  hotel_home_cozi: "호텔 찾기",

  shop_trans: '예약(판매 가격)',
  shop_account: '회원들',
  commission: '수수료',
  booking_user: '부커',


  member_title: '멤버',
  member_show_phone: '전화번호',
  member_show_email: '이메일',
  member_join_date: '참여 날짜',

  show_agent_price: "넷트 비율을 나타내다",

  //预约
	yuyue:"예약하다", //预约   make an appointment   예약하다
  selected_quantity: "선택됨",
  next_btn: "다음 단계",

  sku_not_one_tips_1: '별도 구매가 불가하며, "',
  sku_not_one_tips_2: '"와 함께 구매해주세요.',
  /*
  不能单独购买，请和“独立销售sku名称”一同购买。
  */

  sku_not_one_error: "필수 구매 항목을 선택하십시오.",
  /*
  请选择必购商品
  */
  yuyue_title: "예약확인정보",
  yuyue_btn: "예약 제출 중",
  yuyue_submit_tips: "예약을 제출하는 중...",
  check_dingyue: '이 상자를 선택하면 구독에 동의하고 혜택을 받을 수 있습니다',

  hotel_search_destination: "목적지",
  hotel_search_destination_tips: "목적지 또는 호텔 이름",
  hotel_search_time: "시각",
  hotel_search_checkin: "Check in",
  hotel_search_checkout: "Check out",
  hotel_search_room: "방",
  hotel_search_adult_room: "성인/방",
  hotel_search_child_room: "아이/방",
  hotel_search_adult: "성인",
  hotel_search_child: "아이",
  hotel_search_age: "나이",
  hotel_search_price_per_night: "1박당 가격",
  hotel_search_star: "별",
  hotel_search_text: "검색",
  hotel_search_clear_data: "데이터 지우기",
  hotel_search_km: "km",
  hotel_search_room_unit: "방.",
  hotel_search_australia: "호주",
  hotel_search_new_zealand: "뉴질랜드",
  hotel_nightshort: '박',
  hotel_roomshort: '객실',
  hotel_specialrequests: '스페셜',
  hotel_specialrequests_tips: '(요청사항)',
  hotel_remarks: '요청사항을 남겨주세요',
  hotel_guest_title: '제목',
  hotel_guest_info: "고객 정보",
  hotel_guest_enter_one: "승객 이름 입력",
  hotel_guest_enter_all: "모든 승객의 이름을 입력하십시오.",
  hotel_enter_en_name: "당신의 이름의 한글 철자를 입력하세요.",
  hotel_book_back: "승객 수를 업데이트해야 합니까? 수정하려면 이전 페이지로 돌아가십시오.",



  response_session_select: '세션을 선택해주세요',
  response_inventory_Insufficient: '재고부족',
  cancel_1: '당일취소 환불불가 (100% 핸들링비)',
  cancel_2_1: '취소',
  cancel_2_2: '출발 일 전 취소료 부과(%핸들링비)',
  cancel_2_3: '',
  cancel_3_1: '취소 기간',
  cancel_3_2: '출발 일 전 취소료 부과(%핸들링비)',
  cancel_3_3: '',
  cancel_4_1: '취소',
  cancel_4_2: '출발 일 전 취소시 전체환불',

  fee_note_title: "거래 수수료 정보",
  fee_note_1: "1. 다른 결제 채널은 해당 거래 수수료를 부과합니다.",
  fee_note_2: "2. 여행자의 개인적인 사유로 주문이 취소된 경우, 시스템은 주문 취소 조건에 따라 잔여 금액을 환불할 때 거래 수수료를 환불하지 않습니다.",


  sku_not_one_tips_1: '별도 구매가 불가하며, "',
  sku_not_one_tips_2: '"와 함께 구매해주세요.',
  /*
  不能单独购买，请和“独立销售sku名称”一同购买。
  This cannot be purchased separately, please purchase together with "独立销售sku名称".
  별도 구매가 불가하며, "独立销售sku名称"와 함께 구매해주세요.
  */
  sku_not_one_error: "필수 구매 항목을 선택하십시오.",
  /*
  请选择必购商品
  Please select a must buy item
  필수 구매 항목을 선택하십시오.
  */

  my_passenger: '단골 고객',
  breakfast: '조식',
  hotel_tax: "Taxes",  //税   Taxes
  totalprice: '합계',
  booknow: '예약하기',
  
  /*
    待定
    On Request
    요청 시

    立即询价
    Inquire now
    지금 문의

    订单确认将于48个工作小时内给于回复，以最终房态确认为准。
    Confirmation will be subjected to room availability and reply within 48 working hours.
    확인은 48시간 이내에 객실 가능 여부와 회신을 받게 됩니다.
  */
  onrequest_notprice: "요청 시",
  onrequest_booknow: '지금 문의',
  onrequest_tip: "확인은 48시간 이내에 객실 가능 여부와 회신을 받게 됩니다.",
  

  rooms: '객실',
  nightshort: '박',
  hotelroom: '객실',
  my_title: '빈번한 여행자 목록',
  my_add: '자주 여행하는 사람 추가',
  phonenumberError: '핸드폰(모바일)번호를 입력해주세요',
  tip_phone_error: '핸드폰(모바일)번호를 정확히 입력해주세요',
  ok: '확인',
  cancel: '취소',
  delete_tip: '정말 취소하시겠습니까?',
  title_prompt: '즉시',
  my_title2: '예약자정보등록/변경',
  height: '키(cm)',
  weight: '몸무게(kg)',
  // unitcm: 'cm',
  // unitkg: 'kg',
  bnm_title: '이메일주소 변경',
  bnp_title: '핸드폰(모바일)번호 수정하기',
  phonenumberError: '핸드폰(모바일)번호를 입력해주세요',
  response_phone_as: '기존번호와 같은 핸드폰(모바일)번호를 사용할 수 없습니다',
  response_phone_err: '사용된 핸드폰(모바일)번호입니다',
  email_old_no: '이메일주소와 전화번호는 동일할 수 없습니다',
  email_no: '해당 이메일 주소는 이미 사용 중입니다',
  bind_newemail_ok: '바인딩이 완료되었습니다',
  old_pwd_err: '잘못된 비밀번호',
  old_new_equally: '새비밀번호는 기존의 비밀번호와 같을 수 없습니다',
  system_error: '알수없는에러',
  response_done: '비밀번호가 변경되었습니다. 다시 로그인해주세요',
  response_pwd_double: '입력한 두 개의 비밀번호가 일치하지 않습니다',
  shop_title: '샵 관리',
  hotel_like: '선호 호텔',
  hotel_err_title: '죄송합니다, 이 날짜에 사용 가능한 객실이 없습니다. 다른 날짜에 다시 시도하십시오.',


  account_change_nickname: "닉네임 수정",  //修改昵称     Change Nickname     닉네임 수정
  account_nickname: "별명",  //昵稱     Nickname     닉네임
  account_nickname_tips: "닉네임을 입력하십시오.",   //请输入昵称     Please enter a nickname     닉네임을 입력하십시오.
  mostpopular: "가장 인기 있는",   //热门活动     Most Popular      가장 인기 있는
  account_traveler: "빈번한 여행자 정보",    //常旅客管理     Frequent Traveler Info      빈번한 여행자 정보
  account_traveler_tips: "개인 정보 저장 및 예약 시간 단축",    //保存个人信息，预定更快速    Save personal details and book faster    
  account_traveler_add: "여행자 추가",    //常旅客管理     Add Traveler      여행자 추가  


  travel_in_person: "직접",     //本人    In person    직접
  travel_use_default: "기본값으로 사용",     //设为默认出行人    Use as Default   기본값으로 사용
  points_get: "포인트 받기",   //获取积分    Get Points    포인트 받기
  pages_home: "홈",   //首页    Home      홈
  pages_pre: "이전의",   //上一页      Previous     이전의
  pages_next: "다음 분",   //下一页      Next     다음 분
  pages_last: "지난",   //尾页    Last     지난

  account_edit: "수정하다",    //编辑    Edit    수정하다
  account_delete: "삭제",    //编辑    Delete    삭제
  account_profile: "기본정보",    //基本信息    Profile    기본정보
  account_sex_m: "M",    //男    M    
  account_sex_f: "F",    //女    F

  pla_input_cnname: "请输入中文姓名",   //请输入中文姓名    请输入中文姓名      请输入中文姓名
  account_gender: "성별",    //性别      Gender     성별
  account_add_success: "성공 추가",    //新增成功      Add success     성공 추가
  account_change_success: "변경 성공",    //修改成功      Change success     변경 성공
  account_delete_success: "삭제 성공",    //删除成功      Delete success     삭제 성공
  account_cancelled: "취소된",     //已取消删除      Cancelled     취소된
  account_name_double: "중복된 이름",    //姓名重复     Duplicate names     중복된 이름
  account_contact: "연락",    //联系方式    Contact     연락

  public_countrycode: "국가/지역 코드",     //国家/地区代码      Country/Region Code    국가/지역 코드
  public_please_select_countrycode: "국가/지역 코드를 선택하십시오.",    //请选择国码     Please select Country/Region Code     국가/지역 코드를 선택하십시오.

  public_shop: "샵",   //店铺    Shop    샵

  manage_login: "로그인 관리",   //管理登录方式    Manage login    로그인 관리
  mobile_linked_success: "휴대폰 번호가 성공적으로 연결되었습니다.",   //手机号绑定成功    Mobile number linked successfully    휴대폰 번호가 성공적으로 연결되었습니다.

  pwd_tips_1: "암호는 8자 이상이어야 합니다.",   //密码必须至少包含8个字符    Password must contain at least 8 characters    암호는 8자 이상이어야 합니다.
  pwd_tips_2: "암호에 하나 이상의 숫자가 포함되어야 합니다.",   //密码必须至少包含1个数字    Password must contain at least 1 number    암호에 하나 이상의 숫자가 포함되어야 합니다.
  pwd_tips_3: "암호에 최소 1자 이상의 문자가 포함되어야 합니다.",   //密码必须至少包含1个字母    Password must contain at least 1 letter    
  pwd_tips_4: "비밀번호는 최소 1개의 특수 문자를 포함해야 합니다.",   //密码必须至少包含1个特殊字符    Password must contain at least 1 special character    


  click_more: "더 알아보기",    //点击查看更多      Read more     더 알아보기
  hotel_change_search: "검색 변경",   //更新条件     Change search     검색 변경
  hotel_room_type: "객실 유형",    //客房类型     Room type    객실 유형
  hotel_price_room_n: "가격/방/야간",    //均价/每间/每晚     Price/Room/N     가격/방/야간
  hotel_facilities: "호텔 시설",    //内部设施     Hotel facilities      호텔 시설
  hotel_map_other_info: "지도 및 기타 정보",    //地图和其他信息     Map and other information     지도 및 기타 정보
  account_set: "계정 및 암호",     //账户设置      Account and Password     계정 및 암호

  most_num: '최대 용량',
  people: '인원수',
  p_reset: "재설정",
  englist_name: '이름.',
  operation: '작동',
  merchant_shop: "상인",
  amount_change: '금액 변경',
  payment_number: '청구서 번호',
  payment_days: '청구 기간',
  order_number: '주문 수',
  money: '양',
  download: '다운로드.',
  commission_end_number: "커미션 결제 번호",
  payment_way: '결제 방법',
  payment_type: '정산현황',
  import: '중요한',
  commercial_name: '상점 이름',
  shop_name: '상인 이름',
  commercial_currency: '쇼핑 통화',
  shop_currency: '머천트 통화',
  commercial_url: '쇼핑 웹 사이트',
  shop_url: '머천트 웹사이트',
  default_language: "기본 언어",
  default_city: "기본 도시",
  commercial_type: '상점 상태',
  shop_type: '상인 상태',
  commission_b_SettlementPeriod: "수수료 결제 기간",
  commission_payment_way: '결제 방법',
  shop_finance_name: '가맹점의 재무명',
  service_kefu: '고객 서비스',
  pack_up: '收起',
  new_reviews: "최신 리뷰",
  active_reviews: "이벤트 리뷰",
  low_reviews: "<3.0",
  num_reviews: '리뷰',
  all_reviews: '모든 리뷰 보기',
  use_valid_list: "쿠폰 사용",
  coin_certificate: '바우처 상환',
  use_ok_discount: '사용 가능한 쿠폰',
  use_no_discount: '사용 가능한 쿠폰 없음',
  use_rule: '사용 규칙',
  please_coin_certificate: '바우처 번호를 입력하십시오.',
  certificate: '속죄',
  please_ok_certificate: '올바른 상환 코드를 입력하십시오.',
  error_certificate: '현재 상환 코드가 더 이상 유효하지 않습니다.',
  succeed_certificate: '성공적으로 상환됨',
  discount_deduction: '쿠폰 크레딧',
  back: '뒤로',
  full: '',
  ok_user: '에 대한 할인',
  no_restrict: '문턱이 없는',
  more_reduce: '까지',
  Sold_out: "매진",
	date_day_left_1: "남은 ",   //余2      英文为空     남은 2
	date_day_left_2: "",   //中文为空      2 left        韩文为空
  Try_other_dates: "다른 숙박을 시도하세요.",
  ok_minus: '할인하다',
  // start
  in_use: '가능한',
  stop_use: '사용 안 함',
  no_open: '사용할 수 없음',
  myorder_settings: "진흙받이",
  get_message: "Loading price",
  extra_money: '(additional)',
  use_points_loser: '포인트를 사용하지 못했습니다.',
  use_coupon_loser: '쿠폰 사용 실패.',
  // end

  use_coupon_order_tips: "주의: 1.쿠폰은 주문당 한 장만 사용할 수 있습니다. 결제 시 바우처 미사용 금액에 대해서는 추가 환불이 되지 않습니다.",
  use_coupon_order_tips2: "2.쿠폰과 함께 성공적으로 주문을 완료한 후 주문을 취소하면 쿠폰이 귀하의 계정으로 반환되지 않습니다.",
  use_coupon_paynow_tips: "이 주문에는 추가 지불이 필요하지 않습니다. 당신이 주문을 할 때 할인을 사용하고 있는지 확인해 주세요.",

  use_points_order_tips: "주의: 포인트 크레딧으로 성공적으로 주문을 완료한 후 주문을 취소하면 포인트는 계좌로 반환되지 않습니다.",
  flash_sale: "플래시 세일", //秒杀     Flash Sale     플래시 세일
  coming_soon: "시작",  //即将开售     Begins in     시작
  coming_to_end: "종료",  //即將結束     Ends in     종료
  on_sale: "판매 중",  //热销中     On Sale    판매 중
  off_sale: "팔렸어요",   //活動已結束   Sold out    팔렸어요

  shiling_sale: "제철",   //时令产品    In season    제철
  to_receive: '부탁한다',
  to_receive_success: '성공적으로 수신됨',
  invalid_state: '현재 상태가 더 이상 유효하지 않습니다. 다시 로그인하십시오.',
  refund_message: '환불 및 청구서 조정',
  refund: '환불',
  refund_1: '추가 지급',
  refund_1_money: '추가납부금액',
  order_status_err: '현재 주문 상태 오류',
  refunding: '환불 진행 중',
  refunded: '환불됨',
  supplementing: '지금 결제 중',
  supplemented: '유급의',
  footer_dingyue_success: "구독 성공",
  footer_dingyue_double: "이 제품을 구독했습니다.",   //您已经订阅过该产品     You have subscribed to this product    이 제품을 구독했습니다.
  footer_dingyue_error: "구독 실패",
  perfect_message: '프로필 완성',
  perfect_all_message: '현재 계정에 대한 전자 메일 주소가 없습니다. 전체 프로필 페이지로 이동하여 전자 메일 주소를 설정하십시오.',

  /*
  提示：您使用积分抵扣下单成功后，如果取消订单，积分不会退回账户。
  Notice: If you cancel your order after you have successfully placed it with points credit, the points will not be returned to your account.
  주의: 포인트 크레딧으로 성공적으로 주문을 완료한 후 주문을 취소하면 포인트는 계좌로 반환되지 않습니다.
  */

  yushow_title: "특별 선판매",
  yushow_tip1: "비축하다",
  yushow_tip2: "환불 가능",
  yushow_tip3: "온라인 예약",
  yushow_tip4: "셀프 리저브",
  /*
  特惠预售 
  先囤后约
  不约可退
  在线预约
  自行预约
  */
  yushow_popup_tip1: "먼저 주문을 하여 오퍼를 잠그고 여행 날짜를 확인한 다음 특정 여행 날짜를 예약하십시오.",
  yushow_popup_tip2: "예약하지 않으신 경우 주문내역에서 언제든지 100% 환불이 가능합니다.",
  yushow_popup_tip3: '주문 세부 정보에서 "예약 버튼"을 클릭하여 온라인으로 여행 날짜를 예약하면 주문이 즉시 효율적으로 확인됩니다.',
  yushow_popup_tip4: "주문 내역에 있는 자세한 예약 작업을 참조하십시오.",
  pre_sale_button: "완성",   //complete
  pre_sale_note1: "사용 가능한 날짜:",
  pre_sale_note2: "추가 참고 사항:",
  pre_submit_tips:"지금은 사용할 수 없습니다. 고객 서비스에 문의하십시오.",  //暂无库存，请联系客服   Not available at this time, please contact customer service.  지금은 사용할 수 없습니다. 고객 서비스에 문의하십시오.
  
  cash_out: "현금 인출",   //提现    Cash out    현금 인출
  top_up: "보충하다",   //充值    Top up    보충하다
  not_paid: "미지급",  //未结算    Not Paid   미지급
  paid: "유급의",  //已结算   Paid    유급의
  guest_book_tips:'로그인하지 않았습니다. 로그인하고 주문하시겠습니까?',
	
	guest_login_btn: '계정 로그인',    // Account login
	guest_next_btn: '방문객이 주문하다',     // Order as a guest
	guest_login_msg:'현재 로그인하지 않았습니다. 먼저 로그인하거나 등록하십시오.',
	guest_login_btn2:'로그 인/등록하다',
  guest_tips:"방문객 주문을 선택했으니 정확한 주문 정보를 기입해 주십시오.",
	guest_agree_tips1:"방문객이 주문할 때 귀하의 개인 정보는 주문 확인에 사용되고 메일박스는 주문 확인 정보를 받는 데 사용됩니다.주문이 성공하면 개인 정보로 주문서를 셀프 조회할 수 있습니다.",
	guest_agree_tips21:"이미 있는 계정으로 로그인을 원하신다면,",
	guest_agree_tips22:"클릭하십시오.",
  guest_ph_orderno: '주문 번호를 입력하십시오',
	guest_ph_email: '이메일 주소를 입력하십시오',
	guest_search: '주문 조회',
	guest_login: '계정 로그인',
  no_login_active:'주문으로 획득한 실제 포인트에 따라 로그인 후 활동을 완료하면 최대',
  no_login_credits:'포인트까지 적립할 수 있다.',
  login_credits:'이 활동을 완료하여 XX 점을 획득하십시오!',
  myorder_order_status_6_3:"예약 만료",
	myorder_order_status_6_4:"예약 확인 예정",  
	myorder_order_status_6_5:"예약 취소됨", 
  wait_pay:'대기 중인 결제',
  my_head_order:'주문 보기',
  please_order:'주문 번호를 입력하십시오.',
  please_true_order:'올바른 주문 번호를 입력하십시오.',
  about_sctt:'SCTT 정보',
  market_lol:'제휴 플랫폼',
  website_clause:'이용약관',
  privacy_clause:'개인 정보 보호 정책',
  cookie_policy:'쿠키 정책',
  sonsult_kf:'우리에게 연락하세요',
  online_sonsult:'온라인 고객 서비스',
  serve_phone:'서비스 라인',
  serve_time:'서비스 시간',
  h3_txt_hint:"NAT에 가입하여 독점 계약 해제",
  dingyue_policy:'구독을 클릭하면 약관 및 개인 정보 보호 정책에 동의한 것입니다.',
  only_english:'영문만 입력할 수 있습니다.',
  presell_remain:'잔여선매할당량',
  presell_remain1:'현재 구입한 제품은 예약 판매 할당량을 사용합니다. 계속하시겠습니까?',
  presell_remain2:'당신의 구매는 당신의 사전 판매 할당량의 한도를 초과한다. 계속할 경우 사전 판매 가격이 사용되지 않으며 구매 시 사전 판매 할당량이 소비되지 않습니다. 계속하시겠습니까?',
  presell_remain3:'선판매품목과 비선판매품목은함께구매할수없습니다. 계속할 경우 사전 판매 가격이 사용되지 않으며 구매 시 사전 판매 할당량이 소비되지 않습니다. 계속하시겠습니까?',
  send_card:'보내세요',
  until: '까지',
  class_sup_payment: "지불을 보충",    //需补款   sup payment   지불을 보충
  valid_booking_s:'유효 기간',
  valid_booking_months:'몇달.',
  valid_booking_e:'주문이 성공적으로 이루어진 후',
  pre_sale_date: "예약 가능 날짜:", 
  loose_pulley_text1:'크루즈 월드에 오신 것을 환영합니다.',
  loose_pulley_text2:'웹 브라우저를 사용하여 여는 것이 좋습니다.',
  loose_pulley_text3:'언제든지 도움을 요청해 주시면 대단히 감사하겠습니다.',
  loose_pulley_text4:'입력할 때 제목에서 다음을 선택하는 것이 좋습니다.',
  loose_pulley_text5:'크루즈 찾기(또는 디즈니 크루즈 라인과 같은 적합한 크루즈를 찾기 위해 크루즈 라인을 직접 선택)',
  loose_pulley_text6:'먼저 출발 포트를 선택합니다. 예를 들어 시드니/멜버른 등을 입력할 수 있습니다.',
  loose_pulley_text7:'필터 크루즈 라인:',
  loose_pulley_text8:'카니발 크루즈 라인 카니발 / 디즈니 크루즈 라인 디즈니와 같은 인기 있는 것들을 선택하세요.',
  loose_pulley_text9:'검색하려면 클릭',
  loose_pulley_text10:'특정 출발 시간에 대한 항해 일정 페이지 입력',
  loose_pulley_text11:'원하는 항목을 선택하고 계속을 클릭합니다.',
  loose_pulley_text12:'특정 일정 소개 페이지를 입력하고 계속을 클릭합니다.',
  loose_pulley_text13:'모든 여행자/연령을 입력하십시오. 계속을 클릭하십시오.',
  loose_pulley_text14:'실내 클래스 등을 선택하고 계속을 클릭하십시오.',
  loose_pulley_text15:'사용 가능한 캐빈을 보고 선택한 후 계속을 클릭하십시오.',
  loose_pulley_text16:'여행 일정 소개 및 가격 등을 확인하십시오. 입력하려면 아래로 내려가십시오.',
  loose_pulley_text17:'1. 생일, 국적 등 여행자 개인정보',
  loose_pulley_text18:'2. 리드 승객 접점',
  loose_pulley_text19:'3. 추가 사항, 여기서 예약 보류/견적서 발송을 아카이브로 선택하고 나중에 결정할 수 있습니다.',
  loose_pulley_text20:'결제 페이지에 들어갈 때까지 계속하려면 결제 계속을 클릭하십시오. 보증금 또는 전액 결제를 선택할 수 있습니다.',
  loose_pulley_text21:'약관에 동의하고 결제를 완료하려면 클릭하십시오.',
  loose_pulley_text22:'언제든지 도움을 요청할 수 있습니다. 도움이 필요한 경우 견적서를 보내십시오. 신속하게 도와드리겠습니다.',
  loose_pulley_text23:'안부 전해요',
  email_dialog_txt:'이메일 주소가 올바르지 않을 수 있습니다. 변경하고 확인하십시오',
  no_room_num:'호텔 객실 부족',
  
  //start
  guest_tip:"You can check your booking according to the following information",
	guest_order_tip:"Your booking reference",
	guest_email_tip:"Your booking email address",
	nation_title:"국적선택",
	residency_title:"거주국",
	setuser_nation:"국적",
	setuser_residency:"거주국",
  footer_clause1:'使用者條款',
	footer_clause2:'隱私權保護政策',
	footer_clause3:'觀光產品由',
	footer_clause4:'提供',
	footer_clause5:'代錶人',
	footer_clause6:'統一編號',
	footer_clause7:'旅行社註冊編號',
	footer_clause8:'品保協會會員編號',
	footer_clause9:'聯繫地址',
	footer_clause10:'聯繫電話',
	footer_clause11:'傳真',
	footer_clause12:'信箱',
  need_breakfast:'아침 식사 추가',
  no_need_breakfast:'아침식사 금지',
  //end
  
  
  member_title: "사용자",   //成员管理    Member management    회원관리
  member_new: "새 사용자",   //新增成員    New members    신규회원
  member_edit: "구성원 편집",   //编辑成員    Edit members     구성원 편집
  member: "사용자",   //成员   Member   회원
  member_role: "역할",    //角色   role     역할
  member_status: "상황",    //状态    status  상황
  member_status_open: "켜짐",     //正常    ON    켜짐
  member_status_stop: "꺼짐",     //停用    OFF    꺼짐
  member_creation_time: "만든 날짜",   //创建时间   Creation time  작성시간
  
  member_send_email: "회원에게 계정 활성화 전자 메일 보내기",    //向成员发送账号激活邮件      Send account activation email to members    회원에게 계정 활성화 전자 메일 보내기
  member_auth_error: "활성화 실패",   //激活失败   Activation failed  활성화 실패
  member_auth_ok:"활성화에 성공하면 로그인 페이지로 리디렉션됩니다",   //激活成功，将为您跳转到登录页   Activation is successful and you will be redirected to the login page   활성화에 성공하면 로그인 페이지로 리디렉션됩니다
  
  member_auth_btn: "활성화",     //激活    Activation    활성화

  member_auth_tips: "계정을 활성화하려면 암호를 입력하십시오.",
  member_auth_load_error: "오류, 홈 페이지로 리디렉션됩니다",

  child_age:"아동 연령",
  ask_price: "견적서",
  account_add: '새로 추가됨',
  guest_name:'손님 이름',
  city_name: '도시/일정일수',
  people_time: '작업자/날짜',
  follow_uping: '팔로우업 중',
  place_order: '주문 완료',
  ask_price_detail:"조회서 상세",
  child_num: "어린이 수",
  guest_demand: '손님 기타 설명',
  data_num: '일정일수',
  hotel_start: '호텔 별',
  begain_city: '출발 도시',
  over_city: '엔딩 시티',
  ask_price_record: "문의 제품 기록",
  select_product: '제품 선택',
  product_resource: '프로덕트 리소스',
  num_data:'수량/출발일',
  product_card_name: '제품 번호/이름',
  discription_pla_city: "목적지 도시",
  select: '선택',
  revice_price: '판매가 조정',
  select_go_data:'이동일 선택',
  advance: '예약',
  generate_order: '조회서 생성',
  payment_type: '결제일 유형' ,
  data_type_1:'주문일' ,
  data_type_2:'나들이일' ,
  pay_type_1: '선입금' ,
  pay_type_2:'단결',
  pay_type_3:'주결',
  pay_type_4:'반달결',
  pay_type_5: '달결',
  day:'일',
  no_cancel:'탕감 없음',
  yes_cancel:'탕감',
  refund_message2: '지불' ,
  sup_handsel:'보증금' ,
  sup_final:'균형',
  no_begain_pay: '결제가 활성화되지 않았습니다',
  sup_handsel_text: '적은 보증금으로 장소를 예약할 수 있으며, 전액 지불은 연기됩니다',
  pay_pay_within3: '지불은 이전에 완료되어야 합니다',
  login_break:'현재 계정이 업데이트되었습니다. 다시 로그인하십시오',
  begain_pay_monkey:'에서 잔금을 치르다',
  cancel_service_charge:'참고: 주문 거래 수수료는 환불되지 않습니다',
  confirm_cancel_order1:'현재 주문을 취소하시겠습니까? 현재 취소 시에는 규칙에 따라 주문 손실 및 거래 수수료가 부과됩니다.',
  confirm_cancel_order2:'현재 주문을 취소하시겠습니까? 현재 취소 시에는 규칙에 따라 주문 손실 및 거래 수수료가 부과됩니다.',
  pre_submit_tips2:'고객 서비스에 문의하십시오.',
  fp: '비밀번호찾기',
  response_unknown_error: '인증코드 에러',
  verification_code_error: '보안코드에러',
  fill: '새비밀번호 설정',
  set_newpassword_failed: '비밀번호 재설정이 실패하였습니다',
  re_login: '비밀번호가 재설정되었습니다. 다시 로그인해주세요',
  new_pwd: '새비밀번호를 입력하세요',
  email_no_account:'이 이메일 주소는 계정에 연결되어 있지 않습니다',
  phone_get_psd:'메시지를 통해 암호 수정',
  email_get_psd:'전자 메일을 통해 암호 수정',
  em: '이메일주소',
  response_phone_no:"휴대폰 번호를 사용할 수 없습니다.",
  email_login:'이메일을 통해 로그인/등록',
  login_titlemsg_email: "등록되지 않은 전자 메일 주소로 로그인하면 새 계정이 생성됩니다",
  reg_error: '시스템에러',
  verification_code_error: '보안코드에러',
  remt2: 'SCTT 서비스 동의',
  login_yzm: 'OTP로 로그인',
  no_account: "아직 계정이 없습니다.",
  sign_up:"계정 생성",
  login_change_1:'로그인/등록',
  login_change_2: '나는 이미 읽고 동의했습니다.',
  login_change_3: '약관 및 성명',
  login_change_4: '이메일 사용',
  login_change_5: '나는 계정 비밀번호를 가지고 있다',
  login_change_6:'지금 로그인',
  login_change_7: '휴대폰 사용',
  please_wait:'잠시만요',
  please_select_pay:'한도가 부족하니 다른 결제수단을 선택해주세요',
  qm_need_login:'고객 서비스에 문의하려면 로그인/등록이 필요합니다',
  unsubscribe1: '구독을 취소했습니다.',
	unsubscribe2: '다시 구독하려면 ',
	unsubscribe31: '가게로 가 주세요 ',
	unsubscribe32: '개인 센터 ',
	unsubscribe33: '페이지를 다시 엽니다.',
  dingyue_email: '이메일 구독',
  dingyue_rule1:'업데이트 및 프로모션',
  dingyue_rule2:'NAT의 최신 캠페인, 할인 및 새로운 기능에 대해 가장 먼저 알아보십시오',
  my_order_token:'마이 오더',
  go_page:'에 가요',
  order_shop_trans:'(필터 소스)'
  /*
  请完善密码，以激活您的账号。
  Please complete your password to activate your account.
  계정을 활성화하려면 암호를 입력하십시오.

  信息异常，将为您跳转到首页
  Error, will redirect you to the home page
  오류, 홈 페이지로 리디렉션됩니다
  */
 ,
 hotel_check_in:"체크인",
 hotel_check_out:"체크아웃",
 hotel_book_step_room:'방',
 hotel_book_step_customer:'고객.',
 hotel_book_step_payment:'지불',
 orderfrom_customer_details:'고객 세부 정보',
 orderfrom_lead_traveller_only:'1인 게스트의 정보', 
 orderfrom_all_travellers:'모든 여행자', 
 orderfrom_first_name:'이름', 
 orderfrom_last_name:'성',
 m_logintime:'마지막 로그인',
 
 pla_ipt_name:'이름을 입력하십시오',
 error_order:'오류 - 주문 작성',
  pla_kefu_dispose:'고객 서비스에 문의하십시오',

 quota_pay:"한도 충전",
 quota_pay2: '온라인 충전',
 quota_away_pay: '즉시결제',
 quota_get_total:'到账',
 quota_line_remit:'오프라인 이체 송금',
 quota_pay_record:'지급기록 제출',
 quota_pay_success:'이체완료, 여기에 증빙서류 제출, 재무승인 후 계좌번호 충전',
 quota_company_name: '회사 이름',
 quota_bank_name: '계좌개설은행',
 quota_bank_accound: '계좌개설은행 계좌번호',
 quota_type:'입금상태',
 quota_pay_date:'지급일',
 quota_upload_proof: '업로드 증명서',
 quota_upload: '업로드',
 quota_only_type:'한 개의 이미지 또는 pdf 형식의 파일만 업로드할 수 있습니다.',
 quota_result: '예시효과',
 quota_please_type:'파일 올려주세요',
 quota_please_money:"금액을 입력하세요",
 quota_min_money:'금액 0보다 작을 수 없음',
 quota_please_date: '시간을 선택하십시오',

 select_sku: '선택된 패키지',
 selectdate_other_skutitle: '기타 사용 가능한 패키지',
 selectdate_no_skutitle: '지정된 날짜에 사용할 수 있는 다른 패키지 없음',
 other_goodsitem_pay_btn: "이 대체 패키지를 예약하십시오.",
 

 double_bed:"더블베드", //더블베드
 two_beds:"두 개의 침대", //두 개의 침대
 early_check_in: "얼리 체크인", //얼리 체크인
 late_check_in:"늦은 체크인", //Late Check in
 late_check_out:"레이트 체크아웃(추가 비용 발생 가능)", //Late Check out(추가 비용 발생 가능)
 adjacent_rooms: "인접방", //인접방
 honeymoon: "허니문", //허니문
 InstantConfirmation: "인스턴트 컨펌",   //立即确认    Instant Confirmation
 pay_check_price: '죄송합니다, 이 방은 매진입니다.',    //抱歉，您订购的房间已经完售!     Sorry, This room is sold out.    죄송합니다, 이 방은 매진입니다.
};
