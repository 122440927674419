/* eslint-disable prettier/prettier */

export function formatDate(dates) {
	var date = new Date(dates);
	var YY = date.getFullYear();
	var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
	var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
	return YY + "-" + MM + "-" + DD;
};

export function formatDatei18n(date_str, lang) {
	//Jan.一月，Feb.二月，Mar.三月，Apr.四月，May五月，Jun.六月，Jul.七月，Aug.八月，Sep.九月，Oct.十月，Nov.十一月，Dec.十二月。
	
	let result = date_str;

	let year = date_str.substr(0,4);
	let month = date_str.substr(5,2);
	let day = date_str.substr(8,2);
	let time = "";
	if(date_str.length > 10) {
		time = " " + date_str.substr(10);
	}

	if(lang=="zh-CN" || lang=="zh-TW"){
		result = year + "年" + month + "月"+ day + "日" + time;
	}
	else{
		switch(parseInt(month)){
			case 1:{
				result = day + "-Jan-" + year + time;
				break;
			}
			case 2:{
				result = day + "-Feb-" + year + time;
				break;
			}
			case 3:{
				result = day + "-Mar-" + year + time;
				break;
			}
			case 4:{
				result = day + "-Apr-" + year + time;
				break;
			}
			case 5:{
				result = day + "-May-" + year + time;
				break;
			}
			case 6:{
				result = day + "-Jun-" + year + time;
				break;
			}
			case 7:{
				result = day + "-Jul-" + year + time;
				break;
			}
			case 8:{
				result = day + "-Aug-" + year + time;
				break;
			}
			case 9:{
				result = day + "-Sep-" + year + time;
				break;
			}
			case 10: {
				result = day + "-Oct-" + year + time;
				break;
			}
			case 11:{
				result = day + "-Nov-" + year + time;
				break;
			}
			case 12:{
				result = day + "-Dec-" + year + time;
				break;
			}
		}
	}
	return result;
}

export function CompareDate(date1, date2) {
	if (date1 && date2) {
		let temp1 = Date.parse(new Date(formatDate(date1)));
		let temp2 = Date.parse(new Date(formatDate(date2)));
		if (temp1 >= temp2) {
			return true;
		}
	}
	return false;
};

export function formatDateTime(dates, type) {
	dates = dates.replace(/-/g, '/');  //兼容ios 不能有- 要换成/
	var date = new Date(dates);
	var YY = date.getFullYear();
	var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
	var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
	var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
	var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
	if (type && type == "DT") {
		return YY + "-" + MM + "-" + DD + " " + hh + ':' + mm + ':' + ss;
	}
	return hh + ':' + mm + ':' + ss;
};

export function formatNum(n) {
	return n < 10 ? '0' + n : n;
};
// 将数字转换为逗号隔开的千分位格式
export function formatPrice(num) {
	num = Math.ceil((parseFloat(num) * 100).toFixed(1)) / 100;
	num += '';
	if (!num.includes('.')) num += '.';
	let temp = num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
		return $1 + ',';
	}).replace(/\.$/, '');
	if (temp.includes('.')) {
		if (parseFloat(temp.split(".")[1]) == 0) {
			return temp.split(".")[0];
		}
	}
	return temp;
};
export function MathCeil(fixed3) {
	/* console.log(fixed3);
	console.log(parseFloat(fixed3) * 100);
	console.log(Math.ceil((parseFloat(fixed3) * 100).toFixed(1)));
	console.log(Math.ceil((parseFloat(fixed3) * 100).toFixed(1)) / 100); */

	return Math.ceil((parseFloat(fixed3) * 100).toFixed(1)) / 100;
};
//验证是否有特殊字符 
export function checkSpecialVal(t) {
	var patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
	if (!patrn.test(t)) {// 如果包含特殊字符返回false
		return false;
	}
	return true;
}
export function checkEnVal(t) {
	var re = /[a-z]/i;
	if (re.test(t)) {
		return true;
	}
	else {
		return false;
	}
}
export function getStorage(key) {
	try {
		let result = JSON.parse(localStorage.getItem(key));
		if (!result) {
			result = JSON.parse(sessionStorage.getItem(key));
		}
		return result;
	}
	catch {
		return false;
	}
}

/**
 * @param startDate  开始日期 yyyy-MM-dd
 * @param enDate  结束日期 yyyy-MM-dd
 * @returns {number} 两日期相差的天数
 */
export function getDaysBetween(startDate, enDate) {
	const sDate = Date.parse(startDate)
	const eDate = Date.parse(enDate)
	// if (sDate > eDate) {
	// 	return 0
	// }
	// if (sDate === eDate) {
	// 	return 1
	// }
	const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
	return days
}
export function DateDiff(sDate1, sDate2) {
	//sDate1和sDate2是2006-12-18格式
	var aDate1, aDate2, oDate1, oDate2, iDays;
	aDate1 = sDate1.split("-");
	oDate1 = new Date(aDate1[1] + '/' + aDate1[2] + '/' + aDate1[0]);
	aDate2 = sDate2.split("-");
	oDate2 = new Date(aDate2[1] + '/' + aDate2[2] + '/' + aDate2[0]);
	iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
	return iDays
}
export function dateadd_fun(datetemp, day){
    var date1 = new Date(datetemp),
    time1=date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+date1.getDate(); 
    var date2 = new Date(date1);
    date2.setDate(date1.getDate()+day);
    var time2 = date2.getFullYear()+"-"+(date2.getMonth()+1)+"-"+date2.getDate();
    return time2;
}
export function isDuringDate(beginDateStr, endDateStr, curDateStr){
	var curDate = new Date(curDateStr),
		beginDate = new Date(beginDateStr),
		endDate = new Date(endDateStr);
	if(curDate >= beginDate && curDate <= endDate){
		return true;
	}
	else{
		return false;
	}
}

export function guid() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		s4() + '-' + s4() + s4() + s4();
}
export function default_language_id() {
	return 2
}
export function default_local() {
	return "zh-CN"
}
export function jishiqi_num() {
	return 1800
}
export function guidRandom() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}