
import { mainStore } from "../store/index";
import http from "./request/http";

export default {
    GetCommon(bid){
        console.log(bid);
        const store = mainStore();
        let usercommon = store.commonData;

        if(usercommon && usercommon.configure && usercommon.configure.webstyle && usercommon.configure.bid == bid){
            //let result = {data:{ code: 80000, data: null, msg: "ok"}};
            let result = { code: 80000, data: null, msg: "ok"};
            // return new Promise((resolve,reject)=>{
            //     resolve(result);
            // })
            return Promise.resolve(result);
        }
        else{
            // store.logout();
        }
        
        let thisDate = new Date();
        let timezone = thisDate.getTimezoneOffset();
        
        let host = "sell-dev.sctt.net";
        //let host = "ptc.sctt.net";
        if(process.env.NODE_ENV === 'production'){
            if(process.env.VUE_APP_MODE === "test"){
                host = "sell-dev.sctt.net";
                //host = "ptc.sctt.net";
            }
            else{
                host = window.location.host;
                //host = "ptc.sctt.net";
            }
        }
        console.log("common没值");
        console.log("host：" + host);
        
        return http.post("/common/configure", null,{
            token: '',
            bid: bid,
            language: 0,
            timezone: timezone,
            locations: 0,
            domainname: host,
            guest: 2,
        })
    }
};