import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, onMounted, getCurrentInstance, computed } from 'vue';
import { mainStore } from "@/store/index";
import { useI18n } from "vue-i18n";
export default {
  __name: 'App',

  setup(__props) {
    const {
      locale
    } = useI18n(); // 使用setup 代替 data
    // const { proxy } = getCurrentInstance();  //相当于this;

    console.log("1-开始创建组件-----setup()");
    const store = mainStore();
    let commonData = ref(store.commonData);
    let headerData = ref(store.getHeaderData); // const includedComponents = computed(() => {
    //   console.log(store.includedComponents,'store.includedComponentsxxxxxxxxxxxxxxxx');
    //         return store.includedComponents
    //       })

    onMounted(() => {
      console.log('mounted-----渲染次数');
      window.addEventListener('resize', () => {
        keepRatio();
      }); // let _7moor_language = "ZHCN";
      // if(headerData.value.language == 1){
      //     _7moor_language = "EN";
      // }
      // else if(headerData.value.language == 2){
      //     _7moor_language = "ZHCN";
      // }
      // else if(headerData.value.language ==3){
      //     _7moor_language = "ZHCN";
      // }
      // else if(headerData.value.language ==4){
      //     _7moor_language = "EN";
      // }
      // if(headerData.value.bid == '456817'){

      localStorage.setItem("SCTT_router_num", 1);
      console.log(localStorage.getItem("SCTT_router_num"), 'xxxxxxxxxxxxx'); // if(commonData.value && commonData.value.configure && commonData.value.configure.chatid) {
      //   let _7moor_script = document.createElement('script');
      //    _7moor_script.async = 'async';
      //   _7moor_script.src = `https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=${commonData.value.configure.chatid}&autoShow=false&language=` + _7moor_language;
      //   document.body.appendChild(_7moor_script);
      // }
      // _7moor_script.src = "https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=82a43aa0-6fb8-11ed-9b32-5bd1d2a293e9&autoShow=false&language=" + _7moor_language;
      // document.body.appendChild(_7moor_script);
      // }
    }); // console.log("proxy：", proxy);
    // console.log("proxy.$i18n：", proxy.$i18n);
    // console.log(store.locale);

    const keepRatio = () => {
      var browser = {
        versions: function () {
          var u = navigator.userAgent,
              app = navigator.appVersion;
          return {
            trident: u.indexOf('Trident') > -1,
            //IE内核
            presto: u.indexOf('Presto') > -1,
            //opera内核
            webKit: u.indexOf('AppleWebKit') > -1,
            //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
            //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/),
            //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
            //android终端
            iPhone: u.indexOf('iPhone') > -1,
            //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1,
            //是否iPad
            webApp: u.indexOf('Safari') == -1,
            //是否web应该程序，没有头部与底部
            weixin: u.indexOf('MicroMessenger') > -1,
            //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq" //是否QQ

          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }; //判断是否手机端访问

      var userAgentInfo = navigator.userAgent.toLowerCase();
      var Agents = ["android", "iphone", "symbianos", "windows phone", "ipad", "ipod"]; //var ly=document.referrer;  //返回导航到当前网页的超链接所在网页的URL

      console.log(userAgentInfo);

      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) >= 0) {
          window.location.href = window.location.href.replace(".sctt.net", ".m.sctt.net"); // console.log("wap端判断进来了")
        }
      }
    };

    locale.value = store.locale;
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};