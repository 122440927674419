
const paytime = 480;
const pagesize = 20;



const oss_static_url = "@/"
const oss_static_img = "@/static/image/"
const oss_static_img_home = "@/static/image/home/"
const oss_static_img_home_location = "@/static/image/home/location/"


// const oss_static_url = "https://scttpc-static-uat.sctt.net"
// const oss_static_img = "https://scttpc-static-uat.sctt.net/img"
// const oss_static_img_home = "https://scttpc-static-uat.sctt.net/img"
// const oss_static_img_home_location = "https://scttpc-static-uat.sctt.net/img"


//const oss_static_url = "https://scttpc-static-dev.sctt.net"
// const oss_static_img = "https://scttpc-static-dev.sctt.net/img"
// const oss_static_img_home = "https://scttpc-static-dev.sctt.net/img"
// const oss_static_img_home_location = "https://scttpc-static-dev.sctt.net/img"


//headers.domainname
const headers_domainname = 'sell-dev.sctt.net';
//const headers_domainname = 'ptc.sctt.net';


export default {
  paytime,
  pagesize,
  oss_static_url,
  oss_static_img,
  oss_static_img_home,
  oss_static_img_home_location,
  headers_domainname
}
