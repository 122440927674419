module.exports = {
  system_err: "System Error",
  loading_api: "Requesting data...",
  location: "Sydney",
  menu_home: "Home Page",
  menu_ExperienceTicket: "Attraction Ticket",
  menu_daytour: "Day Tour",
  menu_hotel: "Hotels",
  menu_mdaytour: "Multiple Day Tour",
  menu_fooddining: "Gourmet",
  menu_activity: "Experience Activity",
  menu_giftcard: "Gift Voucher",
  menu_user_myorder: "Bookings (Trade Price)",
  menu_user_shoucang: "Favorites",
  menu_user_account: "My Account",
  menu_user_logout: "Log out",
  tourtype: "Categories",
  reset: "Reset",
  search_pla: "Search for...",
  search_product_pla: "Enter keyword or product code",
  search_discription_pla: "Search and select a destination",
  search_text: "Search",
  label: "Labels",
  page_home: "First",
  language_title: "Select your language",
  findoutmore: "Find out more",
  joinus: "Join us",
  home_selected: "Selected",
  home_clear: "Clear Selection",
  home_discription: "Destination",
  home_product: "Product Search",
  home_data: "Date Created",
  home_sort: "Sort:",
  home_liked: "Wishlist",
  home_general: "General",
  home_price: "Price",
  home_price_hign_low: "Price from high to low",
  home_price_low_hign: "Price from low to high",
  home_world: "Worldwide",
  home_xinpin: "New Products",
  home_hotelbook: "Accommodation Booking",
  home_hotelbook_tips: "Click to book Accommodation",
  login_account_pla: "Email/Mobile Phone",
  login_password_pla: "Password",
  login_phone_pla: "Phone number",
  login_send_code: "Send verification code",
  login_enter_code_pla: "Enter Verification Code",
  login_resend_code: "Resend code",
  login_resend_code_in: "Resend code in ",
  login_resend_code_in_s: " sec",
  login_phonenumber_error_required: "Please enter mobile phone number",
  login_phonenumber_error_msg: "Please enter correct mobile phone number",
  login_valid_code_error_msg: "The limit has been exceeded",
  login_title: "Log in",
  login_title2: "Log in/Sign up",
  login_titlemsg: "Logging in with an unregistered phone number creates a new account",
  login_titlemsg1: "Please log in with your mobile phone or email account",
  login_account_error_msg: "Please Enter your mobile or email",
  login_password_error_msg: "Please Enter password",
  login_username_password_error: "The username or password entered is incorrect",
  login_remember_me: "Remember me",
  login_phone_login: "Security Code Login/JoinUs",
  login_account_login: "Log in with password",
  login_lastname: 'Surname',
  login_firstname: 'First name',
  login_birthday: 'Date Of Birth',
  setuser_okemail: 'Please enter correct email address',

  cancel_noone: "No cancellation",
  cancel_pe: "Manual cancellation and refund",
  cancel_5: "Non refundable",
  cancel_6: "Free Cancellation before reservation, non refundable after reservation",

  pdetail_pid: "Product ID",
  pdetail_time: "Availability",
  pdetail_jike: "Instant Confirmation",
  pdetail_t1: "Cancellation Policy",
  pdetail_soldss: "Sold",
  pdetail_tab1: "Product Description",
  pdetail_tab2: "Important Information",
  pdetail_tab3: "Additional Information",
  pdetail_tab4: "Comment",
  pdetail_location_title: "Address",
  pdetail_resort_title: "Meeting point",
  pdetail_exchange_title: "Voucher Redeem point",
  pdetail_event_starttime: "Business hours",
  pdetail_projectcontain: "Inclusions",
  pdetail_projectexclude: "Exclusions",
  pdetail_da: "Destination Address",
  pdetail_mt1: "Age Limit ",
  pdetail_mt2: "Notes",
  pdetail_mt3: "Reminder",
  pdetail_mt4: "Terms & Conditions",
  pdetail_mt5: "Emergency Contact",
  pdetail_bn: "Book Now",
  pdetail_Wishlist: "Favourite",
  pdetail_pricefrom: "From",
  pdetail_Packageoptions: "Package options",
  pdetail_selectoptions: "Select options",
  pdetail_selectedpackagedetails: "Package Details",
  pdetail_valid: "Valid Date",
  pdetail_valid_until: "Valid until",
  pdetail_valid_booking_s: "Valid for ",
  pdetail_valid_booking_e: " after the order is placed successfully",
  pdetail_valid_booking_months: " months",
  pdetail_valid_booking_years: " years",
  pdetail_likeit: "You might also like...",
  selectdate_title: "Select date/Number of guests",
  selectdate_month1: "January",
  selectdate_month2: "February",
  selectdate_month3: "March",
  selectdate_month4: "April",
  selectdate_month5: "May",
  selectdate_month6: "June",
  selectdate_month7: "July",
  selectdate_month8: "August",
  selectdate_month9: "September",
  selectdate_month10: "October",
  selectdate_month11: "November",
  selectdate_month12: "December",
  selectdate_week1: "Mon",
  selectdate_week2: "Tues",
  selectdate_week3: "Wed",
  selectdate_week4: "Thur",
  selectdate_week5: "Fri",
  selectdate_week6: "Sat",
  selectdate_week7: "Sun",
  selectdate_addpro: "Buy more", //더 구입하기     Buy more    加購
  selectdate_title_session: "Session",
  selectdate_text_travel_times: "Travel time",
  selectdate_text_left: "Available:",
  selectdate_text_well: " well stocked",
  selectdate_text_no_session: "There are no available session on the selected date, no booking hold",
  selectdate_text_loading_session: "Load more session...",
  selectdate_text_loading_price: "Load more price...",
  selectdate_response_session_select: "Please select the session",
  selectdate_response_quantity_min: "The number of reserved can not be less than",
  selectdate_response_quantity_zero: "Please select quantity.",
  selectdate_response_inventory_Insufficient: "Low stocks",
  selectdate_skutitle: "Select Quantity",
  selectdate_booking_details: "Booking details",
  selectdate_package_type: "Package Type",
  selectdate_date_time: "Date & Time:",
  selectdate_price_details: "Details",
  selectdate_please_choose: "Complete all required fields to continue",
  selectdate_see_all: "See all",
  selectdate_tips1: "*Prices may vary according to travel dates and special conditions, please refer to the price indicated on the checkout page.",
  selectdate_tips2: "*As you are using the Australian platform, bank transaction fees will be charged when paying for your order via WeChat, Alipay, UnionPay or credit card.",
  orderform_choose_booking: "Select booking",
  orderform_pay: "Pay",
  orderform_enter_info: "Enter info",
  orderform_enter_info_tip: "*Please enter your info carefully_ Once submitted it cannot be changed.",
  orderform_boolean_true: "true",
  orderform_boolean_false: "false",
  orderform_valid_date: "Valid Date",
  orderform_valid_until: "Valid until",
  orderform_travelinfo: "Travel info",
  orderform_chuxingren: "Participant",
  orderform_contact_info: "Contact info",
  orderform_other_info: "Other info",
  orderform_contact_tip: "We''ll only contact you about updates to your booking",
  orderform_title_pickuplocation: "Pick up/drop off point",
  orderform_title_pickuplocation2:'pick up point',
  orderform_title_dropofflocation: "Drop off point",
  orderform_location_title_no: "Not",
  orderform_agentnumber: "Your reference booking No.",
  orderform_remarks: "Notes",
  orderform_booking_tip: 'Your booking will be submitted once you click "Continue". You can choose your payment method in the next step.',
  orderform_continue: "Continue",
  orderform_date: "Date",
  orderform_time: "Time",
  hotel_check_in:"Check-in",
  hotel_check_out:"Check-out",
  orderform_quantity: "Quantity",
  orderform_subtotal: "Subtotal",
  orderform_total: "Total",
  orderform_subtotal2: "Price",
  orderform_payment_amount: "Payment amount",
  orderform_text_please_enter: "Please enter",
  orderform_text_correct: "Correct",
  orderform_text_choose: "Please select",
  orderform_required: "Must enter",
  orderform_coupon_shop: "Shop coupon",
  coupon_title: "Discount Coupon",
  coupon_checking: "Verify",
  coupon_checkok: "Verified and Passed",
  coupon_plase_print: " Please enter the Coupon code",
  coupon_submit: "Use",
  coupon_valid_list: "Use Current Coupon",
  coupon_cancel: "Cancel",
  coupon_shengyu_num: "Current available number",
  coupon_btn_confirm: "Confirm",
  coupon_btn_cancel_logout: "Click Wrong",
  coupon_tip_cancel: "Are you sure you want to cancel?  The coupon will not be invalid and the booking will has no discout when you cancel the booking",
  coupon_Voucher_Invalid_1001: "Voucher not found.",
  coupon_Voucher_Invalid_1007: "Voucher has been used.",
  coupon_Voucher_Invalid_1008: "Voucher has expired.",
  coupon_Voucher_Invalid_1013: "You have redeemed the voucher today.",
  coupon_notes_title: "Notes:",
  coupon_notes_1: "1. Please verify if the voucher code is correct, and we can only redeem discover voucher.",
  coupon_notes_2: "2. Please verify if the voucher code had been used.",
  coupon_notes_3: "3. Only one voucher can be redeemed per day.",
  coupon_notes_4: "4. You have used the coupon today, please use it tomorrow: you can only use one coupon a day, please use it the next day.",
  coupon_order_amount: "Order amount",
  coupon_p_notes_1: "1. Please verify if the voucher code is correct, and we can only redeem discover voucher.",
  coupon_p_notes_2: "2. Please verify if the voucher code had been used.",
  coupon_p_notes_3: "3. Only one voucher can be redeemed per day.",
  myorder_shop_coupon: "Shop coupon",
  myorder_myorder: "Bookings",
  myorder_manage_personal_info: "Manage personal info ",
  myorder_mybookings: "My bookings",
  myorder_wait_for_confirm: "Wait for confirm",
  myorder_cancelled: "Cancelled already",
  myorder_bookingdate: "Booking Date",
  myorder_reviews: "Reviews",
  myorder_credit_limit: "Credit Balance",
  myorder_manage_booking_info: "Manage booking info",
  myorder_start_date: "Travel Date",
  myorder_start_or_booking_date: "Travel Date / booking date",
  myorder_please_choose_date: "Please select a date type",
  myorder_valid_until: "Valid Until",
  myorder_label_duration_of_validity: "Validity Time",
  myorder_sum_payable: "sum payable",
  myorder_total_paid: "Total",
  myorder_details: "Show Details",
  myorder_gotopay: "Go to pay",
  myorder_paystatus: "Payment status",
  myorder_bookingstatus: "Booking status",
  myorder_all: "All",
  myorder_unpaid: "Unpaid",
  myorder_paid: "Paid",
  myorder_order_status_0: "Wait for payment",
  myorder_order_status_1: "Wait for confirm",
  myorder_order_status_2: "Confirmed",
  myorder_order_status_3: "Completed",
  myorder_order_status_4: "Wait for cancel",
  myorder_order_status_5: "Cancelled",
  myorder_order_status_6: "To be reserved",   //待预约    To be reserved       유보하다
  myorder_order_status_7: "Reserved",    //已预约     Reserved      예약됨
  myorder_order_status_8: "Wait for travel",
  myorder_order_status_9: "Wait for appoint",
  myorder_search_order: "Booking Reference,Product(Name,Code)",
  myorder_search_agentcode: "Agent code",
  myorder_search_confirmation_no: "Confirmation No",
  myorder_search_bookingcontact: "Booking contact (name,phone,email)",
  myorder_reset: "Clear",
  my_jiesuan: "Settlement",
  my_accountinfo: "Account Profile",
  setuser_top: 'To continue, please complete the following information',
  my_name: "Name",
  my_phone: "Phone",
  my_email: "Email",
  my_pwdop: "Password Setting",
  my_pwdop_tips: "Change your password regularly to improve security",
  my_oldpwd: "Current Password",
  my_oldpwd_tips: "Change password",
  my_newpwd_tips: "New password (8 - 20 characters, must contain at least 1 number, 1 letter and 1 special symbol)",
  my_newpwd_pla1: "Please enter password",
  my_newpwd_pla2: "Confirm password",
  my_save: "Save",
  pay_complete_payment: "Payment",
  pay_pay_within1: "Pay within",
  pay_pay_within2: "",
  pay_credit_pay: "Credit Pay",
  pay_credit_amount: "Available Credit",
  pay_alipay: "Alipay",
  pay_wechat: "WeChat Pay",
  pay_union: "UnionPay",
  pay_hc: "Fee fee",
  pay_booking_tip: 'By clicking "Continue" now you agree that you have read and understood our ',
  pay_booking_tip_a: "Terms & Conditions",
  payreturn_ok: "Payment completed!",
  payreturn_email_tip: "The reservation information and usage voucher will be sent to your email within 1-2 working days",
  payreturn_orderno: "Order number",
  payreturn_vieworder: "view order",
  location_hot_title: "Popular destinations",
  location_country_city_title: "Select a country or city to explore",
  location_state_city_title: "Select a destination",
  location_bottom_tips: "Scroll down to see more",
  orderdetail_payment_type_1: "Credit Card",
  orderdetail_payment_type_2: "Alipay",
  orderdetail_payment_type_3: "WeChat Pay",
  orderdetail_payment_type_4: "Union Pay",
  orderdetail_payment_type_5: "Offline payment",
  orderdetail_payment_type_6: "Credit Balance",
  orderdetail_payment_type_7: "Recharge limit",
  orderdetail_orderno: "Booking Reference",
  orderdetail_agentcode: "Agent code",
  orderdetail_viewvoucher: "View voucher",
  orderdetail_pay: "Total Paid",
  orderdetail_orderdetails: "Order Details",
  orderdetail_contactinfo: "Contact Person",
  orderdetail_travelinfo: "Travel Person",
  orderdetail_cancelinfo: "Cancellation Policy",
  orderdetail_bookinginfo: "Booking Details",
  orderdetail_ordertime: "Order Time",
  orderdetail_paytype: "Payment Method",
  orderdetail_paytime: "Payment Time",
  orderdetail_paydetails: "Payment Details",
  orderdetail_total: "Total",
  orderdetail_cancel_order: "Cancel Order",
  orderdetail_cancel_modal_1: "Are you sure you want to cancel the current order? Currently available for free cancellation",
  orderdetail_cancel_modal_2: "Are you sure you want to cancel the current order? The current cancellation will be charged according to the rules",
  settlement_export: "Export",
  settlement_billno: "Bill No",
  settlement_startdate: "Start",
  settlement_enddate: "End",
  settlement_bookings_num: "Bookings Num",
  settlement_total: "Total",
  settlement_writeoff: "Write off status",
  wechat_qrcodetime: "QR Code Validity:",
  wechat_qrcodetips1: "Please use WeChat Scan to complete your payment",
  wechat_qrcodetips2: "Not being redirected to the payment page?",
  wechat_qrcodetips3: "If you are not redirected after completing your WeChat payment, please go to the Booking page to check the status of your order",
  wechat_changepay: "Change payment method",
  b_companyDetails: "Company Details",
  b_BrandName: "Brand Name",
  b_Country: "Country",
  b_City: "City",
  b_address: "Full address",
  b_SettlementPeriod: "Settlement Period",
  b_CompanyNumber: "Company Business Number",
  b_SettlementCompany: "Settlement Company Name",
  b_Personcharge: "Person in charge",
  b_Personemail: "Email of the person in charge",
  b_Personphone: "Phone number of the person in charge",
  b_Remarks: "Remarks",


  home_join: "Join",
  home_explore_local: "Explore Destinations",  //Explore
  home_search: "Search destinations or activities",
  home_bg1: "Having fun wherever you go.",
  home_bg2: "Enjoy the journey and explore your ideal and perfect places.",
  home_banner_title: "New Events",
  home_recommend: "Recommend",
  home_view_more: "Find more",
  home_hot_local: "Popular Destinations",
  home_view_local: "View Destinations",
  home_newproduct_title: "New Products",
  home_user_info_title: "Become A Member",
  home_user_info1: "Join and earn points",
  home_user_info2: "Purchase and earn points",
  home_user_info3: "Write reviews and earn points",

  footer_about: "About Us",
  footer_join_us: "Join Us",
  footer_payment: "Payment Channels",
  footer_sctt_note: "What's SCTT",
  footer_user_reg: "User Registration",
  footer_user_login: "User Log in",
  footer_agent: "Agent Marketplace",
  footer_supplier: "Distribution & Marketing enquiries",
  footer_right_title: 'By clicking "Subscribe", you are agreeing to our Terms of Use and Privacy Policy',
  footer_input_pla: "Please enter your email",
  footer_dingyue: "Subscribe",
  footer_service_hotline: "Customer Service Hotline",
  footer_service_hours: "Service Hours",


  copy_tips: "Copied to clipboard",

  search_try_key: "Try searching for different destinations or choose another category",
  search_try_more: "Explore more destinations",
  search_result_text: "Search results",

  my_points: "Points",   //"Points"
  use_points: "Use Points",
  dikou_points: "Points Discount",
  points_deduction: "Points Discount",
  points_tip: "Description",
  points_tip1_1: "Every 100 points can offset 1 ",
  points_tip1_2: "",  //$  币种拼接，空字符串
  points_tips: "Notice: If you cancel your order after you have successfully placed it with points credit, the points will not be returned to your account.",
  max_points_price: "Available points",
  yes_use: "Use",
  not_use: "Not used",
  submit: "Submit",
  setuser_email: 'Please enter email address',
  setuser_okemail: 'Please enter correct email address',
  response_password: 'The password consists of 8 to 20 digits and characters',
  placeholder_repeat_password: ' Please enter the new password again',
  response_pwd_double: 'The two passwords you entered did not match.',
  coupon_plase_login_lastname: "Please enter Last Name",
  coupon_plase_login_firstname: "Please enter First name",
  orderform_text_choose_login_birthday: 'Please select Date of birth',

  date_of_earn: "Date of earn",   //获得日期：Date of earn 수익일
  date_of_use: "Date of use",    //使用日期：Date of use 사용일자
  valid_until: "Valid until",    //有效期至：Valid until  유효기간

  share_text: "Share",

  orderform_agentpay: "Pay agent price",

  nodata: "No Data",
  hotel_home_cozi: "Hotels",

  shop_trans: 'Bookings (Sell Price)',
  shop_account: 'Members',
  commission: "Commission",
  booking_user: "Booker",


  member_title: 'Member',
  member_show_phone: "Phone",
  member_show_email: "Email",
  member_join_date: "Join time",

  show_agent_price: "to show the Nett rate",


  //预约
	yuyue:"make an appointment", //预约   make an appointment   예약하다
  selected_quantity: "Selected quantity",
  next_btn: "next step",

  sku_not_one_tips_1: 'This cannot be purchased separately, please purchase together with "',
  sku_not_one_tips_2: '".',
  /*
  不能单独购买，请和“独立销售sku名称”一同购买。
  This cannot be purchased separately, please purchase together with "独立销售sku名称".
  별도 구매가 불가하며, "独立销售sku名称"와 함께 구매해주세요.
  */
  sku_not_one_error: "Please select a must buy item",
  /*
  请选择必购商品
  Please select a must buy item
  필수 구매 항목을 선택하십시오.
  */

  yuyue_title: "Reservation information",
  yuyue_btn: "Submitting reservation",
  yuyue_submit_tips: "Reservation being submitted...",
  check_dingyue: 'By ticking this box you agree to subscribe and get deals offer',

  hotel_search_destination: "Destination/property name",
  hotel_search_destination_tips: "Destination or hotel name",
  hotel_search_time: "Check-in/out date",
  hotel_search_checkin: "Check in",
  hotel_search_checkout: "Check out",
  hotel_search_room: "Room",
  hotel_search_adult_room: "Adults/room",
  hotel_search_child_room: "Children/room",
  hotel_search_adult: "adult",
  hotel_search_child: "child",
  hotel_search_age: "age",
  hotel_search_price_per_night: "Price per night",
  hotel_search_star: "Star rating",
  hotel_search_text: "search",
  hotel_search_clear_data: "clear data",
  hotel_search_km: "km",
  hotel_search_room_unit: "rooms",
  hotel_search_australia: "Australia",
  hotel_search_new_zealand: "New Zealand",
  hotel_nightshort: 'Night',
  hotel_roomshort: 'R',
  hotel_specialrequests: 'Special Requests',
  hotel_specialrequests_tips: '(cannot be guaranteed)',
  hotel_remarks: 'Please note if you have special requests',
  hotel_guest_title: 'Title',
  hotel_guest_info: "Guest information",  //顾客信息
  hotel_guest_enter_one: "Enter the lead guest data only",   //输入一个旅客的名字  輸入一個旅客的名字   Enter the lead guest data only
  hotel_guest_enter_all: "Enter the data for all guests",  //输入所有旅客的名字    Enter the data for all guests
  hotel_enter_en_name: "Please type your name in English",   //请输入您名字的拼音    Please type your name in English
  hotel_book_back: "Need to update the number of guests? Please go back to update",   // 需要更新旅客数量吗？请点击返回上一页修改  Need to update the number of guests? Please go back to update

  response_session_select: 'Please select the session',
  response_inventory_Insufficient: 'Low stocks',
  cancel_1: "Cancellation on the day of travel, non-refundable (handling fee 100%)",
  cancel_2_1: "Cancellation ",
  cancel_2_2: " days or more prior to departure will incur (handling fee ",
  cancel_2_3: "%)",
  cancel_3_1: "Cancellation between ",
  cancel_3_2: " days prior to departure will incur (handling fee ",
  cancel_3_3: "%)",
  cancel_4_1: "Cancellation ",
  cancel_4_2: " day or more prior to departure, full refund",

  fee_note_title: "About Transaction Fee",
  fee_note_1: "1. Different payment channels will charge a corresponding transaction fee.",
  fee_note_2: "2. If an order is cancelled for personal reasons by the traveller, the transaction fee will not be refunded when the system refunds the remaining amount according to the cancellation terms of the order.",



  sku_not_one_tips_1: 'This cannot be purchased separately, please purchase together with "',
  sku_not_one_tips_2: '"',
  /*
  不能单独购买，请和“独立销售sku名称”一同购买。
  This cannot be purchased separately, please purchase together with "独立销售sku名称".
  별도 구매가 불가하며, "独立销售sku名称"와 함께 구매해주세요.
  */
  sku_not_one_error: "Please select a must buy item",
  /*
  请选择必购商品
  Please select a must buy item
  필수 구매 항목을 선택하십시오.
  */

  my_passenger: 'Frequent Travellers',
  breakfast: 'Breakfast',
  hotel_tax: "Taxes",  //税   Taxes
  totalprice: 'Total Price',
  booknow: 'Book Now',
  
  /*
    待定
    On Request
    요청 시

    立即询价
    Inquire now
    지금 문의

    订单确认将于48个工作小时内给于回复，以最终房态确认为准。
    Confirmation will be subjected to room availability and reply within 48 working hours.
    확인은 48시간 이내에 객실 가능 여부와 회신을 받게 됩니다.
  */
  onrequest_notprice: "On Request",
  onrequest_booknow: 'Inquire now',
  onrequest_tip: "Confirmation will be subjected to room availability and reply within 48 working hours.",
  
  rooms: 'Rooms',
  nightshort: 'Night',
  hotelroom: 'Room',
  my_title: 'Frequent Travellers List',
  my_add: 'Add Frequent Traveller',
  phonenumberError: 'Please Enter your mobile phone number',
  tip_phone_error: 'Please enter correct mobile phone number',
  ok: 'confirm',
  cancel: "Cancel",
  delete_tip: "Are you Sure you want to cancel?",
  title_prompt: 'Prompt',
  my_title2: 'New Added/Edit Traveler',
  height: 'Height(cm)',
  weight: 'Weight(kg)',
  // unitcm: ' cm',
  // unitkg: ' kg',
  bnm_title: 'Edit Email Address',
  bnp_title: 'Edit Mobile Phone Number',
  phonenumberError: 'Please Enter Mobile Phone Number',
  response_phone_as: 'The Phone Number Can Not Be The Same As The Original One！',
  response_phone_err: 'The Phone Number has been Used ！',
  email_old_no: "The Phone Number Can Not Be The Same As The Original Email Address",
  email_no: "This Email Address Is In Used",
  bind_newemail_ok: 'Binding succeeded!',
  old_pwd_err: 'Wrong Password',
  old_new_equally: "The new password can not be the same as the preious one",
  system_error: "Unknown Error!",
  response_done: ' Your password changed successfully,please Log in again',
  response_pwd_double: 'The two passwords you entered did not match.',
  shop_title: 'Shop management',
  hotel_like: 'Preferred Hotel',
  hotel_err_title: 'Sorry, there are no rooms available on this date. Please try another date.',


  account_change_nickname: "Change Nickname",  //修改昵称     Change Nickname     닉네임 수정
  account_nickname: "Nickname",  //昵稱     Nickname     닉네임
  account_nickname_tips: "Please enter a nickname",   //请输入昵称     Please enter a nickname     닉네임을 입력하십시오.
  mostpopular: "Most Popular",   //热门活动     Most Popular      가장 인기 있는
  account_traveler: "Frequent Traveler Info",    //常旅客管理     Frequent Traveler Info      빈번한 여행자 정보
  account_traveler_tips: "Save personal details and book faster",    //保存个人信息，预定更快速       
  account_traveler_add: "Add Traveler",    //常旅客管理     Add Traveler      여행자 추가


  travel_in_person: "In person",     //本人    In person    직접
  travel_use_default: "Use as Default",     //设为默认出行人    Use as Default   기본값으로 사용
  points_get: "Get Points",   //获取积分    Get Points    포인트 받기
  pages_home: "Home",   //首页    Home      홈
  pages_pre: "Previous",   //上一页      Previous     이전의
  pages_next: "Next",   //下一页      Next     다음 분
  pages_last: "Last",   //尾页    Last     지난

  account_edit: "Edit",    //编辑    Edit    수정하다
  account_delete: "Delete",    //编辑    Delete    삭제
  account_profile: "Profile",    //基本信息    Profile    기본정보
  account_sex_m: "M",    //男    M    
  account_sex_f: "F",    //女    F

  pla_input_cnname: "请输入中文姓名",   //请输入中文姓名    请输入中文姓名      请输入中文姓名
  account_gender: "Gender",    //性别      Gender     성별
  account_add_success: "Add success",    //新增成功      Add success     성공 추가
  account_change_success: "Change success",    //修改成功      Change success     변경 성공
  account_delete_success: "Delete success",    //删除成功      Delete success     삭제 성공
  account_cancelled: "Cancelled",     //已取消删除      Cancelled     취소된
  account_name_double: "Duplicate names",    //姓名重复     Duplicate names     중복된 이름
  account_contact: "Contact",    //联系方式    Contact     연락

  public_countrycode: "Country/Region Code",     //国家/地区代码      Country/Region Code    국가/지역 코드
  public_please_select_countrycode: "Please select Country/Region Code",    //请选择国码     Please select Country/Region Code     국가/지역 코드를 선택하십시오.

  public_shop: "Shop",   //店铺    Shop    샵

  manage_login: "Manage login",   //管理登录方式    Manage login    로그인 관리
  mobile_linked_success: "Mobile number linked successfully",   //手机号绑定成功    Mobile number linked successfully    휴대폰 번호가 성공적으로 연결되었습니다.

  pwd_tips_1: "Password must contain at least 8 characters",   //密码必须至少包含8个字符    Password must contain at least 8 characters    암호는 8자 이상이어야 합니다.
  pwd_tips_2: "Password must contain at least 1 number",   //密码必须至少包含1个数字    Password must contain at least 1 number    암호에 하나 이상의 숫자가 포함되어야 합니다.
  pwd_tips_3: "Password must contain at least 1 letter",   //密码必须至少包含1个字母    Password must contain at least 1 letter    암호에 최소 1자 이상의 문자가 포함되어야 합니다.
  pwd_tips_4: "Password must contain at least 1 special character",   //密码必须至少包含1个特殊字符    Password must contain at least 1 special character    비밀번호는 최소 1개의 특수 문자를 포함해야 합니다.


  click_more: "Read more",    //点击查看更多      Read more     더 알아보기
  hotel_change_search: "Update",   //更新条件     Update     검색 변경
  hotel_room_type: "Room type",    //客房类型     Room type    객실 유형
  hotel_price_room_n: "Price/Room/N",    //均价/每间/每晚     Price/Room/N     가격/방/야간
  hotel_facilities: "Hotel facilities",    //内部设施     Hotel facilities      호텔 시설
  hotel_map_other_info: "Map and other information",    //地图和其他信息     Map and other information     지도 및 기타 정보
  account_set: "Account Settings",     //账户设置      Account and Password     계정 및 암호

  most_num: 'Maximum capacity',
  people: 'Number of people',
  p_reset: "Reset",
  englist_name: 'Name',
  operation: '',
  merchant_shop: "Merchant",
  amount_change: 'Amount Change',
  payment_number: 'Bill Number',
  payment_days: 'Billing Period',
  order_number: 'Number of Orders',
  money: 'Amount',
  download: 'Download',
  commission_end_number: "Commission Settlement Number",
  payment_way: 'Settlement Method',
  payment_type: 'Settlement Status',
  import: 'Important',
  commercial_name: 'Name',
  shop_name: 'Merchant Name',
  commercial_currency: 'Currency',
  shop_currency: 'Merchant Currency',
  commercial_url: 'URL',
  shop_url: 'Merchant Website',
  default_language: "Default Language",
  default_city: "Default City",
  commercial_type: 'Status',
  shop_type: 'Merchant Status',
  commission_b_SettlementPeriod: "Commission Settlement Period",
  commission_payment_way: 'Payment Method',
  shop_finance_name: 'Financial name of the merchant',
  service_kefu: 'Customer Service',
  pack_up: '收起',
  // start
  new_reviews: "Latest Reviews",
  active_reviews: "Event Reviews",
  low_reviews: "<3.0",
  num_reviews: 'Reviews',
  all_reviews: 'View all reviews',
  use_valid_list: "Use Coupon",
  coin_certificate: 'Voucher redemption',
  use_ok_discount: 'Available Coupons',
  use_no_discount: 'No coupons available',
  use_rule: 'Rules of Use',
  please_coin_certificate: 'Please enter voucher number',
  certificate: 'Redemption',
  please_ok_certificate: 'Please enter the correct redemption code',
  error_certificate: 'Current redemption code is no longer valid',
  succeed_certificate: 'Redeemed successfully',
  discount_deduction: 'Coupon Credit',
  back: 'Back',
  full: 'discount for',
  ok_user: '',
  no_restrict: 'no threshold',
  more_reduce: 'Up to',
  Sold_out: "Sold",
	date_day_left_1: "",   //余2      英文为空     남은 2
	date_day_left_2: " left",   //中文为空      2 left        韩文为空
  Try_other_dates: "Try another stay",
  ok_minus: 'discount',
  // start


  in_use: 'Enabled',
  stop_use: 'Disabled',
  no_open: 'Not available',
  myorder_settings: "Dashboard",
  get_message: "Loading price",
  extra_money: '(additional)',
  use_points_loser: 'Failed to use points',
  use_coupon_loser: 'Failed to use coupon',
  // end

  use_coupon_order_tips: "Notice: 1.Only one coupon can be used per order. No additional refund will be given for the unused amount of the voucher at the time of payment.	",
  use_coupon_order_tips2: "2.If you cancel your order after you have successfully placed it with coupon, the coupon will not be returned to your account.",
  use_coupon_paynow_tips: "No additional payment is required for this order. Please check if you are using the discount in placing your order?",

  use_points_order_tips: "Notice: If you cancel your order after you have successfully placed it with points credit, the points will not be returned to your account.",
  flash_sale: "Flash Sale", //秒杀     Flash Sale     플래시 세일
  coming_soon: "Begins in",  //即将开售     Begins in     시작
  coming_to_end: "Ends in",  //即將結束     Ends in     종료
  on_sale: "On Sale",  //热销中     On Sale    판매 중
  off_sale: "Sold out",   //活動已結束    Sold out    팔렸어요

  shiling_sale: "In season",   //时令产品    In season    제철

  to_receive: 'Request',
  to_receive_success: 'Received successfully',
  invalid_state: 'Current status no longer valid, please log in again',
  refund_message: 'Refund and Bill Adjustment',
  refund: 'Refund',
  refund_1: 'Supplementary Payment',
  refund_1_money: 'Supplementary Payment Amount',
  order_status_err: 'Current Order Status Error',
  refunding: 'Refund in progress',
  refunded: 'Refunded',
  supplementing: 'Paying now',
  supplemented: 'Paid',
  footer_dingyue_success: "Subscription successful",
  footer_dingyue_double: "You have subscribed to this product",   //您已经订阅过该产品     You have subscribed to this product    이 제품을 구독했습니다.
  footer_dingyue_error: "Subscription failed",
  perfect_message: 'Complete your profile',
  perfect_all_message: 'You do not have an email address for your current account, please go to the complete profile page to set up your email address.',

  /*
    提示：您使用积分抵扣下单成功后，如果取消订单，积分不会退回账户。
    Notice: If you cancel your order after you have successfully placed it with points credit, the points will not be returned to your account.
    주의: 포인트 크레딧으로 성공적으로 주문을 완료한 후 주문을 취소하면 포인트는 계좌로 반환되지 않습니다.
    */

  yushow_title: "Special Pre-Sale",
  yushow_tip1: "Stock up",
  yushow_tip2: "Refundable",
  yushow_tip3: "Online Reserve",
  yushow_tip4: "Self-Reserve",
  /*
  特惠预售 
  先囤后约
  不约可退
  在线预约
  自行预约
  */

  yushow_popup_tip1: "Place your order first to lock in the offer, confirm your travel date and then reserve a specific travel date.",
  yushow_popup_tip2: "If you don't make a reservation, you can initiate a 100% refund at any time in the order details.",
  yushow_popup_tip3: 'Click on the "Reserve button" in the order details to reserve your travel date online, and your order will be confirmed instantly and efficiently.',
  yushow_popup_tip4: "please refer to the detailed booking operation in the order details.",
  pre_sale_button: "Complete",   //complete

  pre_sale_note1: "Available dates:",
  pre_sale_note2: "Additional Notes:",
  pre_submit_tips:"Not available at this time, please contact customer service.",  //暂无库存，请联系客服   Not available at this time, please contact customer service.  지금은 사용할 수 없습니다. 고객 서비스에 문의하십시오.
  
  cash_out: "Cash out",   //提现    Cash out    현금 인출
  top_up: "Top up",   //充值    Top up    보충하다
  not_paid: "Not Paid",  //未结算    Not Paid   미지급
  paid: "Paid",  //已结算   Paid    유급의
  guest_book_tips:'You are not logged in yet. Do you want to login and order?',
	guest_login_btn: 'Account login',
	guest_next_btn: 'Order as a guest',
	guest_login_msg:'You are not currently logged in, please log in or sign up first',
	guest_login_btn2:'Log in/Sign up',
  guest_tips:"You have selected to order as a guest, please be sure to fill in accurate information.",
	guest_agree_tips1:"When order as a guest, your personal information will be used to confirm the order, and your email will be used to receive order confirmation . After you place an order successfully, you can use your personal information to retrive the order.",
	guest_agree_tips21:"If you wish to log in with an existing account, ",
	guest_agree_tips22:"please click",
  guest_tip:"You can check your booking according to the following information",
	guest_order_tip:"Your booking reference",
	guest_email_tip:"Your booking email address",
  guest_ph_orderno: 'Please enter order number',
	guest_ph_email: 'Please enter email address',
	guest_search: 'Search Order',
	guest_login: 'Account login',
  no_login_active:'You can earn up to',
  no_login_credits:'points after logging in and completing the activity, subject to the actual points earned from the order.',
  login_credits:'Complete this activity to earn',
  myorder_order_status_6_3:"Reservation expired",
	myorder_order_status_6_4:"Reservation to be confirmed",  
	myorder_order_status_6_5:"Reservation Cancelled", 
  wait_pay:'Awaiting payment',
  my_head_order:'View Orders',
  please_order:'Please enter your order number',
  please_true_order:'Please enter the correct order number',
  about_sctt:'About SCTT',
  market_lol:'Affiliate Platform',
  website_clause:'Terms & Conditions',
  privacy_clause:'Privacy Policy',
  cookie_policy:'Cookie Policy',
  sonsult_kf:'Contact us',
  online_sonsult:'Online customer service',
  serve_phone:'Service Line',
  serve_time:'Service Hours',
  h3_txt_hint:"Join us and unlock exclusive deals",
  dingyue_policy:'By clicking Subscribe, you have agreed to our Terms & Conditions and Privacy Policy',
  only_english:'Only English characters can be entered',
  presell_remain:'Remaining pre-sale quota',
  presell_remain1:'Your current purchase will comsume your pre-sale quota. Do you want to continue?',
  presell_remain2:'Your purchase go over the limit of your pre-sale quota.  If you continue, the pre-sale price will not be used and the purchase will not consume your pre-sale quota. Do you want to continue?',
  presell_remain3:'Pre-sale item and non pre-sale items cannot be purchased together.  If you continue, the pre-sale price will not be used and the purchase will not consume your pre-sale quota. Do you want to continue?',
  send_card:'send',
  until: " Until",
  class_sup_payment: "sup payment",    //需补款   sup payment   지불을 보충
  valid_booking_s:'Valid for',
  valid_booking_months:'months',
  valid_booking_e:'after the order is placed successfully',
  pre_sale_date: "Bookable date:",
  loose_pulley_text1:'Welcome to the Cruise World',
  loose_pulley_text2:'It is recommended that you use a web browser to open',
  loose_pulley_text3:'You are very welcome to contact us for assistance at any time',
  loose_pulley_text4:'When you enter, it is recommended to select in the title:',
  loose_pulley_text5:'FIND A CRUISE (or directly select CRUISE LINES to find a suitable cruise such as Disney Cruise Line)',
  loose_pulley_text6:'First, select Departure Port: For example, you can enter Sydney/Melbourne, etc.',
  loose_pulley_text7:'Filter Cruise Line :',
  loose_pulley_text8:'Choose popular ones such as: Carnival Cruise Lines Carnival / Disney Cruise Line Disney',
  loose_pulley_text9:'Click to search',
  loose_pulley_text10:'Enter the sailing schedule page for the specific departure time period',
  loose_pulley_text11:'Choose the one that suits you and click CONTINUE',
  loose_pulley_text12:'Enter the specific schedule introduction page, click CONTINUE',
  loose_pulley_text13:'Please enter all the number of travelers/ages, click CONTINUE',
  loose_pulley_text14:'Select the cabin class, etc., click CONTINUE',
  loose_pulley_text15:'View and select the available cabins, click CONTINUE',
  loose_pulley_text16:'Check ITINERARY introduction and price, etc., please drop down to fill in',
  loose_pulley_text17:"1. Traveler's personal information, including birthday, nationality, etc.",
  loose_pulley_text18:'2. LEAD PASSENGER CONTACT',
  loose_pulley_text19:'3. ADDITIONAL, Here you can choose HOLD BOOKING / SEND A QUOTE as an archive and can decide later',
  loose_pulley_text20:'Click CONTINUE TO PAYMENT to continue until you enter the payment page, you can choose to pay a deposit or pay in full',
  loose_pulley_text21:'Click agree to the terms and MAKE PAYMENT to complete',
  loose_pulley_text22:'You can contact us for assistance at any time. If you need assistance, SEND A QUOTE is archived and we can quickly assist you.',
  loose_pulley_text23:'Regards',
  email_dialog_txt:'Your email address may be incorrect, please change it and confirm.',
  no_room_num:'Insufficient hotel rooms',
  //start
	nation_title:"Select nationality",
	residency_title:"Country of residence",
	setuser_nation:"Nationality",
	setuser_residency:"Country of residence",
  footer_clause1:'使用者條款',
	footer_clause2:'隱私權保護政策',
	footer_clause3:'觀光產品由',
	footer_clause4:'提供',
	footer_clause5:'代錶人',
	footer_clause6:'統一編號',
	footer_clause7:'旅行社註冊編號',
	footer_clause8:'品保協會會員編號',
	footer_clause9:'聯繫地址',
	footer_clause10:'聯繫電話',
	footer_clause11:'傳真',
	footer_clause12:'信箱',
  need_breakfast:'Add Breakfast',
  no_need_breakfast:'No breakfast',
  //end

  
  member_title: "Users",   //成员管理    Member management    회원관리
  member_new: "New User",   //新增成員    New members    신규회원
  member_edit: "Edit members",   //编辑成員    Edit members     구성원 편집
  member: "Users",   //成员   Member   회원
  member_role: "role",    //角色   role     역할
  member_status: "status",    //状态    status  상황
  member_status_open: "ON",     //正常    ON    켜짐
  member_status_stop: "OFF",     //停用    OFF    꺼짐
  member_creation_time: "Date Created",   //创建时间   Creation time  작성시간
  
  member_send_email: "Send account activation email to members",    //向成员发送账号激活邮件      Send account activation email to members    회원에게 계정 활성화 전자 메일 보내기
  member_auth_error: "Activation failed",   //激活失败   Activation failed  활성화 실패
  member_auth_ok:"Activation is successful and you will be redirected to the login page",   //激活成功，将为您跳转到登录页   Activation is successful and you will be redirected to the login page   활성화에 성공하면 로그인 페이지로 리디렉션됩니다
  
  member_auth_btn: "Activation",     //激活    Activation    활성화
  
  member_auth_tips: "Please complete your password to activate your account.",
  member_auth_load_error: "Error, will redirect you to the home page",

  ask_price: "Quotation",
  child_age:"Child age",
  account_add:'Add',
  guest_name:'Guest name',
  city_name:'City/Day of trip',
  people_time:'Operator/Date',
  follow_uping:'In progress',
  place_order:'Ordered',
  ask_price_detail: "Quotation Details",
  child_num:'Number of children',
  guest_demand:"Guest's notes",
  data_num:'Number of days of the trip',
  hotel_start:'Hotel star rating',
  begain_city:'Departure City',
  over_city:'Ending City',
  ask_price_record:'Quotation product record',
  select_product:'Select product',
  product_resource:'Product resource',
  num_data:'Quantity/departure date',
  product_card_name:'Product number/name',
  discription_pla_city:'Destination city',
  select:'select',
  revice_price:'adjust_price',
  select_go_data:'Select travel dates',
  advance:'Book',
  generate_order:'Generate quotation',
  payment_type:'Settlement day type',
  data_type_1:'Order Date',
  data_type_2:'Travel Date',
  pay_type_1:'Prepayment',
  pay_type_2:'Single Settlement',
  pay_type_3:'Weekly',
  pay_type_4:'Semi-monthly',
  pay_type_5:'Monthly',
  day:'day',
  no_cancel:'No write-offs',
  yes_cancel:'Written off',
  refund_message2:'Payment',
  sup_handsel:'Deposit',
  sup_final:'Balance',
  no_begain_pay:'Payment not enabled',
  sup_handsel_text:'A small deposit is sufficient to reserve a place, full payment is deferred',
  pay_pay_within3:'Payment should be done before',
  login_break:'Current account has been updated, please login again',
  begain_pay_monkey:'pay the balance from',
  cancel_service_charge:'Please note : Order transaction fees are not refundable',
  confirm_cancel_order1:'Are you sure you want to cancel your current order? Current cancellations will be subject to order loss and transaction fees as per the rules.',
  confirm_cancel_order2:'Are you sure you want to cancel your current order? Current cancellations will be subject to a transaction fee as per the rules.',
  pre_submit_tips2:'please contact customer service.',
  fp:' Forgot password',
  response_unknown_error:'Verification Error',
  verification_code_error:'Security Code Error！',
  fill:'Setup New Password',
  set_newpassword_failed: 'Password re-set failed',
  re_login: " Password has been reset, please log in again ",
  new_pwd:'Please enter new password',
  email_no_account:'This email address is not tied to an account',
  phone_get_psd:'modify password via message',
  email_get_psd:'modify password via e-mail',
  em:'Email Address',
  response_phone_no:"Mobile number not available",
  email_login:'login/register via email',
  login_titlemsg_email: "Signing in with an unregistered email address will create a new account",
  reg_error: "System error, Please refresh and try again.",
  verification_code_error:'Security Code Error！',
  remt2:' 《SCTT Service Agreement》',
  login_yzm:'Login with OTP',
  no_account:"Don't have an account yet",
  sign_up:'Create account',
  login_change_1:'Login/register',
  login_change_2:'I have read and agree',
  login_change_3:'Terms and Conditions',
  login_change_4:'Via email',
  login_change_5:'I have the password for my account',
  login_change_6:'Login now',
  login_change_7:'Via mobile',
  please_wait:'Please wait',
  please_select_pay:'Insufficient credit, please select another payment method',
  qm_need_login:'Login/registration for customer service contact',
  unsubscribe1: 'Unsubscribe successfully.',
	unsubscribe2: 'To re-subscribe,',
	unsubscribe31: 'please go to the',
	unsubscribe32: 'My page',
	unsubscribe33: 'of the shop.',
  dingyue_email: 'Subscribe Emails',
  dingyue_rule1:'Updates and promotions',
  dingyue_rule2:'Be first to know about our latest campaigns, discounts and new features',
  my_order_token:'My Order',
  go_page:'Go To',
  order_shop_trans:'(Filter Source)',
  /*
  请完善密码，以激活您的账号。
  Please complete your password to activate your account.
  계정을 활성화하려면 암호를 입력하십시오.

  信息异常，将为您跳转到首页
  Error, will redirect you to the home page
  오류, 홈 페이지로 리디렉션됩니다
  */

  hotel_book_step_room:'Room',
  hotel_book_step_customer:'Customer',
  hotel_book_step_payment:'Payment',
  orderfrom_customer_details:'Customer Details',
  orderfrom_lead_traveller_only:'Information of one guest', 
  orderfrom_all_travellers:'All Travellers', 
  orderfrom_first_name:'First name', 
  orderfrom_last_name:'Last Name',
  m_logintime:'Last Login',
  
  pla_ipt_name:'Please Enter your name',
  error_order:'Error - Create order',
  pla_kefu_dispose:'Please contact Customer Service',

  quota_pay:'Credit Top-up',
  quota_pay2:'Top up online',
  quota_away_pay:'Pay Now',
  quota_get_total:'Arrival',
  quota_line_remit:'Pay by offline transfer',
  quota_pay_record:'Submit Payment Record',
  quota_pay_success:'Transfer is complete, please submit the Remittance here and charge it to the your account after financial approval',
  quota_company_name:'Company name',
  quota_bank_accound:'Account number',
  quota_type:'Accounting Status', 
  quota_pay_date:'Payment date',
  quota_upload_proof:'Uploading Remittance',
  quota_upload:'Uploading',
  quota_only_type:'Only upload an image or pdf file',
  quota_result:'Example',
  quota_please_money:'Please enter the amount',
  quota_min_money:'Amount must not be less than 0',
  quota_please_type:'Please upload the file',
  quota_please_date:'Please select a time',
  
  select_sku:'Selected Package',
  selectdate_other_skutitle:'Other available packages',
  selectdate_no_skutitle:'No other packages available for specified date',
  other_goodsitem_pay_btn: "Book this alternative package",

  
  //酒店特殊要求
  double_bed:"Double bed",  //Double bed
  two_beds:"Two beds",   //Two beds
  early_check_in:"Early Check in",    //Early Check in
  late_check_in:"Late Check in",    //Late Check in
  late_check_out:"Late Check out (may occur extra cost)",  //Late Check out (may occur extra cost)
  adjacent_rooms:"Adjacent rooms",   //Adjacent rooms
  honeymoon:"Honeymoon",   //Honeymoon
  InstantConfirmation: "Instant Confirmation",   //立即确认    Instant Confirmation
  pay_check_price: 'Sorry, This room is sold out.',    //抱歉，您订购的房间已经完售!     Sorry, This room is sold out.    죄송합니다, 이 방은 매진입니다.
};
