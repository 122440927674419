/* eslint-disable prettier/prettier */
//export default
module.exports = {
  system_err: "系統錯誤",
  loading_api: "正在請求數據...",
  location: "悉尼(Sydney)",
  menu_home: "首頁",
  menu_ExperienceTicket: "景點門票",
  menu_daytour: "一日遊",
  menu_hotel: "酒店",
  menu_mdaytour: "多日遊",
  menu_fooddining: "美食",
  menu_activity: "體驗活動",
  menu_giftcard: "禮品卡",
  menu_user_myorder: "同行訂單",
  menu_user_shoucang: "我的收藏",
  menu_user_account: "個人中心",
  menu_user_logout: "登出",
  tourtype: "分類",
  reset: "清空數據",
  search_pla: "請輸入關鍵詞",
  search_product_pla: "搜索產品和代碼",
  search_discription_pla: "搜索並選擇目的地",
  search_text: "搜索",
  label: "標簽",
  page_home: "首頁",
  language_title: "選擇語言",
  findoutmore: "了解更多",
  joinus: "進入合作",
  home_selected: "已選擇",
  home_clear: "清除選擇",
  home_discription: "目的地",
  home_product: "搜索玩樂產品",
  home_data: "創建日期",
  home_sort: "排序:",
  home_liked: "喜歡的",
  home_general: "綜合",
  home_price: "價格",
  home_price_hign_low: "價格由高到低",
  home_price_low_hign: "價格由低到高",
  home_world: "不限",
  home_xinpin: "新品推薦",
  home_hotelbook: "酒店預訂",
  home_hotelbook_tips: "點擊進入酒店預訂平臺",
  login_account_pla: "手機/郵箱",
  login_password_pla: "密碼",
  login_phone_pla: "手機號碼",
  login_send_code: "發送驗證碼",
  login_enter_code_pla: "請輸入驗證碼",
  login_resend_code: "重新發送",
  login_resend_code_in: "內重新發送 ",
  login_resend_code_in_s: "秒",
  login_phonenumber_error_required: "請輸入手機號碼",
  login_phonenumber_error_msg: "請輸入正確的手機號碼",
  login_valid_code_error_msg: "驗證碼獲取次數已超限製(每個號碼 3次/天)",
  login_title: "登錄",
  login_title2: "賬戶登陸/註冊",
  login_titlemsg: "使用未註冊的電話號碼登錄會創建一個新的帳戶",
  login_titlemsg1: "請使用您的手機或郵箱賬號登錄",
  login_account_error_msg: "請輸入賬號",
  login_password_error_msg: "請輸入密碼",
  login_username_password_error: "賬號或密碼錯誤",
  login_remember_me: "記住我",
  login_phone_login: "驗證碼登錄/註冊",
  login_account_login: "使用賬號密碼登錄",
  login_lastname: '英文姓',
  login_firstname: '英文名',
  login_birthday: '出生日期',
  coupon_plase_login_lastname: "請輸入英文姓",
  coupon_plase_login_firstname: "請輸入英文名",
  orderform_text_choose_login_birthday: '請選擇出生日期',
  setuser_okemail: '請輸入正確的信箱',
  cancel_noone: "不可取消",
  cancel_pe: "人工取消退款",
  cancel_5: "不可退改",
  cancel_6: "預約前免費取消，預約後不可取消",
  pdetail_pid: "產品ID",
  pdetail_time: "可售日期",
  pdetail_jike: "立即確認並獲得憑證，若售罄將取消",
  pdetail_t1: "取消政策",
  pdetail_soldss: "已售",
  pdetail_tab1: "產品介紹",
  pdetail_tab2: "預定須知",
  pdetail_tab3: "溫馨提示",
  pdetail_tab4: "評價",
  pdetail_location_title: "目的地",
  pdetail_resort_title: "集合地",
  pdetail_exchange_title: "兌換點",
  pdetail_event_starttime: "活動營業時間",
  pdetail_projectcontain: "項目包含",
  pdetail_projectexclude: "項目不含",
  pdetail_da: "目的地地址",
  pdetail_mt1: "年齡描述",
  pdetail_mt2: "使用須知",
  pdetail_mt3: "註意事項",
  pdetail_mt4: "免責聲明",
  pdetail_mt5: "緊急聯系方式",
  pdetail_bn: "立即預訂",
  pdetail_Wishlist: "我的收藏",
  pdetail_pricefrom: "起",
  pdetail_Packageoptions: "套餐選項",
  pdetail_selectoptions: "選擇套餐選項",
  pdetail_selectedpackagedetails: "已選套餐詳情",
  pdetail_valid: "有效期",
  pdetail_valid_until: "有效期至",
  pdetail_valid_booking_s: "下單成功後",
  pdetail_valid_booking_e: "有效",
  pdetail_valid_booking_months: "個月內",
  pdetail_valid_booking_years: "年內",
  pdetail_likeit: "你可能也喜歡...",
  selectdate_title: "選擇日期/人數",
  selectdate_month1: "一月",
  selectdate_month2: "二月",
  selectdate_month3: "三月",
  selectdate_month4: "四月",
  selectdate_month5: "五月",
  selectdate_month6: "六月",
  selectdate_month7: "七月",
  selectdate_month8: "八月",
  selectdate_month9: "九月",
  selectdate_month10: "十月",
  selectdate_month11: "十一月",
  selectdate_month12: "十二月",
  selectdate_week1: "一",
  selectdate_week2: "二",
  selectdate_week3: "三",
  selectdate_week4: "四",
  selectdate_week5: "五",
  selectdate_week6: "六",
  selectdate_week7: "日",
  selectdate_addpro: "加購", //더 구입하기     Buy more    加購
  selectdate_title_session: "場次",
  selectdate_text_travel_times: "出行場次",
  selectdate_text_left: "剩余：",
  selectdate_text_well: " 庫存充足",
  selectdate_text_no_session: "所選日期無可用場次，不可預訂",
  selectdate_text_loading_session: "加載場次...",
  selectdate_text_loading_price: "加載價格...",
  selectdate_response_session_select: "請選擇場次",
  selectdate_response_quantity_min: "預訂份數不能小於",
  selectdate_response_quantity_zero: "預訂份數不能為0",
  selectdate_response_inventory_Insufficient: "庫存不足",
  selectdate_skutitle: "選擇數量",
  selectdate_booking_details: "訂單詳情",
  selectdate_package_type: "套餐類型",
  selectdate_date_time: "日期 & 時間",
  selectdate_price_details: "費用詳情",
  selectdate_please_choose: "請完成所有必選項",
  selectdate_see_all: "顯示全部",
  selectdate_tips1: "*價格可能根據出行日期、優惠條件有所浮動，具體請以結算頁面的標價為準。",
  selectdate_tips2: "*由於您使用的是澳洲平臺，使用微信、支付寶、銀聯卡、信用卡支付訂單時會收取銀行交易手續費。",
  orderform_choose_booking: "預定選項",
  orderform_pay: "支付",
  orderform_enter_info: "填寫信息",
  orderform_enter_info_tip: "*請仔細填寫，訂單提交後可能無法修改",
  orderform_boolean_true: "是",
  orderform_boolean_false: "否",
  orderform_valid_date: "有效期",
  orderform_valid_until: "有效期至",
  orderform_travelinfo: "出行信息",
  orderform_chuxingren: "出行人",
  orderform_contact_info: "聯系信息",
  orderform_other_info: "其他信息",
  orderform_contact_tip: "如你的預訂有任何變動，我們將及時通知你",
  orderform_title_pickuplocation: "接送點",
  orderform_title_pickuplocation2:'上車點',
  orderform_title_dropofflocation: "下車點",
  orderform_location_title_no: "無",
  orderform_agentnumber: "同行訂單號",
  orderform_remarks: "備註",
  orderform_booking_tip: "點擊按鈕後，你的訂單將會自動提交。請在下一頁選擇付款方式",
  orderform_continue: "前往付款",
  orderform_date: "日期",
  orderform_time: "時間",
  orderform_quantity: "數量",
  orderform_subtotal: "合計",
  orderform_total: "總計",
  orderform_subtotal2: "價格",
  orderform_payment_amount: "支付金額",
  orderform_text_please_enter: "請輸入",
  orderform_text_correct: "正確的",
  orderform_text_choose: "請選擇",
  orderform_required: "必填",
  orderform_coupon_shop: "店鋪立減",
  coupon_title: "優惠券",
  coupon_checking: "驗證",
  coupon_checkok: "已驗證通過",
  coupon_plase_print: "請輸入優惠券號碼",
  coupon_submit: "提交使用",
  coupon_valid_list: "當前使用優惠券",
  coupon_cancel: "取消",
  coupon_shengyu_num: "當前還可使用數量",
  coupon_btn_confirm: "確認",
  coupon_btn_cancel_logout: "點錯了",
  coupon_tip_cancel: "您確定要取消嗎？取消後優惠券不會失效，本單將不再享用優惠。",
  coupon_Voucher_Invalid_1001: "找不到該優惠券。",
  coupon_Voucher_Invalid_1007: "該優惠券已使用。",
  coupon_Voucher_Invalid_1008: "該優惠券已過期。",
  coupon_Voucher_Invalid_1013: "您今天已使用過優惠券，請明日再使用。",
  coupon_notes_title: "報錯提示說明：",
  coupon_notes_1: "1. 找不到該優惠券：請核實輸入的優惠券代碼是否正確；請確認您使用的是Discover Voucher。",
  coupon_notes_2: "2. 該優惠券已使用：請核實您是否已使用過該優惠券。",
  coupon_notes_3: "3. 該優惠券已過期：該優惠券已過期，無法再使用。",
  coupon_notes_4: "4. 您今天已使用過優惠券，請明日再使用：一天只能使用一張優惠券，請在次日再使用該優惠券。",
  coupon_order_amount: "訂單金額",
  coupon_p_notes_1: "1. 找不到該優惠券：請核實輸入的優惠券代碼是否正確；請確認您使用的是Parents Voucher。",
  coupon_p_notes_2: "2. 該優惠券已使用：請核實您是否已使用過該優惠券。",
  coupon_p_notes_3: "3. 該優惠券已過期：該優惠券已過期，無法再使用。",
  myorder_shop_coupon: "店鋪立減",
  myorder_myorder: "訂單",
  myorder_manage_personal_info: "個人信息管理",
  myorder_mybookings: "訂單",
  myorder_wait_for_confirm: "待確認",
  myorder_cancelled: "已取消",
  myorder_bookingdate: "預訂日期",
  myorder_reviews: "我的評價",
  myorder_credit_limit: "額度",
  myorder_manage_booking_info: "出行人管理",
  myorder_settings: "商戶信息",
  myorder_start_date: "出行日期",
  myorder_start_or_booking_date: "預訂日期/出行日期",
  myorder_please_choose_date: "請選擇日期類型",
  myorder_valid_until: "有效期至",
  myorder_label_duration_of_validity: "有效期間",
  myorder_sum_payable: "應付金額",
  myorder_total_paid: "實付金額",
  myorder_details: "查看詳細",
  myorder_gotopay: "去支付",
  myorder_paystatus: "支付狀態",
  myorder_bookingstatus: "預訂狀態",
  myorder_all: "全部",
  myorder_unpaid: "未支付",
  myorder_paid: "已支付",
  myorder_order_status_0: "待付款",
  myorder_order_status_1: "待確認",
  myorder_order_status_2: "已確認",
  myorder_order_status_3: "已完成",
  myorder_order_status_4: "待取消",
  myorder_order_status_5: "已取消",
  myorder_order_status_6: "待預約",   //待预约    To be reserved       유보하다
  myorder_order_status_7: "已預約",    //已预约     Reserved      예약됨
  myorder_order_status_8: "未出行",
  myorder_order_status_9: "待預約",
  myorder_search_order: "訂單號,產品名稱,產品ID",
  myorder_search_agentcode: "同行訂單號",
  myorder_search_confirmation_no: "供應商確認號",
  myorder_search_bookingcontact: "預訂聯系人，手機號，郵箱",
  myorder_reset: "清空",
  my_jiesuan: "結算",
  my_accountinfo: "個人信息",
  setuser_top: '您若要繼續，請完善以下信息。',
  my_name: "姓名",
  my_phone: "電話",
  my_email: "郵箱",
  my_pwdop: "密碼管理",
  my_pwdop_tips: "定期修改密碼以提高賬戶安全",
  my_oldpwd: "當前密碼",
  my_oldpwd_tips: "更改密碼",
  my_newpwd_tips: "新密碼（8 - 20個字符，須至少包含1個數字、1個字母及1個特殊符號）",
  my_newpwd_pla1: "請輸入密碼",
  my_newpwd_pla2: "確認密碼",
  my_save: "保存",
  pay_complete_payment: "確認付款",
  pay_pay_within1: "請於",
  pay_pay_within2: "內完成付款",
  pay_credit_pay: "額度支付",
  pay_credit_amount: "可用額度",
  pay_alipay: "支付寶",
  pay_wechat: "微信支付",
  pay_union: "銀聯支付",
  pay_hc: "手續費",
  pay_booking_tip: "點擊「前往支付」，即表示您已核對訂單及總金額，且同意",
  pay_booking_tip_a: "使用條款",
  payreturn_ok: "支付完成!",
  payreturn_email_tip: "預訂信息和使用憑證將在1-2個工作日內發送至您的電子郵件",
  payreturn_orderno: "訂單號",
  payreturn_vieworder: "查看訂單",
  location_hot_title: "熱門目的地",
  location_country_city_title: "請選擇國家 / 城市",
  location_state_city_title: "請選擇目的地",
  location_bottom_tips: "向下滑動查看更多目的地",
  orderdetail_payment_type_1: "信用卡",
  orderdetail_payment_type_2: "支付寶",
  orderdetail_payment_type_3: "微信",
  orderdetail_payment_type_4: "銀聯",
  orderdetail_payment_type_5: "線下支付",
  orderdetail_payment_type_6: "信用額度",
  orderdetail_payment_type_7: "充值額度",
  orderdetail_orderno: "訂單號",
  orderdetail_agentcode: "同行訂單號",
  orderdetail_viewvoucher: "查看憑證",
  orderdetail_pay: "實付金額",
  orderdetail_paydetails: "支付明細",
  orderdetail_orderdetails: "訂購詳情",
  orderdetail_contactinfo: "預定人信息",
  orderdetail_travelinfo: "出行人信息",
  orderdetail_cancelinfo: "退訂政策",
  orderdetail_bookinginfo: "訂單信息",
  orderdetail_ordertime: "購買時間",
  orderdetail_paytype: "支付方式",
  orderdetail_paytime: "支付時間",
  orderdetail_total: "總價",
  orderdetail_cancel_order: "取消訂單",
  orderdetail_cancel_modal_1: "您確定要取消當前訂單嗎？當前可免費取消",
  orderdetail_cancel_modal_2: "您確定要取消當前訂單嗎？當前取消將按規則收取手續費",
  settlement_export: "導出 ",
  settlement_billno: "賬單號",
  settlement_startdate: "開始日期",
  settlement_enddate: "結束日期",
  settlement_bookings_num: "含訂單數",
  settlement_total: "總額",
  settlement_writeoff: "銷賬狀態",
  wechat_qrcodetime: "二維碼有效時長：",
  wechat_qrcodetips1: "打開手機微信掃一掃繼續付款",
  wechat_qrcodetips2: "支付完成後沒有跳轉到支付結果頁嗎？",
  wechat_qrcodetips3: "如果您完成微信支付後沒有進行頁面跳轉，請點擊我的訂單查看支付狀態",
  wechat_changepay: "更改支付方式",
  b_companyDetails: "公司信息",
  b_BrandName: "品牌名稱",
  b_Country: "所在國家",
  b_City: "所在城市",
  b_address: "詳細地址",
  b_SettlementPeriod: "結算周期",
  b_CompanyNumber: "公司行政編號",
  b_SettlementCompany: "結算公司名稱",
  b_Personcharge: "負責人",
  b_Personemail: "負責人郵箱",
  b_Personphone: "負責人電話",
  b_Remarks: "備註",

  home_join: "註冊",
  home_explore_local: "探索目的地",
  home_search: "探索全球目的地、活動",
  home_bg1: "走到哪玩到哪",
  home_bg2: "享在旅途，與你探索詩和遠方。",
  home_banner_title: "最新活動",
  home_recommend: "推薦",
  home_view_more: "查看更多",
  home_hot_local: "熱門目的地",
  home_view_local: "更多目的地",
  home_newproduct_title: "新品搶先購",
  home_user_info_title: "會員介紹",
  home_user_info1: "註冊用戶賺取積分",
  home_user_info2: "體驗活動賺取積分",
  home_user_info3: "活動點評賺取積分",

  footer_about: "關於我們",
  footer_join_us: "加入我們",
  footer_payment: "支付方式",
  footer_sctt_note: "SCTT是怎樣的",
  footer_user_reg: "用戶註冊",
  footer_user_login: "用戶登錄",
  footer_agent: "同業交易",
  footer_supplier: "分銷/提供商機",
  footer_right_title: "點擊「訂閱」，即表示您同意我們的使用條款及隱私政策",
  footer_input_pla: "請輸入郵箱以接收訂閱信息",
  footer_dingyue: "訂閱",
  footer_service_hotline: "客服熱線",
  footer_service_hours: "服務時間",

  copy_tips: "已複製到剪切板",
  /*
  已复制到剪切板
  英语：Copied to clipboard
  韩语：클립보드에 복사된
  印尼语：Disalin ke papan klip
  马来语：Disalin ke papan keratan
  */

  search_try_key: "試試搜索不同目的地或選擇其他類別",
  search_try_more: "探索更多目的地",
  search_result_text: "搜索結果",
  /*
  中文	英文	韩文	马来语	印尼语
  试试搜索不同目的地或选择其他类别	Try searching for different destinations or choose another category	다른 목적지를 검색하거나 다른 카테고리를 선택하십시오.	Cuba cari destinasi lain atau pilih kategori lain	Coba cari tujuan lain atau pilih kategori lain
  探索更多目的地	Explore more destinations	더 많은 목적지 탐색	Teroka lebih banyak destinasi	Jelajahi lebih banyak tujuan
  搜索结果	Search results	검색 결과	Keputusan Carian	Hasil Pencarian
  */
  my_points: "積分",   //"Points"
  use_points: "使用積分",
  dikou_points: "可抵扣",
  points_deduction: "積分抵扣",
  points_tip: "說明",
  points_tip1_1: "每100積分可抵扣1",
  points_tip1_2: "",  //  幣種拼接，空字符串
  points_tips: "提示：您使用積分抵扣下單成功後，如果取消訂單，積分不會退回賬戶。",
  max_points_price: "可用積分",
  yes_use: "使用",
  not_use: "不使用",
  submit: "提交",
  setuser_email: '請輸入信箱',
  setuser_okemail: '請輸入正確的信箱',
  response_password: '密碼由8-20位英文數字和字符組成',
  placeholder_repeat_password: '請再次輸入新密碼',
  response_pwd_double: '兩次輸入密碼不一致',

  date_of_earn: "獲得日期",   //获得日期：Date of earn 수익일
  date_of_use: "使用日期",    //使用日期：Date of use 사용일자
  valid_until: "有效期至",    //有效期至：Valid until  유효기간

  share_text: "分享",

  orderform_agentpay: "支付同行結算價",

  nodata: "暫無數據",

  hotel_home_cozi: "酒店預定",

  shop_trans: '直客訂單',
  shop_account: '會員',
  commission: "傭金",
  booking_user: "預定人",

  member_title: '會員',
  member_show_phone: "手機",
  member_show_email: "郵箱",
  member_join_date: "註冊日期",

  show_agent_price: "顯示同行價",

  //预约
	yuyue:"預約", //预约   make an appointment   예약하다
  selected_quantity: "已選數量",
  next_btn: "下一步",

  sku_not_one_tips_1: "不能單獨購買，請和「",
  sku_not_one_tips_2: "」一同購買。",

  sku_not_one_error: "請選擇必購商品",

  yuyue_title: "預約確認信息",
  yuyue_btn: "提交預約",
  yuyue_submit_tips: "正在提交預約...",
  check_dingyue: '勾選即同意郵件訂閱優惠信息',

  hotel_search_destination: "目的地",
  hotel_search_destination_tips: "目的地或者酒店名稱",
  hotel_search_time: "入住/離店日期",
  hotel_search_checkin: "入住",
  hotel_search_checkout: "離店",
  hotel_search_room: "房間",
  hotel_search_adult_room: "成人/房間",
  hotel_search_child_room: "兒童/房間",
  hotel_search_adult: "成人",
  hotel_search_child: "兒童",
  hotel_search_age: "年齡",
  hotel_search_price_per_night: "每晚價格",
  hotel_search_star: "星級",
  hotel_search_text: "搜索",
  hotel_search_clear_data: "清空數據",
  hotel_search_km: "千米",
  hotel_search_room_unit:"個房間",
  hotel_search_australia:"澳大利亞",
  hotel_search_new_zealand:"新西蘭",
	hotel_nightshort: '晚',
	hotel_roomshort: '間',
	hotel_specialrequests: '特殊需求',
	hotel_specialrequests_tips: '(盡量安排但不保證)',
	hotel_remarks: '如有特殊需要請備註',
	hotel_guest_title: '稱呼',
  hotel_guest_info: "顧客信息",  //顾客信息
  hotel_guest_enter_one:"輸入一個旅客的名字",   //输入一个旅客的名字  輸入一個旅客的名字   Enter the lead guest data only
  hotel_guest_enter_all: "輸入所有旅客的名字",  //输入所有旅客的名字    Enter the data for all guests
  hotel_enter_en_name: "請輸入您名字的拼音",   //请输入您名字的拼音    Please type your name in English
  hotel_book_back: "需要更新旅客數量嗎？請點選返回上一頁修改",   // 需要更新旅客数量吗？请点击返回上一页修改  Need to update the number of guests? Please go back to update

  response_session_select:'請選擇場次',
  response_inventory_Insufficient:'庫存不足',
  cancel_1: "出行當天取消，無法退款(手續費 100%)",
	cancel_2_1: "出行前 ",
	cancel_2_2: " 天及以上取消，收取(手續費 ",
	cancel_2_3: "%)",
	cancel_3_1: "出行 ",
	cancel_3_2: " 天之間取消，收取(手續費 ",
	cancel_3_3: "%)",
	cancel_4_1: "出行前 ",
	cancel_4_2: " 天及以上取消，全額退款",

	fee_note_title: "手續費說明",
	fee_note_1: "1、不同支付渠道將會收取相應的手續費。",
	fee_note_2: "2、若因旅客個人原因提出訂單取消申請，繫統根據訂單的取消條款退還剩余款項時，在線支付手續費不予退還。",
  
  
	sku_not_one_tips_1:"不能單獨購買，請和“",
	sku_not_one_tips_2:"”一同購買。",
	/*
	不能单独购买，请和“独立销售sku名称”一同购买。
	This cannot be purchased separately, please purchase together with "独立销售sku名称".
	별도 구매가 불가하며, "独立销售sku名称"와 함께 구매해주세요.
	*/
	sku_not_one_error:"請選擇必購商品",
	/*
	请选择必购商品
	Please select a must buy item
	필수 구매 항목을 선택하십시오.
	*/
  
	my_passenger:'常旅客',
  breakfast: '早餐',
  hotel_tax:"稅",  //税   Taxes
  totalprice: '總價',
  booknow: '立即預定',
  
  /*
    待定
    On Request
    요청 시

    立即詢價
    Inquire now
    지금 문의

    訂單確認將於48個工作小時內給於回復，以最終房態確認為準。
    Confirmation will be subjected to room availability and reply within 48 working hours.
    확인은 48시간 이내에 객실 가능 여부와 회신을 받게 됩니다.
  */
  onrequest_notprice: "待定",
  onrequest_booknow: '立即詢價',
  onrequest_tip: "訂單確認將於48個工作小時內給於回復，以最終房態確認為準。",
  
  rooms: '間房',
  nightshort: '晚',
  hotelroom: '客房',
  my_title:'常旅客管理', 
  my_add:'新增常旅客', 
  phonenumberError:'請輸入手機號碼', 
  tip_phone_error:'請輸入正確的手機號碼',
  ok:'確定',
  cancel:"取消", 
  delete_tip:"確定要刪除嗎?",
  title_prompt: '提示',
  my_title2: '新增/編輯常旅客',
  height: '身高(cm)',
	weight: '體重(kg)',
	// unitcm: ' cm',
	// unitkg: ' kg',
  bnm_title:'修改信箱',
  bnp_title:'修改手機',
  phonenumberError:'請輸入手機號碼',
  response_phone_as:'不能與原手機號碼相同！',
	response_phone_err:'手機號碼已被使用！',
  email_old_no:"不能與原信箱地址相同",
	email_no:"此信箱地址已被使用",
  bind_newemail_ok:'綁定成功！',
  old_pwd_err: '舊密碼錯誤',
	old_new_equally: "新密碼不能和舊密碼相同",
	system_error: "未知錯誤!",
	response_done:'密碼修改成功，請重新登陸',
  response_pwd_double:'兩次輸入密碼不一致',
  shop_title:'店鋪管理',
  hotel_like:'偏愛酒店',
  hotel_err_title:'抱歉，暫時沒有符合這個日期條件的客房，請換個日期再試試。',

  
  account_change_nickname: "修改昵稱",  //修改昵称     Change Nickname     닉네임 수정
  account_nickname: "昵稱",  //昵稱     Nickname     닉네임
  account_nickname_tips: "請輸入昵稱",   //请输入昵称     Please enter a nickname     닉네임을 입력하십시오.
  account_set: "賬號",     //账户设置      Account and Password     계정 및 암호
  mostpopular: "夯活動",   //热门活动     Most Popular      가장 인기 있는
  account_traveler: "常旅客管理",    //常旅客管理     Frequent Traveler Info      빈번한 여행자 정보
  account_traveler_tips: "保存個人信息，預定更快速",    //保存个人信息，预定更快速    Save personal details and book faster      개인 정보 저장 및 예약 시간 단축
  account_traveler_add: "添加常旅客信息",    //常旅客管理     Add Traveler      여행자 추가

  travel_in_person: "本人",     //本人    In person    직접
  travel_use_default: "設為預設出行人",     //設為預設出行人    Use as Default   기본값으로 사용
  points_get: "獲取積分",   //獲取積分    Get Points    포인트 받기
  pages_home: "首頁",   //首頁    Home      홈
  pages_pre: "上一頁",   //上一頁      Previous     이전의
  pages_next: "下一頁",   //下一頁      Next     다음 분
  pages_last: "尾頁",   //尾頁    Last     지난

  account_edit: "編輯",    //編輯    Edit    수정하다
  account_delete: "刪除",    //編輯    Delete    삭제
  account_profile: "基本信息",    //基本信息    Profile    기본정보
  account_sex_m: "男",    //男    M        
  account_sex_f: "女",    //女    F

  pla_input_cnname: "請輸入中文姓名",   //請輸入中文姓名    請輸入中文姓名      請輸入中文姓名
  account_gender: "性別",    //性別      Gender     성별
  account_add_success: "新增成功",    //新增成功      Add success     성공 추가
  account_change_success: "修改成功",    //修改成功      Change success     변경 성공
  account_delete_success: "刪除成功",    //刪除成功      Delete success     삭제 성공
  account_cancelled: "已取消刪除",     //已取消刪除      Cancelled     취소된
  account_name_double: "姓名重復",    //姓名重復     Duplicate names     중복된 이름
  account_contact: "聯繫方式",    //聯繫方式    Contact     연락

  public_countrycode: "國家/地區代碼",     //國家/地區代碼      Country/Region Code    국가/지역 코드
  public_please_select_countrycode: "請選擇國碼",    //請選擇國碼     Please select Country/Region Code     국가/지역 코드를 선택하십시오.

  public_shop: "店鋪",   //店鋪    Shop    샵

  manage_login: "管理登入方式",   //管理登入方式    Manage login    로그인 관리
  mobile_linked_success: "手機號綁定成功",   //手機號綁定成功    Mobile number linked successfully    휴대폰 번호가 성공적으로 연결되었습니다.

  pwd_tips_1:"密碼必須至少包含8個字符",   //密碼必須至少包含8個字符    Password must contain at least 8 characters    암호는 8자 이상이어야 합니다.
  pwd_tips_2:"密碼必須至少包含1個數字",   //密碼必須至少包含1個數字    Password must contain at least 1 number    암호에 하나 이상의 숫자가 포함되어야 합니다.
  pwd_tips_3:"密碼必須至少包含1個字母",   //密碼必須至少包含1個字母    Password must contain at least 1 letter    암호에 최소 1자 이상의 문자가 포함되어야 합니다.
  pwd_tips_4:"密碼必須至少包含1個特殊字符",   //密碼必須至少包含1個特殊字符    Password must contain at least 1 special character    비밀번호는 최소 1개의 특수 문자를 포함해야 합니다.
 
  click_more: "點選檢視更多",    //点击查看更多      Read more     더 알아보기
  hotel_change_search: "更新條件",   //更新条件     Change search     검색 변경
  hotel_room_type: "客房類型",    //客房类型     Room type    객실 유형
  hotel_price_room_n: "均價/每間/每晚",    //均价/每间/每晚     Price/Room/N     가격/방/야간
  hotel_facilities: "內部設施",    //内部设施     Hotel facilities      호텔 시설
  hotel_map_other_info: "地圖和其他信息",    //地图和其他信息     Map and other information     지도 및 기타 정보
  most_num:'最多容納',
  people:'人數',
  p_reset:"清空",
  englist_name:'名/姓',
  operation:'操作',
  merchant_shop: "商戶",
  amount_change:'額度變動',
  payment_number:'賬單編號',
  payment_days:'賬期',
  order_number:'訂單數',
  money:'金額',
  download:'下載',
  commission_end_number: "傭金結算編號",
  payment_way:'結算方式',
  payment_type:'結算狀態',
  import:'重要',
  commercial_name:'店鋪名稱',
  shop_name:'商戶名稱',
  commercial_currency:'店鋪幣種',
  shop_currency:'商戶幣種',
  commercial_url:'店鋪網址',
  shop_url:'商戶網址',
  default_language:"預設語言",
  default_city:"預設城市",
  commercial_type:'店鋪狀態',
  shop_type:'商戶狀態',
  commission_b_SettlementPeriod: "傭金結算周期",
  commission_payment_way:'傭金結算方式',
  shop_finance_name:'商戶財務名稱',
  service_kefu:'客服',
  pack_up:'收起',
  new_reviews: "最新評價",
  active_reviews: "活動評價",
  low_reviews:"<3.0",
  num_reviews:'條評價',
  all_reviews:'檢視全部評價',
  use_valid_list: "使用優惠券",
  coin_certificate: '兌換券',
  use_ok_discount:'可用優惠劵',
  use_no_discount:'不可用優惠劵',
  use_rule:'使用規則',
  please_coin_certificate:'請輸入兌換券號碼',
  certificate:'兌換',
  please_ok_certificate:'請輸入正確的兌換券號碼',
  error_certificate:'當前兌換碼已失效',
  succeed_certificate:'兌換成功',
  discount_deduction:'優惠券抵扣',
  back:'返回',
  full:'滿',
  ok_user:'可用',
  no_restrict:'無門檻',
  more_reduce:'最高減',
  Sold_out:"售完",
	date_day_left_1: "餘 ",   //余2      英文为空     남은 2
	date_day_left_2: "",   //中文为空      2 left        韩文为空
  Try_other_dates:"試試別的入住日期",
  ok_minus:'可減',
  in_use:'使用中',
  stop_use:'停用',
  no_open:'未開通',
  get_message:"獲取詳細信息中",
  extra_money:'(額外算錢)',
  use_points_loser:'積分使用失敗',
  use_coupon_loser:'優惠券使用失敗',
  
  use_coupon_order_tips: "提示：1.每單只能使用一張優惠券。抵扣時優惠券未使用的金額不會額外退還。",
  use_coupon_order_tips2: "2.您使用優惠券抵扣下單成功後，如果取消訂單，優惠券不會退回賬戶",
  use_coupon_paynow_tips: "該訂單無需額外支付。請確認是否使用優惠抵扣下單？",
  
  use_points_order_tips: "提示：您使用積分抵扣下單成功後，如果取消訂單，積分不會退回賬戶。",
  to_receive: '領取',
  to_receive_success: '領取成功',
  flash_sale:"限時秒殺", //限时秒杀     Flash Sale     플래시 세일
	coming_soon: "即將開售",  //即将开售     Begins in     시작
	coming_to_end: "即將結束",  //即將結束     Ends in     종료
	on_sale: "熱銷中",  //热销中     On Sale    판매 중
	off_sale: "活動已結束",   //活動已結束   Sold out    팔렸어요
	
	shiling_sale: "時令產品",   //时令产品    In season    제철
  invalid_state:'當前狀態已失效，請重新登入',
  refund_message: '退補款信息',
  refund:'退款',
  refund_1:'補款',
  refund_1_money:'補款金額',
  order_status_err:'當前訂單狀態異常',
  refunding:'退款中',
  refunded:'已退款',
  supplementing:'現在支付',
  supplemented:'已支付' ,
  footer_dingyue_success: "訂閱成功",
  footer_dingyue_double: "您已經訂閱過該產品",   //您已经订阅过该产品     You have subscribed to this product    이 제품을 구독했습니다.
  footer_dingyue_error: "訂閱失敗",
  perfect_message:'完善信息',
  perfect_all_message:'當前賬號未設定信箱，請前往完善信息頁設定信箱',
  
	//特惠预售
	yushow_title: "特惠預售",
	yushow_tip1: "先囤後約",
	yushow_tip2: "不約可退",
	yushow_tip3: "在線預約",
	yushow_tip4: "自行預約",
	yushow_popup_tip1: "先下單鎖定優惠，確認出行日期後，再預約具體出行日期。",
	yushow_popup_tip2: "如您未進行預約，支援訂單詳情中隨時發起100%退款。",
	yushow_popup_tip3: "在訂單詳情中點選“預約按鈕”，在線預約出行日期，訂單即時確認，高效便捷。",
	yushow_popup_tip4: "請參考在訂單詳情中的詳細預約操作。",
	pre_sale_button: "完成",   //complete
	pre_sale_note1: "可使用日期：",
	pre_sale_note2: "補充說明：",
  pre_submit_tips:"暫無庫存，請聯繫客服",  //暂无库存，请联系客服   Not available at this time, please contact customer service.  지금은 사용할 수 없습니다. 고객 서비스에 문의하십시오.
  
  cash_out: "提現",   //提現    Cash out    현금 인출
  top_up: "充值",   //充值    Top up    보충하다
  not_paid: "未結算",  //未結算    Not Paid   미지급
  paid: "已結算",  //已結算   Paid    유급의
  guest_book_tips:'您目前暫未登錄，是否使用已有賬戶下單？',
	guest_login_btn: '賬戶登錄',    // Account login
	guest_next_btn: '訪客下單',     // Order as a guest
	guest_login_msg:'您目前暫未登陸，請先登陸或註冊',
	guest_login_btn2:'賬戶登陸/註冊',
  guest_tips:"您選擇了訪客下單，請務必填寫準確的下單信息。",
	guest_agree_tips1:"訪客下單時，您的個人信息將被用於確認訂單，郵箱被用於收取訂單確認信息。您下單成功後，可使用個人信息自助查詢訂單。",
	guest_agree_tips21:"若您希望使用已有賬戶登錄，",
	guest_agree_tips22:"請點擊",
  guest_tip:"可根據以下信息查詢您的訂單",
	guest_order_tip:"您的訂單編號",
	guest_email_tip:"您的預定郵箱",
  guest_ph_orderno: '請輸入訂單號',
	guest_ph_email: '請輸入下單時填寫的預定人信箱',
	guest_search: '查詢訂單',
	guest_login:'賬戶登入',
  no_login_active:'登入後下單,完成活動最高可獲得',
  no_login_credits:'積分,以實際下單獲取的積分為准',
  login_credits:'完成活動可獲得',
  myorder_order_status_6_3:"預約已過期",  
	myorder_order_status_6_4:"預約等待",  
	myorder_order_status_6_5:"預約取消",
  wait_pay:'待支付', 
  my_head_order:'我的訂單',
  please_order:'請輸入訂單號',
  please_true_order:'請輸入正確的訂單號',
  about_sctt:'關於SCTT',
  market_lol:'分銷聯盟',
  website_clause:'網站條款',
  privacy_clause:'隱私政策',
  cookie_policy:'Cookie政策',
  sonsult_kf:'咨詢客服',
  online_sonsult:'在線咨詢',
  serve_phone:'服務電話',
  serve_time:'服務時間',
  h3_txt_hint:"最高省60%，只有訂閱用戶才能享受到隱藏優惠",
  dingyue_policy:'點擊"訂閱",即錶示妳同意我們的使用條款及隱私政策',
  only_english:'只能輸入英文字符',
  presell_remain:'預售剩余',
  presell_remain1:'您當前的預定將使用預售名額，要繼續嗎？',
  presell_remain2:'您當前預定的數量大於您的預售剩余名額，提交後將不會使用預售價格，併且不會佔用您的預售名額，要繼續嗎？',
  presell_remain3:'預售項不能和非預售項一起使用，提交後將不會使用預售價格，併且不會佔用您的預售名額，要繼續嗎？',
  send_card:'發送',
  until:'至',
  class_sup_payment: "需補款",    //需补款   sup payment   지불을 보충
  valid_booking_s:'下單成功後',
  valid_booking_months:'個月內',
  valid_booking_e:'有效',
  pre_sale_date: "可預約日期：", 
  loose_pulley_text1:'歡迎進入遊輪世界預訂頁面',
  loose_pulley_text2:'建議您使用網頁瀏覽器打開',
  loose_pulley_text3:'請註意點擊下一頁進入英文版本預訂頁，您可以隨時聯系我們協助',
  loose_pulley_text4:'當您進入後，建議選擇標題頁：',
  loose_pulley_text5:'FIND A CRUISE（或直接選擇CRUISE LINES找到合適的遊輪譬如Disney Cruise Line）',
  loose_pulley_text6:'先選擇 Departure Port ：例如可輸入Sydney/Melbourne等',
  loose_pulley_text7:'篩選Cruise Line :',
  loose_pulley_text8:'選擇熱門航線例如：Carnival Cruise Lines 嘉年華 / Disney Cruise Line 迪士尼',
  loose_pulley_text9:'點擊搜索',
  loose_pulley_text10:'進入具體出發時間段的船期頁面',
  loose_pulley_text11:'選擇適合您的點擊CONTINUE繼續',
  loose_pulley_text12:'進入具體船期介紹頁面，點擊CONTINUE繼續',
  loose_pulley_text13:'請輸入所有出行人數/年齡，點擊CONTINUE繼續',
  loose_pulley_text14:'選擇艙位等級等，點擊CONTINUE繼續',
  loose_pulley_text15:'查看並選擇剩余船艙，點擊CONTINUE繼續',
  loose_pulley_text16:'查看ITINERARY行程介紹和價格等，請下拉輸入',
  loose_pulley_text17:'1，出行人的個人信息，包含生日國籍等',
  loose_pulley_text18:'2，LEAD PASSENGER CONTACT',
  loose_pulley_text19:'3，ADDITIONAL 在這裏您可以選擇HOLD BOOKING / SEND A QUOTE 以作為存檔以後再決定',
  loose_pulley_text20:'點擊CONTINUE TO PAYMENT繼續直到進入支付頁面，您可以選擇支付定金或全額支付',
  loose_pulley_text21:'點擊同意條款並MAKE PAYMENT完成',
  loose_pulley_text22:'您可以隨時聯系我們協助，如需協助建議SEND A QUOTE存檔給到我們可以迅速協助您處理',
  loose_pulley_text23:'感謝',
  loose_pulley_text24:'請註意 : 點擊下方的',
  loose_pulley_text25:'按鈕將進入英文版本預訂頁，您可以隨時聯系我們協助。',
  loose_pulley_text26:'當您進入後，建議點擊',
  loose_pulley_text27:'按鈕進入搜索頁面',
  loose_pulley_text28:'您可以直接選擇',
  loose_pulley_text29:'找到熱門航線譬如：迪士尼Disney Cruise Line，嘉年華Carnival Cruise Lines',
  loose_pulley_text30:'如無心儀的船公司，您可以選擇',
  loose_pulley_text31:'離開港口 ：例如可輸入Sydney/Melbourne等',
  loose_pulley_text33:'點擊搜索按鈕',
  loose_pulley_text34:'進入具體出發時間的船期頁面',
  loose_pulley_text35:'選擇適合您的遊輪產品，點擊',
  loose_pulley_text36:'繼續',
  loose_pulley_text37:'進入具體船期介紹頁面，點擊',
  loose_pulley_text38:'請輸入所有出行人數, 年齡，點擊',
  loose_pulley_text39:'選擇艙位等級等，點擊',
  loose_pulley_text40:'查看',
  loose_pulley_text41:'行程介紹和價格等，請完成以下內容：',
  loose_pulley_text42:'1，完成所有出行人',
  loose_pulley_text43:'個人信息，包含生日國籍等',
  loose_pulley_text44:'2，完成',
  loose_pulley_text45:'信息',
  loose_pulley_text46:'3，點擊',
  loose_pulley_text47:'進入支付頁面，您可以選擇支付定金或全額支付',
  loose_pulley_text48:'4，如果還未決定是否付錢，在您選擇的船期余位充足的情況下，會出現 ',
  loose_pulley_text49:'預留位置頁面，您可以選擇這個服務，系統會發送郵件給您做備份，請在',
  loose_pulley_text50:'前完成付款，否則位置將被放掉。如果您選擇的船期余位不多，則不會出現',
  loose_pulley_text51:'預留位置頁面。',
  loose_pulley_text52:'5，如需協助您可以在',
  loose_pulley_text53:'裏面您可以選擇',
  loose_pulley_text54:'系統會發送郵件給你，客服郵件',
  loose_pulley_text55:'也會在收到您發送的郵件後聯系您。',
  loose_pulley_text56:'您可以隨時聯系我們協助，我們會聯系您迅速協助您處理。',
  loose_pulley_text57:'感謝您的支持！',
  footer_clause1:'使用者條款',
	footer_clause2:'隱私權保護政策',
	footer_clause3:'觀光產品由',
	footer_clause4:'提供',
	footer_clause5:'代錶人',
	footer_clause6:'統一編號',
	footer_clause7:'旅行社註冊編號',
	footer_clause8:'品保協會會員編號',
	footer_clause9:'聯繫地址',
	footer_clause10:'聯繫電話',
	footer_clause11:'傳真',
	footer_clause12:'信箱',
  email_dialog_txt:'您的信箱地址或有誤，請修改併驗證。',
  need_breakfast:'需要',
  no_need_breakfast:'不需要',
  no_room_num:'酒店房間數量不足',
  //start
	nation_title:"選擇國籍",
	residency_title:"選擇居住地",
	setuser_nation:"國籍",
	setuser_residency:"居住地",
  //end
  
  member_title: "成員管理",   //成员管理    Member management    회원관리
  member_new: "新增成員",   //新增成員    New members    신규회원
  member_edit: "编辑成員",   //编辑成員    Edit members     구성원 편집
  member: "成員",   //成员   Member   회원
  member_role: "角色",    //角色   role     역할
  member_status: "狀態",    //状态    status  상황
  member_status_open: "正常",     //正常    ON    켜짐
  member_status_stop: "停用",     //停用    OFF    꺼짐
  member_creation_time: "創建時間",   //创建时间   Creation time  작성시간

  member_send_email: "向成員發送賬號激活郵件",    //向成员发送账号激活邮件      Send account activation email to members    회원에게 계정 활성화 전자 메일 보내기
  member_auth_error: "激活失敗",   //激活失败   Activation failed  활성화 실패
  member_auth_ok:"激活成功，將為您跳轉到登錄頁",   //激活成功，将为您跳转到登录页   Activation is successful and you will be redirected to the login page   활성화에 성공하면 로그인 페이지로 리디렉션됩니다
  
  member_auth_btn: "激活",     //激活    Activation    활성화
  
  member_auth_tips:"請完善密碼，以激活您的賬號。",
  member_auth_load_error: "信息異常，將為您跳轉到首頁",
  ask_price:"詢價單",
  account_add:'新增',
  guest_name:'客人姓名',
  city_name:'城市/行程天數',
  people_time:'操作人/日期',
  follow_uping:'跟進中',
  place_order:'已下單',
  ask_price_detail:"詢價單詳細",
  child_num:"兒童人數",
  child_age:"兒童年齡",
  guest_demand:'客人其他說明',
  data_num:'行程天數',
  hotel_start:'酒店星級',
  begain_city:'出發城市',
  over_city:'結束城市',
  ask_price_record:"詢價產品記錄",
  select_product:'選擇產品',
  product_resource:'產品資源',
  num_data:'數量/出發日期',
  product_card_name:'產品編號/名稱',
  discription_pla_city:"目的地城市",
  select:'選擇',
  revice_price:'調整售價',
  select_go_data:'選擇出行日期',
  advance:'預訂',
  generate_order:'生成詢價單',
  payment_type:'結算日類型',
  data_type_1:'下單日',
  data_type_2:'出行日',
  pay_type_1:'預付款',
  pay_type_2:'單結',
  pay_type_3:'周結',
  pay_type_4:'半月結',
  pay_type_5:'月結',
  day:'天',
  no_cancel:'未銷賬',
  yes_cancel:'已銷賬',
  refund_message2:'支付款項',
  sup_handsel:'定金',
  sup_final:'尾款',
  no_begain_pay:'未開啟支付',
  sup_handsel_text:'少量定金即可留位，延後支付全款',
  pay_pay_within3:'前支付完成',
  login_break:'當前賬戶變動，請重新登入',
  begain_pay_monkey:'開始支付尾款',
  cancel_service_charge:'請註意：訂單交易手續費恕不退款',
  confirm_cancel_order1:'您確定要取消當前訂單嗎？當前取消將按規則收取訂單損失及交易手續費。',
  confirm_cancel_order2:'您確定要取消當前訂單嗎？當前取消將按規則收取交易手續費。',
  pre_submit_tips2:'請聯繫客服',
  fp:'忘記密碼',
  verification_code_error:'驗證碼錯誤！',
  response_unknown_error:'驗證錯誤！',
  fill:'設定新密碼',
  set_newpassword_failed: '密碼重置失敗',
  re_login: "密碼已重置，請重新登入",
  new_pwd:'請輸入新密碼',
  email_no_account:'此郵箱沒有綁定賬號',
  phone_get_psd:'手機找回密碼',
  email_get_psd:'郵箱找回密碼',
  em:'郵箱地址',
  response_phone_no:"手機號碼不存在",
  email_login:'郵箱登錄/註冊',
  login_titlemsg_email: "使用未註冊的郵箱登錄會創建一個新的帳戶",
  reg_error: "繫統錯誤",
  verification_code_error:'驗證碼錯誤！',
  remt2:'《SCTT 服務協議》',
  login_yzm:'驗證碼登錄',
  no_account:'還沒有賬號',
  sign_up:'立即註冊',
  login_change_1:'登錄/註冊',
  login_change_2:'我已閱讀併同意',
  login_change_3:'條款與聲明',
  login_change_4:'使用郵箱',
  login_change_5:'我有賬號密碼',
  login_change_6:'立即登錄',
  login_change_7:'使用手機',
  please_wait:'請稍等',
  please_select_pay:'額度不足，請選擇其他支付方式',
  qm_need_login:'聯繫客服需登錄/註冊',
  unsubscribe1: '取消訂閱成功。',
	unsubscribe2: '如需再次訂閱，',
	unsubscribe31: '請至店鋪的',
	unsubscribe32: '個人中心',
	unsubscribe33: '頁面重新訂閱。',
  dingyue_email: '訂閱郵箱',
  dingyue_rule1:'優惠及更新信息',
  dingyue_rule2:'搶先獲取最新優惠活動、摺扣信息及最新動嚮',
  my_order_token:'我的訂單',
  go_page:'前往',
  order_shop_trans:'(訂單篩選來源)'
  /*
  请完善密码，以激活您的账号。
  Please complete your password to activate your account.
  계정을 활성화하려면 암호를 입력하십시오.

  信息异常，将为您跳转到首页
  Error, will redirect you to the home page
  오류, 홈 페이지로 리디렉션됩니다
  */,
  hotel_check_in:"入住日期",
  hotel_check_out:"退房日期",
  hotel_book_step_room:'房間',
  hotel_book_step_customer:'旅客',
  hotel_book_step_payment:'支付',
  orderfrom_customer_details:'旅客信息',
  orderfrom_lead_traveller_only:'僅填一位旅客信息', 
  orderfrom_all_travellers:'所有旅客', 
  orderfrom_first_name:'英文名', 
  orderfrom_last_name:'英文姓',
  m_logintime:'最後登錄時間',
  
  pla_ipt_name:'請輸入姓名',
  error_order:'創建訂單出現異常',
  pla_kefu_dispose:'請聯繫客服處理',

  quota_pay:"額度充值",
  quota_pay2:'在線充值',
  quota_away_pay:'立即支付',
  quota_get_total:'到賬',
  quota_line_remit:'線下轉賬匯款',
  quota_pay_record:'提交支付記錄',
  quota_pay_success:'轉賬完成，請在這裏提交憑證，財務審批後充入賬號',
  quota_company_name:'公司名稱',
  quota_bank_name:'開戶行',
  quota_bank_accound:'開戶行賬號',
  quota_type:'入賬狀態',
  quota_pay_date:'支付日期',
  quota_upload_proof:'上載憑證',
  quota_upload:'上載',
  quota_only_type:'只能上載一個圖片或pdf格式的文件',
  quota_result:'示例效果',
  quota_please_type:'請上載文件',
  quota_please_money:"請輸入金額",
  quota_min_money:'金額不能小於0',
  quota_please_date:'請選擇時間',
  
  select_sku:'已選套餐',
  selectdate_other_skutitle:'其他可定套餐',
  selectdate_no_skutitle:'當天無其他套餐可替換',
  other_goodsitem_pay_btn: "預訂該替換方案",

  //酒店特殊要求
  double_bed:"大床",  //Double bed
  two_beds:"雙床",   //Two beds
  early_check_in:"提前入住",    //Early Check in
  late_check_in:"晚入住",    //Late Check in
  late_check_out:"晚退房 (可能會產生額外收費）",  //Late Check out (may occur extra cost)
  adjacent_rooms:"臨近房間",   //Adjacent rooms
  honeymoon:"蜜月",   //Honeymoon
  InstantConfirmation: "立即確認",   //立即确认    Instant Confirmation
  pay_check_price: '抱歉，您订购的房间已经完售!',    //抱歉，您订购的房间已经完售!     Sorry, This room is sold out.
};
