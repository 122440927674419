/* eslint-disable prettier/prettier */
module.exports = {
  system_err: "系统错误",
  loading_api: "正在请求数据...",
  location: "悉尼(Sydney)",
  menu_home: "首页",
  menu_ExperienceTicket: "景点门票",
  menu_daytour: "一日游",
  menu_hotel: "酒店",
  menu_mdaytour: "多日游",
  menu_fooddining: "美食",
  menu_activity: "体验活动",
  menu_giftcard: "礼品卡",
  menu_user_myorder: "同行订单",
  menu_user_shoucang: "我的收藏",
  menu_user_account: "个人中心",
  menu_user_logout: "登出",
  tourtype: "分类",
  reset: "清空数据",
  search_pla: "请输入关键词",
  search_product_pla: "搜索产品和代码",
  search_discription_pla: "搜索并选择目的地",
  search_text: "搜索",
  label: "标签",
  page_home: "首页",
  language_title: "选择语言",
  findoutmore: "了解更多",
  joinus: "进入合作",
  home_selected: "已选择",
  home_clear: "清除选择",
  home_discription: "目的地",
  home_product: "搜索玩乐产品",
  home_data: "创建日期",
  home_sort: "排序:",
  home_liked: "喜欢的",
  home_general: "综合",
  home_price: "价格",
  home_price_hign_low: "价格由高到低",
  home_price_low_hign: "价格由低到高",
  home_world: "不限",
  home_xinpin: "新品推荐",
  home_hotelbook: "酒店预订",
  home_hotelbook_tips: "点击进入酒店预订平台",
  login_account_pla: "手机/邮箱",
  login_password_pla: "密码",
  login_phone_pla: "手机号码",
  login_send_code: "发送验证码",
  login_enter_code_pla: "请输入验证码",
  login_resend_code: "重新发送",
  login_resend_code_in: "内重新发送 ",
  login_resend_code_in_s: "秒",
  login_phonenumber_error_required: "请输入手机号码",
  login_phonenumber_error_msg: "请输入正确的手机号码",
  login_valid_code_error_msg: "验证码获取次数已超限制(每个号码 3次/天)",
  login_title: "登录",
  login_title2: "账户登陆/注册",
  login_titlemsg: "使用未注册的电话号码登录会创建一个新的帐户",
  login_titlemsg1: "请使用您的手机或邮箱账号登录",
  login_account_error_msg: "请输入账号",
  login_password_error_msg: "请输入密码",
  login_username_password_error: "账号或密码错误",
  response_password: '密码由8-20位英文数字和字符组成',
  placeholder_repeat_password: '请再次输入新密码',
  response_pwd_double: '两次输入密码不一致',
  login_remember_me: "记住我",
  login_phone_login: "验证码登录/注册",
  login_account_login: "使用账号密码登录",
  login_lastname: '英文姓',
  login_firstname: '英文名',
  login_birthday: '出生日期',
  coupon_plase_login_lastname: "请输入英文姓",
  coupon_plase_login_firstname: "请输入英文名",
  orderform_text_choose_login_birthday: '请选择出生日期',
  cancel_noone: "不可取消",
  cancel_pe: "人工取消退款",
  cancel_5: "不可退改",
  cancel_6: "预约前免费取消，预约后不可取消",

  pdetail_pid: "产品ID",
  pdetail_time: "可售日期",
  pdetail_jike: "立即确认并获得凭证，若售罄将取消",
  pdetail_t1: "取消政策",
  pdetail_soldss: "已售",
  pdetail_tab1: "产品介绍",
  pdetail_tab2: "预定须知",
  pdetail_tab3: "温馨提示",
  pdetail_tab4: "评价",
  pdetail_location_title: "目的地",
  pdetail_resort_title: "集合地",
  pdetail_exchange_title: "兑换点",
  pdetail_event_starttime: "活动营业时间",
  pdetail_projectcontain: "项目包含",
  pdetail_projectexclude: "项目不含",
  pdetail_da: "目的地地址",
  pdetail_mt1: "年龄描述",
  pdetail_mt2: "使用须知",
  pdetail_mt3: "注意事项",
  pdetail_mt4: "免责声明",
  pdetail_mt5: "紧急联系方式",
  pdetail_bn: "立即预订",
  pdetail_Wishlist: "我的收藏",
  pdetail_pricefrom: "起",
  pdetail_Packageoptions: "套餐选项",
  pdetail_selectoptions: "选择套餐选项",
  pdetail_selectedpackagedetails: "已选套餐详情",
  pdetail_valid: "有效期",
  pdetail_valid_until: "有效期至",
  pdetail_valid_booking_s: "下单成功后",
  pdetail_valid_booking_e: "有效",
  pdetail_valid_booking_months: "个月内",
  pdetail_valid_booking_years: "年内",
  pdetail_likeit: "你可能也喜欢...",
  selectdate_title: "选择日期/人数",
  selectdate_month1: "一月",
  selectdate_month2: "二月",
  selectdate_month3: "三月",
  selectdate_month4: "四月",
  selectdate_month5: "五月",
  selectdate_month6: "六月",
  selectdate_month7: "七月",
  selectdate_month8: "八月",
  selectdate_month9: "九月",
  selectdate_month10: "十月",
  selectdate_month11: "十一月",
  selectdate_month12: "十二月",
  selectdate_week1: "一",
  selectdate_week2: "二",
  selectdate_week3: "三",
  selectdate_week4: "四",
  selectdate_week5: "五",
  selectdate_week6: "六",
  selectdate_week7: "日",
  selectdate_addpro: "加购", //더 구입하기     Buy more    加購
  selectdate_title_session: "场次",
  selectdate_text_travel_times: "出行场次",
  selectdate_text_left: "剩余：",
  selectdate_text_well: " 库存充足",
  selectdate_text_no_session: "所选日期无可用场次，不可预订",
  selectdate_text_loading_session: "加载场次...",
  selectdate_text_loading_price: "加载价格...",
  selectdate_response_session_select: "请选择场次",
  selectdate_response_quantity_min: "预订份数不能小于",
  selectdate_response_quantity_zero: "预订份数不能为0",
  selectdate_response_inventory_Insufficient: "库存不足",
  selectdate_skutitle: "选择数量",
  selectdate_booking_details: "订单详情",
  selectdate_package_type: "套餐类型",
  selectdate_date_time: "日期 & 时间",
  selectdate_price_details: "费用详情",
  selectdate_please_choose: "请完成所有必选项",
  selectdate_see_all: "显示全部",
  selectdate_tips1: "*价格可能根据出行日期、优惠条件有所浮动，具体请以结算页面的标价为准。",
  selectdate_tips2: "*由于您使用的是澳洲平台，使用微信、支付宝、银联卡、信用卡支付订单时会收取银行交易手续费。",
  orderform_choose_booking: "预定选项",
  orderform_pay: "支付",
  orderform_enter_info: "填写信息",
  orderform_enter_info_tip: "*请仔细填写，订单提交后可能无法修改",
  orderform_boolean_true: "是",
  orderform_boolean_false: "否",
  orderform_valid_date: "有效期",
  orderform_valid_until: "有效期至",
  orderform_travelinfo: "出行信息",
  orderform_chuxingren: "出行人",
  orderform_contact_info: "联系信息",
  orderform_other_info: "其他信息",
  orderform_contact_tip: "如你的预订有任何变动，我们将及时通知你",
  orderform_title_pickuplocation: "接送点",
  orderform_title_pickuplocation2:'上车点',
  orderform_title_dropofflocation: "下车点",
  orderform_location_title_no: "无",
  orderform_agentnumber: "同行订单号",
  orderform_remarks: "备注",
  orderform_booking_tip: "点击按钮后，你的订单将会自动提交。请在下一页选择付款方式",
  orderform_continue: "前往付款",
  orderform_date: "日期",
  orderform_time: "时间",
  orderform_quantity: "数量",
  orderform_subtotal: "合计",
  orderform_total: "总计",
  orderform_subtotal2: "价格",
  orderform_payment_amount: "支付金额",
  orderform_text_please_enter: "请输入",
  orderform_text_correct: "正确的",
  orderform_text_choose: "请选择",
  orderform_required: "必填",
  orderform_coupon_shop: "店铺立减",
  coupon_title: "优惠券",
  coupon_checking: "验证",
  coupon_checkok: "已验证通过",
  coupon_plase_print: "请输入优惠券号码",
  coupon_submit: "提交使用",
  coupon_valid_list: "当前使用优惠券",
  coupon_cancel: "取消",
  coupon_shengyu_num: "当前还可使用数量",
  coupon_btn_confirm: "确认",
  coupon_btn_cancel_logout: "点错了",
  coupon_tip_cancel: "您确定要取消吗？取消后优惠券不会失效，本单将不再享用优惠。",
  coupon_Voucher_Invalid_1001: "找不到该优惠券。",
  coupon_Voucher_Invalid_1007: "该优惠券已使用。",
  coupon_Voucher_Invalid_1008: "该优惠券已过期。",
  coupon_Voucher_Invalid_1013: "您今天已使用过优惠券，请明日再使用。",
  coupon_notes_title: "报错提示说明：",
  coupon_notes_1: "1. 找不到该优惠券：请核实输入的优惠券代码是否正确；请确认您使用的是Discover Voucher。",
  coupon_notes_2: "2. 该优惠券已使用：请核实您是否已使用过该优惠券。",
  coupon_notes_3: "3. 该优惠券已过期：该优惠券已过期，无法再使用。",
  coupon_notes_4: "4. 您今天已使用过优惠券，请明日再使用：一天只能使用一张优惠券，请在次日再使用该优惠券。",
  coupon_order_amount: "订单金额",
  coupon_p_notes_1: "1. 找不到该优惠券：请核实输入的优惠券代码是否正确；请确认您使用的是Parents Voucher。",
  coupon_p_notes_2: "2. 该优惠券已使用：请核实您是否已使用过该优惠券。",
  coupon_p_notes_3: "3. 该优惠券已过期：该优惠券已过期，无法再使用。",
  myorder_shop_coupon: "店铺立减",
  myorder_myorder: "订单",
  myorder_manage_personal_info: "个人信息管理",
  myorder_mybookings: "订单",
  myorder_wait_for_confirm: "待确认",
  myorder_cancelled: "已取消",
  myorder_bookingdate: "预订日期",
  myorder_reviews: "我的评价",
  myorder_credit_limit: "额度",
  myorder_manage_booking_info: "出行人管理",
  myorder_settings: "商户中心",
  myorder_start_date: "出行日期",
  myorder_start_or_booking_date: "预订日期/出行日期",
  myorder_please_choose_date: "请选择日期类型",
  myorder_valid_until: "有效期至",
  myorder_label_duration_of_validity: "有效期间",
  myorder_sum_payable: "应付金额",
  myorder_total_paid: "实付金额",
  myorder_details: "查看详细",
  myorder_gotopay: "去支付",
  myorder_paystatus: "支付状态",
  myorder_bookingstatus: "预订状态",
  myorder_all: "全部",
  myorder_unpaid: "未支付",
  myorder_paid: "已支付",
  
  myorder_order_status_0: "待付款",
  myorder_order_status_1: "待确认",
  myorder_order_status_2: "已确认",
  myorder_order_status_3: "已出行",
  myorder_order_status_4: "待取消",
  myorder_order_status_5: "已取消",
  myorder_order_status_6: "待预约",   //待预约    To be reserved       유보하다
  myorder_order_status_7: "已预约",    //已预约     Reserved      예약됨
  myorder_order_status_8: "未出行",
  myorder_order_status_9: "待预约",
  myorder_order_status_6_3:"预约已过期",  
	myorder_order_status_6_4:"预约等待",  
	myorder_order_status_6_5:"预约取消", 
  



  myorder_search_order: "订单号,产品名称,产品ID",
  myorder_search_agentcode: "同行订单号",
  myorder_search_confirmation_no: "供应商确认号",
  myorder_search_bookingcontact: "预订联系人，手机号，邮箱",
  myorder_reset: "清空",
  my_jiesuan: "结算",
  my_accountinfo: "个人信息",
  setuser_top: '您若要继续，请完善以下信息。',
  my_name: "姓名",
  my_phone: "电话",
  my_email: "邮箱",
  my_pwdop: "密码管理",
  my_pwdop_tips: "定期修改密码以提高账户安全",
  my_oldpwd: "当前密码",
  my_oldpwd_tips: "更改密码",
  my_newpwd_tips: "新密码（8 - 20个字符，须至少包含1个数字、1个字母及1个特殊符号）",
  my_newpwd_pla1: "请输入密码",
  my_newpwd_pla2: "确认密码",
  my_save: "保存",
  pay_complete_payment: "确认付款",
  pay_pay_within1: "请于",
  pay_pay_within2: "内完成付款",
  pay_credit_pay: "额度支付",
  pay_credit_amount: "可用额度",
  pay_alipay: "支付宝",
  pay_wechat: "微信支付",
  pay_union: "银联支付",
  pay_hc: "手续费",
  pay_booking_tip: "点击“前往付款”，即表示您已核对订单及总金额，且同意",
  pay_booking_tip_a: "使用条款",
  payreturn_ok: "支付完成!",
  payreturn_email_tip: "预订信息和使用凭证将在1-2个工作日内发送至您的电子邮件",
  payreturn_orderno: "订单号",
  payreturn_vieworder: "查看订单",
  location_hot_title: "热门目的地",
  location_country_city_title: "请选择国家 / 城市",
  location_state_city_title: "请选择目的地",
  location_bottom_tips: "向下滑动查看更多目的地",
  orderdetail_payment_type_1: "信用卡",
  orderdetail_payment_type_2: "支付宝",
  orderdetail_payment_type_3: "微信",
  orderdetail_payment_type_4: "银联",
  orderdetail_payment_type_5: "线下支付",
  orderdetail_payment_type_6: "信用额度",
  orderdetail_payment_type_7: "充值额度",
  orderdetail_orderno: "订单号",
  orderdetail_agentcode: "同行订单号",
  orderdetail_viewvoucher: "查看凭证",
  orderdetail_pay: "实付金额",
  orderdetail_paydetails: "支付明细",
  orderdetail_orderdetails: "订购详情",
  orderdetail_contactinfo: "预定人信息",
  orderdetail_travelinfo: "出行人信息",
  orderdetail_cancelinfo: "退订政策",
  orderdetail_bookinginfo: "订单信息",
  orderdetail_ordertime: "购买时间",
  orderdetail_paytype: "支付方式",
  orderdetail_paytime: "支付时间",
  orderdetail_total: "总价",
  orderdetail_cancel_order: "取消订单",
  orderdetail_cancel_modal_1: "您确定要取消当前订单吗？当前可免费取消",
  orderdetail_cancel_modal_2: "您确定要取消当前订单吗？当前取消将按规则收取手续费",
  settlement_export: "导出 ",
  settlement_billno: "账单号",
  settlement_startdate: "开始日期",
  settlement_enddate: "结束日期",
  settlement_bookings_num: "含订单数",
  settlement_total: "总额",
  settlement_writeoff: "销账状态",
  wechat_qrcodetime: "二维码有效时长：",
  wechat_qrcodetips1: "打开手机微信扫一扫继续付款",
  wechat_qrcodetips2: "支付完成后没有跳转到支付结果页吗？",
  wechat_qrcodetips3: "如果您完成微信支付后没有进行页面跳转，请点击我的订单查看支付状态",
  wechat_changepay: "更改支付方式",
  b_companyDetails: "公司信息",
  b_BrandName: "品牌名称",
  b_Country: "所在国家",
  b_City: "所在城市",
  b_address: "详细地址",
  b_SettlementPeriod: "结算周期",
  b_CompanyNumber: "公司行政编号",
  b_SettlementCompany: "结算公司名称",
  b_Personcharge: "负责人",
  b_Personemail: "负责人邮箱",
  b_Personphone: "负责人电话",
  b_Remarks: "备注",

  home_join: "注册",
  home_explore_local: "探索目的地",
  home_search: "探索全球目的地、活动",
  home_bg1: "走到哪玩到哪",
  home_bg2: "享在旅途，与你探索诗和远方。",
  home_banner_title: "最新活动",
  home_recommend: "推荐",
  home_view_more: "查看更多",
  home_hot_local: "热门目的地",
  home_view_local: "更多目的地",
  home_newproduct_title: "新品抢先购",
  home_user_info_title: "会员介绍",
  home_user_info1: "注册用户赚取积分",
  home_user_info2: "体验活动赚取积分",
  home_user_info3: "活动点评赚取积分",

  footer_about: "关于我们",
  footer_join_us: "加入我们",
  footer_payment: "支付方式",
  footer_sctt_note: "SCTT是怎样的",
  footer_user_reg: "用户注册",
  footer_user_login: "用户登录",
  footer_agent: "同业交易",
  footer_supplier: "分销/提供商机",
  footer_right_title: "点击“订阅”，即表示您同意我们的使用条款及隐私政策",
  footer_input_pla: "请输入邮箱以接收订阅信息",
  footer_dingyue: "订阅",
  footer_service_hotline: "客服热线",
  footer_service_hours: "服务时间",
  setuser_email: '请输入邮箱',
  setuser_okemail: '请输入正确的邮箱',
  reg_mail_error: "邮箱已被使用",

  copy_tips: "已复制到剪切板",
  /*
  已复制到剪切板
  英语：Copied to clipboard
  韩语：클립보드에 복사된
  印尼语：Disalin ke papan klip
  马来语：Disalin ke papan keratan
  */

  search_try_key: "试试搜索不同目的地或选择其他类别",
  search_try_more: "探索更多目的地",
  search_result_text: "搜索结果",
  /*
  中文	英文	韩文	马来语	印尼语
  试试搜索不同目的地或选择其他类别	Try searching for different destinations or choose another category	다른 목적지를 검색하거나 다른 카테고리를 선택하십시오.	Cuba cari destinasi lain atau pilih kategori lain	Coba cari tujuan lain atau pilih kategori lain
  探索更多目的地	Explore more destinations	더 많은 목적지 탐색	Teroka lebih banyak destinasi	Jelajahi lebih banyak tujuan
  搜索结果	Search results	검색 결과	Keputusan Carian	Hasil Pencarian
  */


  my_points: "积分",   //"Points"
  use_points: "使用积分",
  dikou_points: "可抵扣",
  points_deduction: "积分抵扣",
  points_tip: "说明",
  points_tip1_1: "每100积分可抵扣1",
  points_tip1_2: "",  //  币种拼接，空字符串
  points_tips: "提示：您使用积分抵扣下单成功后，如果取消订单，积分不会退回账户。",
  max_points_price: "可用积分",
  yes_use: "使用",
  not_use: "不使用",
  submit: "提交",

  date_of_earn: "获得日期",   //获得日期：Date of earn 수익일
  date_of_use: "使用日期",    //使用日期：Date of use 사용일자
  valid_until: "有效期至",    //有效期至：Valid until  유효기간

  share_text: "分享",

  orderform_agentpay: "支付同行结算价",

  nodata: "暂无数据",

  hotel_home_cozi: "酒店预订",


  shop_trans: '直客订单',
  shop_account: '会员',
  commission: "佣金",
  booking_user: "预定人",

  member_title: '会员',
  member_show_phone: "手机",
  member_show_email: "邮箱",
  member_join_date: "注册日期",

  show_agent_price: "显示同行价",


  //预约
  yuyue: "预约", //预约   make an appointment   예약하다
  selected_quantity: "已选数量",
  next_btn: "下一步",

  sku_not_one_tips_1: "不能单独购买，请和“",
  sku_not_one_tips_2: "”一同购买。",

  sku_not_one_error: "请选择必购商品",

  yuyue_title: "预约确认信息",
  yuyue_btn: "提交预约",
  yuyue_submit_tips: "正在提交预约...",

  check_dingyue: '勾选即同意邮件订阅优惠信息',

  hotel_search_destination: "目的地/住宿名称",
  hotel_search_destination_tips: "目的地或者酒店名称",
  hotel_search_time: "入住/离店日期",
  hotel_search_checkin: "入住",
  hotel_search_checkout: "离店",
  hotel_search_room: "房间",
  hotel_search_adult_room: "成人/房间",
  hotel_search_adult: "成人",
  hotel_search_child_room: "儿童/房间",
  hotel_search_child: "儿童",
  hotel_search_age: "年龄",
  hotel_search_price_per_night: "每晚价格",
  hotel_search_star: "星级",
  hotel_search_text: "搜索",
  hotel_search_clear_data: "清空数据",
  hotel_search_km: "千米",
  hotel_search_room_unit: "个房间",
  hotel_search_australia: "澳大利亚",
  hotel_search_new_zealand: "新西兰",
  hotel_nightshort: '晚',
  hotel_roomshort: '间',
  hotel_specialrequests: '特殊需求',
  hotel_specialrequests_tips: '(尽量安排但不保证)',
  hotel_remarks: '如有特殊需要请备注',
  hotel_guest_title: '称呼',
  hotel_guest_info: "顾客信息",  //顾客信息
  hotel_guest_enter_one: "输入一个旅客的名字",   //输入一个旅客的名字  輸入一個旅客的名字   Enter the lead guest data only
  hotel_guest_enter_all: "输入所有旅客的名字",  //输入所有旅客的名字    Enter the data for all guests
  hotel_enter_en_name: "请输入您名字的拼音",   //请输入您名字的拼音    Please type your name in English
  hotel_book_back: "需要更新旅客数量吗？请点击返回上一页修改",   // 需要更新旅客数量吗？请点击返回上一页修改  Need to update the number of guests? Please go back to update

  response_session_select: '请选择场次',
  response_inventory_Insufficient: '库存不足',
  cancel_1: "出行当天取消，无法退款(手续费 100%)",
  cancel_2_1: "出行前 ",
  cancel_2_2: " 天及以上取消，收取(手续费 ",
  cancel_2_3: "%)",
  cancel_3_1: "出行 ",
  cancel_3_2: " 天之间取消，收取(手续费 ",
  cancel_3_3: "%)",
  cancel_4_1: "出行前 ",
  cancel_4_2: " 天及以上取消，全额退款",


  fee_note_title: "手续费说明",
  fee_note_1: "1、不同支付渠道将会收取相应的手续费。",
  fee_note_2: "2、若因旅客个人原因提出订单取消申请，系统根据订单的取消条款退还剩余款项时，在线支付手续费不予退还。",

  sku_not_one_tips_1: "不能单独购买，请和“",
  sku_not_one_tips_2: "”一同购买。",
  /*
  不能单独购买，请和“独立销售sku名称”一同购买。
  This cannot be purchased separately, please purchase together with "独立销售sku名称".
  별도 구매가 불가하며, "独立销售sku名称"와 함께 구매해주세요.
  */
  sku_not_one_error: "请选择必购商品",
  /*
  请选择必购商品
  Please select a must buy item
  필수 구매 항목을 선택하십시오.
  */

  my_passenger: '常旅客',
  hotel_tax: "税",  //税   Taxes
  breakfast: '早餐',
  totalprice: '总价',
  booknow: '立即预定',
  /*
    待定
    On Request
    요청 시

    立即询价
    Inquire now
    지금 문의

    订单确认将于48个工作小时内给于回复，以最终房态确认为准。
    Confirmation will be subjected to room availability and reply within 48 working hours.
    확인은 48시간 이내에 객실 가능 여부와 회신을 받게 됩니다.
  */
  onrequest_notprice: "待定",
  onrequest_booknow: '立即询价',
  onrequest_tip: "订单确认将于48个工作小时内给于回复，以最终房态确认为准。",

  rooms: '间房',
  nightshort: '晚',
  hotelroom: '客房',
  my_title: '常旅客管理',
  my_add: '新增常旅客',
  phonenumberError: '请输入手机号码',
  tip_phone_error: '请输入正确的手机号码',
  ok: '确定',
  cancel: "取消",
  delete_tip: "确定要删除吗?",
  title_prompt: '提示',
  my_title2: '新增/编辑常旅客',
  height: '身高(cm)',
  weight: '体重(kg)',
  // unitcm: ' cm',
  // unitkg: ' kg',
  bnm_title: '修改邮箱',
  bnp_title: '修改手机',
  phonenumberError: '请输入手机号码',
  response_phone_as: '不能与原手机号码相同！',
  response_phone_err: '手机号码已被使用！',
  email_old_no: "不能与原邮箱地址相同",
  email_no: "此邮箱地址已被使用",
  bind_newemail_ok: '绑定成功！',
  old_pwd_err: '旧密码错误',
  old_new_equally: "新密码不能和旧密码相同",
  system_error: "未知错误!",
  response_done: '密码修改成功，请重新登陆',
  response_pwd_double: '两次输入密码不一致',
  shop_title: '店铺管理',
  hotel_like: '偏爱酒店',
  hotel_err_title: '抱歉，暂时没有符合这个日期条件的客房，请换个日期再试试。',

  account_change_nickname: "修改昵称",  //修改昵称     Change Nickname     닉네임 수정
  account_nickname: "昵称",  //昵称     Nickname     별명
  account_nickname_tips: "请输入昵称",   //请输入昵称     Please enter a nickname     닉네임을 입력하십시오.
  account_set: "账号",     //账户设置      Account and Password     계정 및 암호
  mostpopular: "热门活动",   //热门活动     Most Popular      가장 인기 있는
  account_traveler: "常旅客管理",    //常旅客管理     Frequent Traveler Info      빈번한 여행자 정보
  account_traveler_tips: "保存个人信息，预定更快速",    //保存个人信息，预定更快速    Save personal details and book faster      개인 정보 저장 및 예약 시간 단축
  account_traveler_add: "添加常旅客信息",    //常旅客管理     Add Traveler      여행자 추가

  travel_in_person: "本人",     //本人    In person    직접
  travel_use_default: "设为默认出行人",     //设为默认出行人    Use as Default   기본값으로 사용
  points_get: "获取积分",   //获取积分    Get Points    포인트 받기
  pages_home: "首页",   //首页    Home      홈
  pages_pre: "上一页",   //上一页      Previous     이전의
  pages_next: "下一页",   //下一页      Next     다음 분
  pages_last: "尾页",   //尾页    Last     지난

  account_edit: "编辑",    //编辑    Edit    수정하다
  account_delete: "删除",    //编辑    Delete    삭제
  account_profile: "基本信息",    //基本信息    Profile    기본정보
  account_sex_m: "男",    //男    M    
  account_sex_f: "女",    //女    F

  pla_input_cnname: "请输入中文姓名",   //请输入中文姓名    请输入中文姓名      请输入中文姓名
  account_gender: "性别",    //性别      Gender     성별
  account_add_success: "新增成功",    //新增成功      Add success     성공 추가
  account_change_success: "修改成功",    //修改成功      Change success     변경 성공
  account_delete_success: "删除成功",    //删除成功      Delete success     삭제 성공
  account_cancelled: "已取消删除",     //已取消删除      Cancelled     취소된
  account_name_double: "姓名重复",    //姓名重复     Duplicate names     중복된 이름
  account_contact: "联系方式",    //联系方式    Contact     연락

  public_countrycode: "国家/地区代码",     //国家/地区代码      Country/Region Code    국가/지역 코드
  public_please_select_countrycode: "请选择国码",    //请选择国码     Please select Country/Region Code     국가/지역 코드를 선택하십시오.

  public_shop: "店铺",   //店铺    Shop    샵

  manage_login: "管理登录方式",   //管理登录方式    Manage login    로그인 관리
  mobile_linked_success: "手机号绑定成功",   //手机号绑定成功    Mobile number linked successfully    휴대폰 번호가 성공적으로 연결되었습니다.

  pwd_tips_1: "密码必须至少包含8个字符",   //密码必须至少包含8个字符    Password must contain at least 8 characters    암호는 8자 이상이어야 합니다.
  pwd_tips_2: "密码必须至少包含1个数字",   //密码必须至少包含1个数字    Password must contain at least 1 number    암호에 하나 이상의 숫자가 포함되어야 합니다.
  pwd_tips_3: "密码必须至少包含1个字母",   //密码必须至少包含1个字母    Password must contain at least 1 letter    암호에 최소 1자 이상의 문자가 포함되어야 합니다.
  pwd_tips_4: "密码必须至少包含1个特殊字符",   //密码必须至少包含1个特殊字符    Password must contain at least 1 special character    비밀번호는 최소 1개의 특수 문자를 포함해야 합니다.

  click_more: "点击查看更多",    //点击查看更多      Read more     더 알아보기
  hotel_change_search: "更新条件",   //更新条件     Update     검색 변경
  hotel_room_type: "客房类型",    //客房类型     Room type    객실 유형
  hotel_price_room_n: "均价/每间/每晚",    //均价/每间/每晚     Price/Room/N     가격/방/야간
  hotel_facilities: "内部设施",    //内部设施     Hotel facilities      호텔 시설
  hotel_map_other_info: "地图和其他信息",    //地图和其他信息     Map and other information     지도 및 기타 정보
  most_num: '最多容纳',
  people: '人数',
  p_reset: "重置",
  englist_name: '名/姓',
  operation: '操作',
  merchant_shop: "商户",
  amount_change: '额度变动',
  payment_number: '账单编号',
  payment_days: '账期',
  order_number: '订单数',
  money: '金额',
  download: '下载',
  commission_end_number: "佣金结算编号",
  payment_way: '结算方式',
  payment_type: '结算状态',
  import: '重要',
  commercial_name: '店铺名称',
  shop_name: '商户名称',
  commercial_currency: '店铺币种',
  shop_currency: '商户币种',
  commercial_url: '店铺网址',
  shop_url: '商户网址',
  default_language: "默认语言",
  default_city: "默认城市",
  commercial_type: '店铺状态',
  shop_type: '商户状态',
  commission_b_SettlementPeriod: "佣金结算周期",
  commission_payment_way: '佣金结算方式',
  shop_finance_name: '商户财务名称',
  service_kefu: '客服',
  pack_up: '收起',
  new_reviews: "最新评价",
  active_reviews: "活动评价",
  low_reviews: "<3.0",
  num_reviews: '条评价',
  all_reviews: '查看全部评价',
  use_valid_list: "使用优惠券",
  coin_certificate: '兑换券',
  use_ok_discount: '可用优惠劵',
  use_no_discount: '不可用优惠劵',
  use_rule: '使用规则',
  please_coin_certificate: '请输入兑换券号码',
  certificate: '兑换',
  please_ok_certificate: '请输入正确的兑换券号码',
  error_certificate: '当前兑换码已失效',
  succeed_certificate: '兑换成功',
  discount_deduction: '优惠券抵扣',
  back: '返回',
  full: '满',
  ok_user: '可用',
  no_restrict: '无门槛',
  more_reduce: '最高减',
  Sold_out: "售罄",
	date_day_left_1: "余 ",   //余2      英文为空     남은 2
	date_day_left_2: "",   //中文为空      2 left        韩文为空
  Try_other_dates: "试试别的入住日期",
  ok_minus: '可减',
  in_use: '使用中',
  stop_use: '停用',
  no_open: '未开通',
  get_message: "获取详细信息中",
  extra_money: '(额外算钱)',
  use_points_loser: '积分使用失败',
  use_coupon_loser: '优惠券使用失败',

  use_coupon_order_tips: "提示：1.每单只能使用一张优惠券。抵扣时优惠券未使用的金额不会额外退还。",
  use_coupon_order_tips2: "2.您使用优惠券抵扣下单成功后，如果取消订单，优惠券不会退回账户",
  use_coupon_paynow_tips: "该订单无需额外支付。请确认是否使用优惠抵扣下单？",
  use_points_order_tips: "提示：您使用积分抵扣下单成功后，如果取消订单，积分不会退回账户。",

  to_receive: '领取',
  to_receive_success: '领取成功',
  flash_sale: "限时秒杀", //限时秒杀     Flash Sale     플래시 세일
  on_sale: "热销中",  //热销中 
  off_sale: "活动已结束",
  coming_soon: "即将开售",  //即将开售     Begins in     시작
  coming_to_end: "即将结束",  //即將結束
  shiling_sale: "时令产品",
  invalid_state: '当前状态已失效，请重新登录',
  refund_message: '退补款信息',
  refund: '退款',
  refund_1: '补款',
  refund_1_money: '补款金额',
  order_status_err: '当前订单状态异常',
  refunding: '退款中',
  refunded: '已退款',
  supplementing: '现在支付',
  supplemented: '已支付',
  footer_dingyue_success: "订阅成功",
  footer_dingyue_double: "您已经订阅过该产品",   //您已经订阅过该产品     You have subscribed to this product    이 제품을 구독했습니다.
  footer_dingyue_error: "订阅失败",
  perfect_message: '完善信息',
  perfect_all_message: '当前账号未设置邮箱，请前往完善信息页设置邮箱',
  until:'至',
  /*
    Notice: Only one coupon can be used per order. No additional refund will be given for the unused amount of the voucher at the time of payment.	주의: 쿠폰은 주문당 한 장만 사용할 수 있습니다. 결제 시 바우처 미사용 금액에 대해서는 추가 환불이 되지 않습니다.
  No additional payment is required for this order. Please check if you are using the discount in placing your order?	이 주문에는 추가 지불이 필요하지 않습니다. 당신이 주문을 할 때 할인을 사용하고 있는지 확인해 주세요.

  */


  yushow_title: "特惠预售",
  yushow_tip1: "先囤后约",
  yushow_tip2: "不约可退",
  yushow_tip3: "在线预约",
  yushow_tip4: "自行预约",

  yushow_popup_tip1: "先下单锁定优惠，确认出行日期后，再预约具体出行日期。",
  yushow_popup_tip2: "如您未进行预约，支持订单详情中随时发起100%退款。",
  yushow_popup_tip3: "在订单详情中点击“预约按钮”，在线预约出行日期，订单即时确认，高效便捷。",
  yushow_popup_tip4: "请参考在订单详情中的详细预约操作。",
  pre_sale_button: "完成",   //complete

  pre_sale_note1: "可使用日期：",
  pre_sale_note2: "补充说明：",
  pre_submit_tips:"暂无库存，请联系客服",  //暂无库存，请联系客服   Not available at this time, please contact customer service.  지금은 사용할 수 없습니다. 고객 서비스에 문의하십시오.
  
  cash_out: "提现",   //提现    Cash out    현금 인출
  top_up: "充值",   //充值    Top up    보충하다
  not_paid: "未结算",  //未结算    Not Paid   미지급
  paid: "已结算",  //已结算   Paid    유급의
  guest_book_tips:'您目前暂未登录，是否使用已有账户下单？',
  guest_login_btn: '账户登录',    // Account login
	guest_next_btn: '访客下单',     // Order as a guest
  guest_login_msg:'您目前暂未登陆，请先登陆或注册',
	guest_login_btn2:'账户登陆/注册',
  guest_tips:"您选择了访客下单，请务必填写准确的下单信息。",
  guest_agree_tips1:"访客下单时，您的个人信息将被用于确认订单，邮箱被用于收取订单确认信息。您下单成功后，可使用个人信息自助查询订单。",
	guest_agree_tips21:"若您希望使用已有账户登录，",
	guest_agree_tips22:"请点击",
  guest_tip:"可根据以下信息查询您的订单",
	guest_order_tip:"您的订单编号",
	guest_email_tip:"您的预定邮箱",
  guest_ph_orderno: '请输入订单号',
	guest_ph_email: '请输入下单时填写的预定人邮箱',
	guest_search: '查询订单',
	guest_login:'账户登录',
  no_login_active:'登录后下单,完成活动最高可获得',
  no_login_credits:'积分,以实际下单获取的积分为准',
  login_credits:'完成活动可获得',
  wait_pay:'待支付',
  my_head_order:'订单查询',
  please_order:'请输入订单号',
  please_true_order:'请输入正确的订单号',
  about_sctt:'关于SCTT',
  market_lol:'分销联盟',
  website_clause:'网站条款',
  privacy_clause:'隐私政策',
  cookie_policy:'Cookie政策',
  sonsult_kf:'咨询客服',
  online_sonsult:'在线咨询',
  serve_phone:'服务电话',
  serve_time:'服务时间',
  h3_txt_hint:"最高省60%，只有订阅用户才能享受到隐藏优惠",
  dingyue_policy:'点击"订阅",即表示你同意我们的使用条款及隐私政策',
  only_english:'只能输入英文字符',
  presell_remain:'预售剩余',
  presell_remain1:'您当前的预定将使用预售名额，要继续吗？',
  presell_remain2:'您当前预定的数量大于您的预售剩余名额，提交后将不会使用预售价格，并且不会占用您的预售名额，要继续吗？',
  presell_remain3:'预售项不能和非预售项一起使用，提交后将不会使用预售价格，并且不会占用您的预售名额，要继续吗？',
  send_card:'发送',
  until:'至',
  valid_booking_s:'下单成功后',
  valid_booking_months:'个月内',
  valid_booking_e:'有效',
  class_sup_payment: "需补款",    //需补款   sup payment   지불을 보충
  pre_sale_date: "可预约日期：", 
  loose_pulley_text1:'欢迎进入游轮世界预订页面',
  loose_pulley_text2:'建议您使用网页浏览器打开',
  loose_pulley_text3:'请注意点击下一页进入英文版本预订页，您可以随时联系我们协助',
  loose_pulley_text4:'当您进入后，建议选择标题页：',
  loose_pulley_text5:'FIND A CRUISE（或直接选择CRUISE LINES找到合适的游轮譬如Disney Cruise Line）',
  loose_pulley_text6:'先选择 Departure Port ：例如可输入Sydney/Melbourne等',
  loose_pulley_text7:'筛选Cruise Line :',
  loose_pulley_text8:'选择热门航线例如：Carnival Cruise Lines 嘉年华 / Disney Cruise Line 迪士尼',
  loose_pulley_text9:'点击搜索',
  loose_pulley_text10:'进入具体出发时间段的船期页面',
  loose_pulley_text11:'选择适合您的点击CONTINUE继续',
  loose_pulley_text12:'进入具体船期介绍页面，点击CONTINUE继续',
  loose_pulley_text13:'请输入所有出行人数/年龄，点击CONTINUE继续',
  loose_pulley_text14:'选择舱位等级等，点击CONTINUE继续',
  loose_pulley_text15:'查看并选择剩余船舱，点击CONTINUE继续',
  loose_pulley_text16:'查看ITINERARY行程介绍和价格等，请下拉输入',
  loose_pulley_text17:'1，出行人的个人信息，包含生日国籍等',
  loose_pulley_text18:'2，LEAD PASSENGER CONTACT',
  loose_pulley_text19:'3，ADDITIONAL 在这里您可以选择HOLD BOOKING / SEND A QUOTE 以作为存档以后再决定',
  loose_pulley_text20:'点击CONTINUE TO PAYMENT继续直到进入支付页面，您可以选择支付定金或全额支付',
  loose_pulley_text21:'点击同意条款并MAKE PAYMENT完成',
  loose_pulley_text22:'您可以随时联系我们协助，如需协助建议SEND A QUOTE存档给到我们可以迅速协助您处理',
  loose_pulley_text23:'感谢',
  loose_pulley_text24:'请注意 : 点击下方的',
  loose_pulley_text25:'按钮将进入英文版本预订页，您可以随时联系我们协助。',
  loose_pulley_text26:'当您进入后，建议点击',
  loose_pulley_text27:'按钮进入搜索页面',
  loose_pulley_text28:'您可以直接选择',
  loose_pulley_text29:'找到热门航线譬如：迪士尼Disney Cruise Line，嘉年华Carnival Cruise Lines',
  loose_pulley_text30:'如无心仪的船公司，您可以选择',
  loose_pulley_text31:'离开港口 ：例如可输入Sydney/Melbourne等',
  loose_pulley_text33:'点击搜索按钮',
  loose_pulley_text34:'进入具体出发时间的船期页面',
  loose_pulley_text35:'选择适合您的游轮产品，点击',
  loose_pulley_text36:'继续',
  loose_pulley_text37:'进入具体船期介绍页面，点击',
  loose_pulley_text38:'请输入所有出行人数, 年龄，点击',
  loose_pulley_text39:'选择舱位等级等，点击',
  loose_pulley_text40:'查看',
  loose_pulley_text41:'行程介绍和价格等，请完成以下内容：',
  loose_pulley_text42:'1，完成所有出行人',
  loose_pulley_text43:'个人信息，包含生日国籍等',
  loose_pulley_text44:'2，完成',
  loose_pulley_text45:'信息',
  loose_pulley_text46:'3，点击',
  loose_pulley_text47:'进入支付页面，您可以选择支付定金或全额支付',
  loose_pulley_text48:'4，如果还未决定是否付钱，在您选择的船期余位充足的情况下，会出现 ',
  loose_pulley_text49:'预留位置页面，您可以选择这个服务，系统会发送邮件给您做备份，请在',
  loose_pulley_text50:'前完成付款，否则位置将被放掉。如果您选择的船期余位不多，则不会出现',
  loose_pulley_text51:'预留位置页面。',
  loose_pulley_text52:'5，如需协助您可以在',
  loose_pulley_text53:'里面您可以选择',
  loose_pulley_text54:'系统会发送邮件给你，客服邮件',
  loose_pulley_text55:'也会在收到您发送的邮件后联系您。',
  loose_pulley_text56:'您可以随时联系我们协助，我们会联系您迅速协助您处理。',
  loose_pulley_text57:'感谢您的支持！',
  footer_clause1:'使用者条款',
	footer_clause2:'隐私权保护政策',
	footer_clause3:'旅游产品由',
	footer_clause4:'提供',
	footer_clause5:'代表人',
	footer_clause6:'统一编号',
	footer_clause7:'旅行社注册编号',
	footer_clause8:'品保协会会员编号',
	footer_clause9:'联系地址',
	footer_clause10:'联系电话',
	footer_clause11:'传真',
	footer_clause12:'邮箱',
  email_dialog_txt:'您的邮箱地址或有误，请修改并验证。',
  need_breakfast:'加早',
  no_need_breakfast:'不加早',
  no_room_num:'酒店房间数量不足',
  //start
	nation_title:"选择国籍",
	residency_title:"选择居住地",
	setuser_nation:"国籍",
	setuser_residency:"居住地",
  //end

  member_title: "成员管理",   //成员管理    Member management    회원관리
  member_new: "新增成员",   //新增成員    New members    신규회원
  member_edit: "编辑成员",   //编辑成員    Edit members     구성원 편집
  member: "成员",   //成员   Member   회원
  member_role: "角色",    //角色   role     역할
  member_status: "状态",    //状态    status  상황
  member_status_open: "正常",     //正常    ON    켜짐
  member_status_stop: "停用",     //停用    OFF    꺼짐
  member_creation_time: "创建时间",   //创建时间   Creation time  작성시간

  member_send_email: "向成员发送账号激活邮件",    //向成员发送账号激活邮件      Send account activation email to members    회원에게 계정 활성화 전자 메일 보내기
  member_auth_error: "激活失败",   //激活失败   Activation failed  활성화 실패
  member_auth_ok:"激活成功，将为您跳转到登录页",   //激活成功，将为您跳转到登录页   Activation is successful and you will be redirected to the login page   활성화에 성공하면 로그인 페이지로 리디렉션됩니다
  
  member_auth_btn: "激活",     //激活    Activation    활성화
  member_auth_tips:"请完善密码，以激活您的账号。",   //请完善密码，以激活您的账号。   Please complete your password to activate your account.   계정을 활성화하려면 암호를 입력하십시오.
  member_auth_load_error: "信息异常，将为您跳转到首页",   //信息异常，将为您跳转到首页    Error, will redirect you to the home page     오류, 홈 페이지로 리디렉션됩니다
  ask_price:"询价单",
  account_add:'新增',
  guest_name:'客人姓名',
  city_name:'城市/行程天数',
  people_time:'操作人/日期',
  follow_uping:'跟进中',
  place_order:'已下单',
  ask_price_detail:"询价单详细",
  child_num:"儿童人数",
  child_age:"儿童年龄",
  guest_demand:'客人其他说明',
  data_num:'行程天数',
  hotel_start:'酒店星级',
  begain_city:'出发城市',
  over_city:'结束城市',
  ask_price_record:"询价产品记录",
  select_product:'选择产品',
  product_resource:'产品资源',
  num_data:'数量/出发日期',
  product_card_name:'产品编号/名称',
  discription_pla_city:"目的地城市",
  select:'选择',
  revice_price:'调整售价',
  select_go_data:'选择出行日期',
  advance:'预订',
  generate_order:'生成询价单',
  payment_type:'结算日类型',
  data_type_1:'下单日',
  data_type_2:'出行日',
  pay_type_1:'预付款',
  pay_type_2:'单结',
  pay_type_3:'周结',
  pay_type_4:'半月结',
  pay_type_5:'月结',
  day:'天',
  no_cancel:'未销账',
  yes_cancel:'已销账',
  refund_message2:'支付款项',
  sup_handsel:'定金',
  sup_final:'尾款',
  no_begain_pay:'未开启支付',
  sup_handsel_text:'少量定金即可留位，延后支付全款',
  pay_pay_within3:'前支付完成',
  login_break:'当前账户变动，请重新登录',
  begain_pay_monkey:'开始支付尾款',
  cancel_service_charge:'请注意：订单交易手续费恕不退款',
  confirm_cancel_order1:'您确定要取消当前订单吗？当前取消将按规则收取订单损失及交易手续费。',
  confirm_cancel_order2:'您确定要取消当前订单吗？当前取消将按规则收取交易手续费。',
  pre_submit_tips2:'请联系客服',
  fp:'忘记密码',
  response_unknown_error:'验证错误！',
  verification_code_error:'验证码错误！',
  fill:'设置新密码',
  set_newpassword_failed: '密码重置失败',
  re_login: "密码已重置，请重新登录",
  new_pwd:'请输入新密码',
  email_no_account:'此邮箱没有绑定账号',
  phone_get_psd:'手机找回密码',
  email_get_psd:'邮箱找回密码',
  em:'邮箱地址',
  response_phone_no:'手机号码不存在',
  
  email_login:'邮箱登录/注册',
  login_titlemsg_email: "使用未注册的邮箱登录会创建一个新的帐户",
  codeError:'验证码错误！',
  reg_error: "系统异常，请刷新重试",
  remt2:'《SCTT 服务协议》',
  login_yzm:'验证码登录',
  no_account:'还没有账号',
  sign_up:'立即注册',
  login_change_1:'登录/注册',
  login_change_2:'我已阅读并同意',
  login_change_3:'条款与声明',
  login_change_4:'使用邮箱',
  login_change_5:'我有账号密码',
  login_change_6:'立即登录',
  login_change_7:'使用手机',
  please_wait:'请稍等',
  please_select_pay:'额度不足，请选择其他支付方式',
  qm_need_login:'联系客服需登录/注册',
  unsubscribe1: '取消订阅成功。',
	unsubscribe2: '如需再次订阅，',
	unsubscribe31: '请至店铺的',
	unsubscribe32: '个人中心',
	unsubscribe33: '页面重新订阅。',
  dingyue_email: '订阅邮箱',
  dingyue_rule1:'优惠及更新信息',
  dingyue_rule2:'抢先获取最新优惠活动、折扣信息及最新动向',
  my_order_token:'我的订单',
  go_page:'前往',
  order_shop_trans:'(订单筛选来源)', 

  /*
  请完善密码，以激活您的账号。
  Please complete your password to activate your account.
  계정을 활성화하려면 암호를 입력하십시오.

  信息异常，将为您跳转到首页
  Error, will redirect you to the home page
  오류, 홈 페이지로 리디렉션됩니다

  */

  hotel_check_in:"入住日期",
  hotel_check_out:"退房日期",
  hotel_book_step_room:'房间',
  hotel_book_step_customer:'旅客',
  hotel_book_step_payment:'支付',
  orderfrom_customer_details:'旅客信息',
  orderfrom_lead_traveller_only:'仅填一位旅客信息', 
  orderfrom_all_travellers:'所有旅客', 
  orderfrom_first_name:'英文名', 
  orderfrom_last_name:'英文姓',
  m_logintime:'最后登录时间',
  
  pla_ipt_name:'请输入姓名',
  error_order:'创建订单出现异常',
  pla_kefu_dispose:'请联系客服处理',
  

  quota_pay:"额度充值",
  quota_pay2:'在线充值',
  quota_away_pay:'立即支付',
  quota_get_total:'到账',
  quota_line_remit:'线下转账汇款',
  quota_pay_record:'提交支付记录',
  quota_pay_success:'转账完成，请在这里提交凭证，财务审批后充入账号',
  quota_company_name:'公司名称',
  quota_bank_name:'开户行',
  quota_bank_accound:'开户行账号',
  quota_type:'入账状态',
  quota_pay_date:'支付日期',
  quota_upload_proof:'上传凭证',
  quota_upload:'上传',
  quota_only_type:'只能上传一个图片或pdf格式的文件',
  quota_result:'示例效果',
  quota_please_type:'请上传文件',
  quota_please_money:"请输入金额",
  quota_min_money:'金额不能小于0',
  quota_please_date:'请选择时间',
  
  select_sku:'已选套餐',
  selectdate_other_skutitle:'其他可定套餐',
  selectdate_no_skutitle:'当天无其他套餐可替换',
  other_goodsitem_pay_btn: "预订该替换方案", 
  //酒店特殊要求
  double_bed:"大床",  //Double bed
  two_beds:"双床",   //Two beds
  early_check_in:"提前入住",    //Early Check in
  late_check_in:"晚入住",    //Late Check in
  late_check_out:"晚退房 (可能会产生额外收费）",  //Late Check out (may occur extra cost)
  adjacent_rooms:"临近房间",   //Adjacent rooms
  honeymoon:"蜜月",   //Honeymoon
  InstantConfirmation: "立即确认",   //立即确认    Instant Confirmation
  pay_check_price: '抱歉，您订购的房间已经完售!',    //抱歉，您订购的房间已经完售!     Sorry, This room is sold out.
};
