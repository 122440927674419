// import { createStore } from "vuex";

// export default createStore({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });

import { defineStore } from "pinia";
import global from "./global";
export const mainStore = defineStore("main", {
  state: () => {
    return {
      //公共参数
      global: global,
      userInfo: {},
      hasLogin: false,
      usertoken: "",
      olduid: 0,
      usertype: 0,  //用户类别（0直客，1分销商，2同行）
      HeaderData: {
        guest: 2,
        token: "",
        //bid: process.env.NODE_ENV === 'production' ? 0 : 456817,
        bid: 0,
        language: 0,
        locations: 0,
        timezone: process.env.NODE_ENV === 'production' ? 0 : new Date().getTimezoneOffset(),
        //domainname:  "ptc.sctt.net",
        domainname: process.env.NODE_ENV === 'production' ? window.location.host : "sell-dev.sctt.net",
      },
      commonData: {},
      commonTime: "",
      keywords: "",
      orderkey: "",
      version: "3.0.0",   //初始版本号
      locale: "",
      coziAccount: {},
      loginRoute: {},
      hotelToken: {
        token: "",
        expireTime: 0,
        uuid: ""
      },
      default_language_id: 2,
      default_local: "zh-CN",
      nationModel: {},
      residencyModel: {},
      includedComponents: [],
      powerarr: "",
      qimoClientIdBySctt:'',
      scttpc_login_time_agent:''
    };
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
  getters: {
    getHeaderData() {
      let headerData = this.HeaderData;
      if (headerData.token === "") {
        headerData.token = sessionStorage.getItem("scttpc3_usertoken");
      }
      return headerData;
    },
    getUserToken() {
      let token = this.usertoken;
      if (token === "") {
        token = sessionStorage.getItem("scttpc3_usertoken");
      }
      return token;
    },
    getLoginRoute() {
      return this.loginRoute;
    },
    getPowerarr() {
      return this.powerarr;
    },
    getqimoClient() {
      return this.qimoClientIdBySctt;
    }
  },
  actions: {
    // changeState() {
    //   this.count++;
    //   this.helloPinia = "change helloPinia!!!";
    // },
    changeLoginTimeAgent (data) {
      this.scttpc_login_time_agent = data
    },
    qimoClientChange (data) {
      this.qimoClientIdBySctt = data
    },
    includedComponent (data) {
      this.includedComponents = data
    },
    changeVersion(v) {
      this.version = v;
    },
    changekeywords(key) {
      this.keywords = key;
    },
    login(token) {
      //改变登录状态
      this.hasLogin = true;
      this.usertoken = token;
    },
    logout() {
      //退出登录
      this.hasLogin = false;
      this.usertoken = "";
      this.olduid = 0;
      this.usertype = 0;
      this.HeaderData.token = "";
      this.HeaderData.guest = 2; //登出后自动设置为访客状态
      //清除
      sessionStorage.clear(); //清空sessionStorage中所有信息
      localStorage.clear(); //清空localStorage中所有信息
    },
    setLoginRoute(route) {
      //记录登陆前的路由信息，登陆后自动跳转，非预定页面 可用
      this.loginRoute = route;
    },
    setCoziAccount(cozi) {
      this.coziAccount = cozi;
    },
    upolduid(olduid) {
      this.olduid = olduid;
    },
    upusertype(usertype) {
      this.usertype = usertype;
    },
    setHeaderData(headerData) {
      this.HeaderData.token = headerData.token;
      this.HeaderData.bid = headerData.bid;
      this.HeaderData.language = headerData.language;
      this.HeaderData.locations = headerData.locations;
      this.HeaderData.timezone = headerData.timezone;
      this.HeaderData.domainname = headerData.domainname;
    },
    upHeaderToken(token) {
      this.HeaderData.token = token;
      this.usertoken = token;
    },
    upHeaderTimeZone(timezone) {
      this.HeaderData.timezone = timezone;
    },
    upHeaderLocation(locations) {
      this.HeaderData.locations = locations;
    },
    upCityCode(citycode) {
      this.citycode = citycode;
    },
    upHeaderLanguage(language) {
      this.HeaderData.language = language;
      this.commonData.configure.language = language;
      let locale =
        language == 1
          ? "en-US"
          : language == 2
            ? "zh-CN"
            : language == 3
              ? "zh-TW"
              : language == 4
                ? "ko-KR"
                : "zh-CN";
      this.locale = locale;
    },
    upHeaderGuest(guest) {
      this.HeaderData.guest = guest;
    },
    upcommonTime(time) {
      this.commonTime = time;
    },
    upcommonData(common) {
      this.commonData = common;
      //state.HeaderData.token = '';
      this.HeaderData.bid = common.configure.bid;
      this.HeaderData.locations = common.configure.locations;
      //this.HeaderData.language = common.configure.language;
      this.HeaderData.language = common.configure.language;
      let locale =
        common.configure.language == 1
          ? "en-US"
          : common.configure.language == 2
            ? "zh-CN"
            : common.configure.language == 3
              ? "zh-TW"
              : common.configure.language == 4
                ? "ko-KR"
                : "zh-CN";
      this.locale = locale;

      let thisDate = new Date();
      let timezone = thisDate.getTimezoneOffset();
      this.HeaderData.timezone = timezone;
      let host = "sell-dev.sctt.net";
      //let host = "ptc.sctt.net";
      if (process.env.NODE_ENV === "production") {
        if(process.env.VUE_APP_MODE === "test"){
          host = "sell-dev.sctt.net";
          //host = "ptc.sctt.net";
        }
        else{
          host = window.location.host;
        }
      }

      this.HeaderData.domainname = host;

    },
    clearCommonData() {
      this.commonData = {};
    },
    upkeywords(keywords) {
      this.keywords = keywords;
    },
    uporderkey(orderkey) {
      this.orderkey = orderkey;
    },
    upHotelToken(token, uuid, expireTime) {
      this.hotelToken.token = token;
      this.hotelToken.uuid = uuid;
      this.hotelToken.expireTime = expireTime;
      console.log("token 更新成功了");
    },
    upNationModel(model) {
      this.nationModel = model;
    },
    upResidencyModel(model) {
      this.residencyModel = model;
    },
    upPowerarr(powerarr){
      this.powerarr = powerarr;
    },
  },
});
