/* eslint-disable prettier/prettier */
import { createI18n } from "vue-i18n";

import zh_CN from "./zh-CN";
import zh_TW from "./zh-TW";
import en_US from "./en-US";
import ko_KR from "./ko-KR";

// 语言库
const messages = {
  "zh-CN": zh_CN,
  "zh-TW": zh_TW,
  "en-US": en_US,
  "ko-KR": ko_KR,
};

// 默认语言
//const langDefault = store.locale;
 const langDefault = 'zh-CN'

const i18n = createI18n({
  legacy: false,    //Uncaught SyntaxError: Not available in legacy mode
  locale: langDefault, //默认显示的语言
  messages,
});
export default i18n; // 将i18n暴露出去，在main.js中引入挂载
